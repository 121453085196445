<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#eee" d="M138.4 147L512 167v178l-373.6 20z" />
      <path fill="#6da544" d="M0 0l138.4 167H512V0z" />
      <path fill="#d80027" d="M0 512l138.4-167H512v167z" />
      <path fill="#333" d="M167 167L0 0v512l167-167z" />
    </g>
  </svg>
</template>
