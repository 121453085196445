<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#0052b4" d="M256 0h256v511.9H0v-256z" />
      <path
        fill="#eee"
        d="M256 0H100.2L66.7 14.9 33.4 0H0v33.4l15.7 31.3L0 100.2V256h33.4l34-13.6 32.8 13.6h33.4v-75.2l75.2 75.2H256l-7.7-21.3 7.7-10.2v-15.7l-75.2-75.2H256v-33.4l-11.8-33.4L256 33.4z"
      />
      <path fill="#338af3" d="M289.4 133.6V256l78 40.4 77.9-40.4V133.6h-156z" />
      <path fill="#d80027" d="M256 224.5l-91-91h-31.4L256 256z" />
      <path fill="#a2001d" d="M289.4 256c0 59.6 77.9 78 77.9 78s78-18.4 78-78h-156z" />
      <path fill="#d80027" d="M33.4 0v33.4H0v66.8h33.4V256h66.8V100.2H256V33.4H100.2V0z" />
      <path fill="#333" d="M400.7 189.2h-22.3V167h-22.2v22.2h-22.3v22.3h22.3v66.8h22.2v-66.8h22.3z" />
    </g>
  </svg>
</template>
