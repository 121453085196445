<template>
  <div class="lg:hidden">
    <div :class="['relative z-1 flex justify-between bg-white px-6 py-5 shadow-mfs']">
      <div class="flex w-6 grow-0 items-center">
        <MfsIcon
          v-if="commonStore.basePageDetails.isBackArrowVisible"
          :size="18"
          color="primary"
          icon-name="arrow-left"
          @click="goBack"
          classes="translate-x-0 cursor-pointer transition duration-[250] ease-in-out hover:translate-x-[2px]"
        />

        <a v-else-if="commonStore.basePageDetails.isHeaderLogoVisible" href="https://metro-fs.com/">
          <MfsLogoMetro />
        </a>
      </div>

      <MfsFont
        v-if="commonStore.basePageDetails.isHeaderTitleVisible"
        fontName="subtitle-1"
        tag="h1"
        color="primary-v2"
      >
        {{ commonStore.basePageDetails.topHeaderTitle }}
      </MfsFont>

      <MfsIcon
        v-if="!commonStore.isAuthenticated"
        :size="24"
        color="primary"
        icon-name="message"
        classes="cursor-pointer"
        @click="openFreshChat"
      />

      <div v-else class="relative">
        <MfsIcon
          :size="24"
          color="primary"
          icon-name="more-three-dots"
          classes="cursor-pointer"
          @click="isDropdownVisible = true"
        />

        <div
          class="absolute -right-2 top-[53px] z-20 w-[190px] rounded-lg bg-white shadow-mfs-xl"
          v-show="isDropdownVisible"
        >
          <div
            class="flex h-[50px] cursor-pointer border-b border-base-border pl-4 transition-opacity duration-300 hover:opacity-70"
            @click="
              openFreshChat();
              isDropdownVisible = false;
            "
            role="button"
          >
            <MfsIcon icon-name="message" :size="22" color="primary-v2" classes="mr-3" />
            <p class="m-0 self-center font-metro font-bold text-primary-v2">
              {{ t('onboardingV2.sidebar.menu.support') }}
            </p>
          </div>

          <div
            v-if="commonStore.isAuthenticated && commonStore.currentUser"
            class="flex h-[50px] cursor-pointer pl-4 transition-opacity duration-300 hover:opacity-70"
            @click="onClick"
            role="button"
          >
            <MfsIcon icon-name="logout" :size="22" color="primary-v2" classes="mr-3" />
            <p class="m-0 self-center font-metro font-bold text-primary-v2">
              {{ t('onboardingV2.sidebar.menu.logout') }}
            </p>
          </div>
        </div>
      </div>

      <div
        v-show="isDropdownVisible"
        class="fixed left-0 top-0 z-10 size-full bg-transparent"
        @click="isDropdownVisible = false"
      />
    </div>

    <MfsProgressbar
      v-if="commonStore.basePageDetails.isProgressBarVisible"
      :steps-config="{ max: commonStore.basePageDetails.stepsCount, completed: commonStore.basePageDetails.step }"
    />

    <MfsAuthDialog
      :is-visible="isDialogVisible"
      :title="t('onboardingV2.logout.heading')"
      :text="t('onboardingV2.logout.text')"
      :cancel-label="t('onboardingV2.logout.backBtn')"
      :ok-label="t('onboardingV2.logout.continueBtn')"
      @ok-btn-click="logout"
      @cancel-btn-click="isDialogVisible = false"
    />
  </div>
  <FreshChatInfoDialog :isOpened="isFreshChatDialogOpen" @close="toggleFreshChatDialog" />
</template>

<script setup lang="ts">
import MfsFont from '@/components/atoms/MfsFont.vue';
import MfsIcon from '@/components/atoms/MfsIcon.vue';
import MfsLogoMetro from '@/components/atoms/MfsLogoMetro.vue';
import MfsProgressbar from '@/components/atoms/MfsProgressbar.vue';
import MfsAuthDialog from '@/components/organisms/MfsAuthDialog.vue';
import FreshChatInfoDialog from '@/components/organisms/FreshChatInfoDialog.vue';
import { useCommonPageFeats } from '@/composables/useCommonPageFeats';
import { useCommonStore } from '@/stores/common';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { isFreshChatDialogOpen, toggleFreshChatDialog, onClickLogout, openFreshChat, logout, goBack } =
  useCommonPageFeats();
const commonStore = useCommonStore();
const isDropdownVisible = ref(false);
const isDialogVisible = ref(false);

function onClick() {
  isDialogVisible.value = true;
  isDropdownVisible.value = false;
  onClickLogout();
}
</script>
