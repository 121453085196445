<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#6da544" d="M0 0h144.7l108.4 41.2L367.3 0H512v512H367.3l-110.2-41.4L144.7 512H0z" />
      <path fill="#eee" d="M144.7 0h222.6v512H144.7z" />
      <path fill="#6da544" d="M323 334l-67-211.6L189.3 334h50.1v55.7h33.4V334z" />
    </g>
  </svg>
</template>
