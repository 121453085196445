<template>
  <svg
    width="56px"
    height="56px"
    viewBox="0 0 56 56"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g transform="translate(-988.000000, -567.000000)">
        <g transform="translate(420.000000, 96.000000)">
          <g transform="translate(88.000000, 471.000000)">
            <g transform="translate(352.000000, 0.000000)">
              <g transform="translate(129.000000, 7.000000)">
                <path
                  d="M32,21.3333333 C32,22.8060927 30.8060927,24 29.3333333,24 L2.66666667,24 C1.19390733,24 0,22.8060927 0,21.3333333 L0,5.33333333 C0,3.860574 1.19390733,2.66666667 2.66666667,2.66666667 L13.3333333,2.66666667 C14.08,1.17333333 14.536,0 16.3146667,0 L22.352,0 C24.1306667,0 24.5866667,1.17333333 25.3333333,2.66666667 L29.3333333,2.66666667 C30.8060927,2.66666667 32,3.860574 32,5.33333333 L32,21.3333333 Z"
                  stroke="#0064FE"
                  stroke-width="2"
                ></path>
                <path
                  d="M14,13.5 C14,16.5375661 16.4624339,19 19.5,19 C22.5375661,19 25,16.5375661 25,13.5 C25,10.4624339 22.5375661,8 19.5,8 C16.4624339,8 14,10.4624339 14,13.5 Z"
                  stroke="#0064FE"
                  stroke-width="2"
                ></path>
                <path
                  d="M39.6773333,18 L25.5555556,18 C23.5918764,18 22,19.5842232 22,21.5384615 L22,37.4615385 C22,39.4157768 23.5918764,41 25.5555556,41 L50.4444444,41 C52.4081236,41 54,39.4157768 54,37.4615385 L54,28.5959231"
                  stroke="#002D72"
                  stroke-width="2"
                  transform="translate(38.000000, 29.500000) scale(-1, 1) translate(-38.000000, -29.500000) "
                ></path>
                <circle id="Oval" stroke="#002D72" stroke-width="1.8" cx="38" cy="26" r="3"></circle>
                <path
                  d="M44,36 C43.3006168,33.0584586 40.8304666,31 38,31 C35.1695334,31 32.6993832,33.0584586 32,36"
                  stroke="#002D72"
                  stroke-width="1.8"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
