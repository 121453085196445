<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#ffda44" d="M0 167l257.3-31.9L512 167v178l-256.3 29L0 345z" />
      <path fill="#d80027" d="M0 0h512v167H0z" />
      <path fill="#496e2d" d="M0 345h512v167H0z" />
      <path fill="#333" d="M256 167l22.1 68h71.5l-57.8 42 22 68-57.8-42-57.9 42 22.1-68-57.8-42h71.5z" />
    </g>
  </svg>
</template>
