import {
  MFS_FIREBASE_APIKEY,
  MFS_FIREBASE_APP_ID,
  MFS_FIREBASE_AUTHDOMAIN,
  MFS_FIREBASE_AUTH_EMULATOR_HOST,
  MFS_FIREBASE_MASSENGER_SENDER_ID,
  MFS_FIREBASE_PROJECT_ID,
  MFS_FIRESTORE_EMULATOR_HOST,
  MFS_FIRESTORE_EMULATOR_PORT,
} from '@/env';
import type { FirebaseApp } from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, initializeFirestore } from 'firebase/firestore/lite';

interface QueuedPromise<T = FirebaseApp> {
  promise: () => Promise<T>;
  resolve: (value: FirebaseApp) => void;
  reject: (error: unknown) => void;
}

let firebaseInstance: FirebaseApp | null = null;
const queue: Array<QueuedPromise> = [];
let isFirebaseInitializing: Boolean = false;

async function getFirebaseClient(): Promise<FirebaseApp> {
  return new Promise((resolve, reject) => {
    queue.push({
      promise: initializeFirebase,
      resolve,
      reject,
    });

    processInitializationQueue();
  });
}

async function initializeFirebase(): Promise<FirebaseApp> {
  if (firebaseInstance) return firebaseInstance;

  const firebaseApp = initializeApp({
    apiKey: MFS_FIREBASE_APIKEY,
    authDomain: MFS_FIREBASE_AUTHDOMAIN,
    projectId: MFS_FIREBASE_PROJECT_ID,
    messagingSenderId: MFS_FIREBASE_MASSENGER_SENDER_ID,
    appId: MFS_FIREBASE_APP_ID,
  });

  if (
    (import.meta.env.MODE === 'e2e-ci' || import.meta.env.MODE === 'e2e-local') &&
    MFS_FIREBASE_AUTH_EMULATOR_HOST &&
    MFS_FIRESTORE_EMULATOR_HOST &&
    MFS_FIRESTORE_EMULATOR_PORT
  ) {
    console.log('e2e detected, using Firebase emulator');

    const auth = getAuth(firebaseApp);
    const db = initializeFirestore(firebaseApp, {
      // @ts-ignore
      experimentalForceLongPolling: true,
    });

    connectAuthEmulator(auth, MFS_FIREBASE_AUTH_EMULATOR_HOST);
    connectFirestoreEmulator(db, MFS_FIRESTORE_EMULATOR_HOST, MFS_FIRESTORE_EMULATOR_PORT);
  }

  firebaseInstance = firebaseApp;

  return firebaseInstance;
}

function processInitializationQueue() {
  if (isFirebaseInitializing) return;

  const nextPromise = queue.shift();
  if (!nextPromise) return;

  try {
    isFirebaseInitializing = true;

    nextPromise
      .promise()
      .then((value: FirebaseApp) => {
        isFirebaseInitializing = false;
        nextPromise.resolve(value);
        processInitializationQueue();
      })
      .catch((error) => {
        isFirebaseInitializing = false;
        nextPromise.reject(error);
        processInitializationQueue();
      });
  } catch (error) {
    isFirebaseInitializing = false;
    nextPromise.reject(error);
    processInitializationQueue();
  }

  return;
}

export { getFirebaseClient };
