import { BKZ, metroIdRegex } from '@/constants';
import { i18n } from '@/plugins/i18n';
import { helpers } from '@vuelidate/validators';

const validateFormat = (value: string) => {
  return value ? metroIdRegex.test(value) : true;
};

const validateBkz = (value: string) => {
  if (!value) return true;
  const paddedBkz = value.split('-')[0].padStart(3, '0');
  return BKZ.indexOf(paddedBkz) !== -1;
};

export const validateMetroId = helpers.withMessage(String(i18n.global.t('validation.valid_metro_id')), validateFormat);
export const validateMetroBkz = helpers.withMessage(String(i18n.global.t('validation.valid_bkz')), validateBkz);
