<template>
  <nav class="hidden min-h-full w-60 shrink-0 bg-white lg:block">
    <div class="flex min-h-full flex-col">
      <div class="pl-8 pr-6 pt-8">
        <h2 class="m-0 mb-11 p-0 font-metro text-xl font-black text-primary-v2">
          {{ commonStore.basePageDetails.sideBarTitle }}
        </h2>

        <SidebarSteps
          v-if="commonStore.basePageDetails.stepsDescription18nKey"
          :step="commonStore.basePageDetails.step"
          :steps-count="commonStore.basePageDetails.stepsCount"
          :is-logged-out="commonStore.currentUser === null"
          :steps-description-key="commonStore.basePageDetails.stepsDescription18nKey"
        />
      </div>

      <div class="mt-auto grow-0">
        <div class="pb-3 pl-8 pr-6">
          <div
            class="mb-6 flex cursor-pointer align-middle transition-opacity duration-300 hover:opacity-70"
            @click="openFreshChat"
            role="button"
          >
            <MfsIcon icon-name="message" :size="22" color="primary-v2" classes="mr-3" />
            <p class="m-0 font-metro font-bold text-primary-v2">{{ t('onboardingV2.sidebar.menu.support') }}</p>
          </div>

          <div
            v-if="commonStore.isAuthenticated && commonStore.currentUser && !onboardingStore.teamCardsJwt"
            class="flex cursor-pointer align-middle transition-opacity duration-300 hover:opacity-70"
            @click="onOpenDialog"
            role="button"
            data-cy="logout-button"
          >
            <MfsIcon icon-name="logout" :size="22" color="primary-v2" classes="mr-3" />
            <p class="m-0 font-metro font-bold text-primary-v2">{{ t('onboardingV2.sidebar.menu.logout') }}</p>
          </div>
        </div>

        <div class="relative">
          <div
            :class="[
              'flex h-[140px] grow-0',
              'before:content-[\' \'] before:absolute before:inset-0 before:bg-secondary',
              'clip-bcg',
            ]"
          >
            <MfsLogoMetro class="relative m-auto mb-6" />
          </div>
        </div>
      </div>
    </div>

    <MfsAuthDialog
      :is-visible="isVisible"
      :title="t('onboardingV2.logout.heading')"
      :text="t('onboardingV2.logout.text')"
      :cancel-label="t('onboardingV2.logout.backBtn')"
      :ok-label="t('onboardingV2.logout.continueBtn')"
      @ok-btn-click="logout"
      @cancel-btn-click="isVisible = false"
    />
  </nav>
  <FreshChatInfoDialog :isOpened="isFreshChatDialogOpen" @close="toggleFreshChatDialog" />
</template>

<script setup lang="ts">
import MfsIcon from '@/components/atoms/MfsIcon.vue';
import MfsLogoMetro from '@/components/atoms/MfsLogoMetro.vue';
import SidebarSteps from '@/components/molecules/SidebarSteps.vue';
import MfsAuthDialog from '@/components/organisms/MfsAuthDialog.vue';
import FreshChatInfoDialog from '@/components/organisms/FreshChatInfoDialog.vue';
import { useCommonPageFeats } from '@/composables/useCommonPageFeats';
import { useCommonStore } from '@/stores/common';
import { useOnboardingStore } from '@/stores/onboarding';
import { onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const commonStore = useCommonStore();
const onboardingStore = useOnboardingStore();
const { isFreshChatDialogOpen, toggleFreshChatDialog, openFreshChat, onClickLogout, logout } = useCommonPageFeats();

const isVisible = ref(false);

const onOpenDialog = () => {
  isVisible.value = true;
  onClickLogout();
};

onUnmounted(() => {
  isVisible.value = false;
});
</script>

<style scoped>
.clip-bcg:before {
  clip-path: polygon(0 100%, 0 60px, 100% 0, 100% 100%);
}
</style>
