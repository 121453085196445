<template>
  <button
    data-cy="mfs-btn"
    :id="id"
    :class="[
      'mfs-button px-9 py-0 text-[15px] shadow-mfs-sm',
      'font-lato font-black tracking-[-0.1px]',
      'relative self-center overflow-hidden whitespace-nowrap rounded text-center',
      {
        'w-full': isBlock,
        'border-2': hasThickerOutline,
        'bg-primary text-white hover:opacity-90': !isOutlined && !isDisabled,
        'button--outlined border border-solid border-primary bg-white text-primary hover:bg-base-lighter':
          isOutlined && !isDisabled,
        'pointer-events-none !bg-[#b3b9c2] text-white': isDisabled,
      },
    ]"
    :style="{
      'line-height': `${height}px`,
    }"
    :disabled="isDisabled"
    type="submit"
    @click="onClick"
  >
    <span class="relative flex flex-auto items-center justify-center text-inherit transition-opacity">
      <MfsSpinner v-if="isLoading" :color="getIconColor" :size="25" class="ml-[-6px] mr-[7px] mt-[3px]" />

      <MfsIcon v-if="!isLoading && icon" classes="mr-2" :icon-name="icon" :size="iconSize" :color="getIconColor" />

      <div v-if="hasText" :class="['pa-0', fontClass]">
        {{ label }}
      </div>
    </span>
  </button>
</template>

<script setup lang="ts">
import MfsIcon from '@/components/atoms/MfsIcon.vue';
import MfsSpinner from '@/components/atoms/MfsSpinner.vue';
import { computed, ref, watch } from 'vue';
import { type TranslateResult } from 'vue-i18n';

const emit = defineEmits(['click']);

const props = withDefaults(
  defineProps<{
    id?: string;
    isBlock?: boolean;
    isOutlined?: boolean;
    isDisabled?: boolean;
    label?: string | TranslateResult;
    isLoading?: boolean;
    hasThickerOutline?: boolean;
    height?: string;
    fontClass?: string;
    icon?: string | null;
    iconSize?: number;
  }>(),
  {
    isBlock: true,
    isOutlined: false,
    isDisabled: false,
    label: '',
    isLoading: false,
    hasThickerOutline: false,
    height: '56',
    fontClass: '',
    icon: null,
    iconSize: 24,
  }
);

const hasText = ref(true);

// working example taken from https://codepen.io/BretCameron/pen/mdPMVaW
const createRipple = (e: { currentTarget: any; clientX: number; clientY: number }) => {
  const button = e.currentTarget;
  const circle = document.createElement('span');
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${e.clientX - button.offsetLeft - radius}px`;
  circle.style.top = `${e.clientY - button.offsetTop - radius}px`;
  circle.classList.add('ripple');

  const ripple = button.getElementsByClassName('ripple')[0];
  if (ripple) ripple.remove();
  button.appendChild(circle);
};

const getIconColor = computed(() => {
  return props.isOutlined ? 'primary' : 'white';
});

watch(
  () => props.label,
  (value: string) => {
    hasText.value = !!value;
  }
);

const onClick = (e: MouseEvent) => {
  createRipple(e);
  emit('click', e);
};
</script>

<style>
.mfs-button > span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.3);
}

.mfs-button.button--outlined > span.ripple {
  background-color: #66a2fe;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
</style>
