import { cssProperties } from '@/assets/styles/cssProperties';
import { getMobileOS, MOBILE_PLATFORMS } from '@/utils/detectMobileOs';

export function generateCssRootCustomProperties(variableNames: Record<string, string> = cssProperties.colors) {
  return Object.keys(variableNames).forEach((cssVar) => {
    document.documentElement.style.setProperty(`--mfs-color-${cssVar}`, variableNames[cssVar]);
  }, '');
}

export function setAppSizeAsCssProperty() {
  window.addEventListener('resize', determineAppHeight);

  determineAppHeight();

  function determineAppHeight(): void {
    const os = getMobileOS();
    let screenHeight = window.innerHeight;
    let screenWidth = window.innerWidth;

    // window.innerHeight (and width) can get wacky on mobile browsers,
    // even when you use the proper viewport meta tags.
    // On some browsers it can be very inconsistent.
    if (os === MOBILE_PLATFORMS.IOS || os === MOBILE_PLATFORMS.ANDROID) {
      screenHeight = document.documentElement.clientHeight;
      screenWidth = document.documentElement.clientWidth;
    }

    // The background components are meant to take at least 100% of the screen height. Unfortunately,
    // something like "min-height: 100vh" or "min-height: 100%" does not work for smartphones (especially iOS) as it will
    // always add vertical scrolling although it is not required by the amount of content on the page. Thus we are working
    // with a CSS variable (--app-height) which can be used in a way like this: "min-height: var(--app-height);",
    document.documentElement.style.setProperty('--mfs-app-height', `${screenHeight}px`);
    document.documentElement.style.setProperty('--mfs-app-width', `${screenWidth}px`);
  }
}

export function validateColorPropBasedOnCssProperty(prop: string): boolean {
  const definedColors = Object.keys(cssProperties.colors);

  const isColorDefined = definedColors.includes(prop);

  if (!isColorDefined) {
    console.info(
      `MfsFont: The color name you have chosen - ${prop} - is not defined. Define it in src/assets/styles/cssProperties AND tailwind.config.js files, or take one of these:`,
      definedColors
    );
  }

  return isColorDefined;
}
