<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#6da544" d="M0 0l216.9 301.6L512 512V0z" />
      <path fill="#ffda44" d="M0 0l512 512H0z" />
      <path fill="#d80027" d="M256 121l90 270-234-168h288L166 391z" />
    </g>
  </svg>
</template>
