<template>
  <CoreLayoutV2
    :is-with-sticky-header="false"
    :with-x-padding="true"
    :is-full-height="true"
    :with-lg-flex="false"
    class="lg:bg-base-bg"
  >
    <template v-slot:body>
      <div class="min-h-full">
        <div class="mfs-container mb-2 flex h-16 items-center justify-between lg:hidden">
          <MfsIcon
            id="btn-header-back"
            :size="18"
            color="primary"
            icon-name="arrow-left"
            @click="goBack"
            classes="cursor-pointer transition duration-[250] ease-in-out hover:translate-x-[2px]"
          />
          <MfsIcon
            id="btn-header-freshchat"
            :size="24"
            color="primary"
            icon-name="message"
            @click="openFreshChat"
            classes="cursor-pointer"
          />
        </div>

        <MfsIcon
          id="btn-header-back"
          :size="18"
          color="primary"
          icon-name="arrow-left"
          @click="goBack"
          classes="-right-2 z-20 hidden w-[190px] cursor-pointer transition duration-[250] ease-in-out hover:translate-x-[2px] lg:absolute lg:left-8 lg:top-8 lg:block"
        />

        <MfsGrid :is-full-height-with-header="true" class="lg:!pt-24">
          <template v-slot:body>
            <MfsHeader predefined-style="onb-page-header">
              {{ headline }}
            </MfsHeader>

            <div class="mt-7 flex flex-col items-center">
              <MfsText predefined-style="onb-page-info-text">
                {{ descriptionPrimary }}
              </MfsText>
              <EmailSendImg class="mb-8" />
              <MfsText predefined-style="onb-page-info-text">
                {{ descriptionSecondary }}
              </MfsText>
            </div>
          </template>
        </MfsGrid>
      </div>
    </template>
  </CoreLayoutV2>
  <FreshChatInfoDialog :isOpened="isFreshChatDialogOpen" @close="toggleFreshChatDialog" />
</template>

<script setup lang="ts">
import EmailSendImg from '@/components/atoms/EmailSendImg.vue';
import MfsHeader from '@/components/atoms/MfsHeader.vue';
import MfsIcon from '@/components/atoms/MfsIcon.vue';
import MfsText from '@/components/atoms/MfsText.vue';
import CoreLayoutV2 from '@/components/templates/CoreLayoutV2.vue';
import MfsGrid from '@/components/templates/MfsGrid.vue';
import FreshChatInfoDialog from '@/components/organisms/FreshChatInfoDialog.vue';
import { useCommonPageFeats } from '@/composables/useCommonPageFeats';
import { type TranslateResult } from 'vue-i18n';

defineProps<{
  headline: string | TranslateResult;
  descriptionPrimary: string | TranslateResult;
  descriptionSecondary: string | TranslateResult;
}>();

const { isFreshChatDialogOpen, toggleFreshChatDialog, goBack, openFreshChat } = useCommonPageFeats();
</script>
