<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#eee" d="M27 63.3l485 39.1v102.4L477.3 259l34.7 48.2v102.4L27.4 446.9z" />
      <path fill="#d80027" d="M0 0l51.2 102.4H512V0zm0 512h512V409.6H51.2zm180-204.8h332V204.8H180z" />
      <path fill="#0052b4" d="M0 0v512l256-256z" />
      <path fill="#eee" d="M103.6 189.2l16.6 51h53.6l-43.4 31.6 16.6 51-43.4-31.5-43.4 31.5 16.6-51-43.4-31.6H87z" />
    </g>
  </svg>
</template>
