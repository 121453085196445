<template>
  <article class="home-container">
    <div class="metro-logo">
      <a href="https://metro-fs.com/" @click="onMetroLogoClick">
        <MfsLogoMetro />
      </a>
    </div>

    <slot name="login-link" />

    <section class="help-slider">
      <div class="tablet-and-up">
        <HelpSliderSteps :current-slide="currentSlide" @switch-to-slide="onSlideChange" />

        <HelpSliderText :current-slide="currentSlide" />
      </div>
      <HelpSlider :current-slide="currentSlide" @slide-change="onSlideChange" />
    </section>

    <section class="text-container">
      <div class="up-to-tablet">
        <HelpSliderSteps :current-slide="currentSlide" @switch-to-slide="onSlideChange" />
        <HelpSliderText :current-slide="currentSlide" />
      </div>

      <div class="slider-information-margin">
        <HelpSliderBigText class="tablet-and-up big-text" />
        <MfsButton
          data-cy="start-button"
          :is-loading="isTokenValidating"
          :is-disabled="isTokenValidating"
          :label="t('teamCardsHomeSlider.continueBtn')"
          class="action-btn"
          @click="onStartNowClick"
        />
        <MfsFont v-if="hasError" class="lg:text-left" tag="span" font-name="body-2-regular" color="error">
          {{ t('apiErrors.teamCardsApiErrors.DEFAULT_MESSAGE') }}
        </MfsFont>
      </div>
    </section>
  </article>
</template>

<script setup lang="ts">
import MfsButton from '@/components/atoms/MfsButton.vue';
import MfsFont from '@/components/atoms/MfsFont.vue';
import MfsLogoMetro from '@/components/atoms/MfsLogoMetro.vue';
import HelpSlider from '@/components/molecules/HelpSlider.vue';
import HelpSliderBigText from '@/components/molecules/HelpSliderBigText.vue';
import HelpSliderSteps from '@/components/molecules/HelpSliderSteps.vue';
import HelpSliderText from '@/components/molecules/HelpSliderText.vue';
import { useFirestore } from '@/composables/useFirestore';
import { MethodType, teamCardsJwtLocalStorageKey } from '@/constants';
import { indexRoutesNames, teamCardsRoutesNames } from '@/router/routeNames';
import { signInWithToken, updateDataInFirestore } from '@/service/firebase';
import { useCommonStore } from '@/stores/common';
import { useOnboardingStore } from '@/stores/onboarding';
import { TeamCards } from '@/types/ApiName';
import { Collection } from '@/types/FirebaseCollection';
import { teamCardsStartPage } from '@/utils/analytics/errorEventNames';
import { teamCardsStartPageEvents } from '@/utils/analytics/eventNames';
import { trackAnalytics, trackError } from '@/utils/analytics/sendAnalyticsUtil';
import { EventKeys, NameKeys, trackEvents } from '@/utils/analytics/trackEvents';
import { sendRequest } from '@/utils/axios';
import { setNewThemeColor } from '@/utils/themeColorTag';
import { type AxiosRequestConfig } from 'axios';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const commonStore = useCommonStore();
const onboardingStore = useOnboardingStore();
const { firestoreRequest } = useFirestore();

const teamCardsJwt = ref<string>('');
const hasError = ref<boolean>(false);
const isTokenValidating = ref<boolean>(false);
const currentSlide = ref<number>(1);

onMounted(() => {
  if (!('token' in router.currentRoute.value.query)) {
    router.push({ name: indexRoutesNames.home });
  }

  document.title = String(t('pageTitles.default'));
  const currentPath = router.currentRoute.value.fullPath;
  teamCardsJwt.value = currentPath.split('token=')[1];
  onboardingStore.teamCardsJwt = teamCardsJwt.value;
  localStorage.setItem(teamCardsJwtLocalStorageKey, teamCardsJwt.value);
});

const onStartNowClick = async () => {
  setNewThemeColor();
  isTokenValidating.value = true;

  trackEvents(EventKeys.CONTINUE_CLICK, route.name, NameKeys.TEAMCARDS_START_NEXT);

  const request: AxiosRequestConfig = {
    method: MethodType.POST,
    url: TeamCards.TOKEN_VERIFY,
    data: { token: teamCardsJwt.value },
  };

  try {
    const response = await sendRequest<{ authToken: string }>(request);
    const { user } = await signInWithToken(response.data.authToken);

    commonStore.currentUser = user;
    try {
      await updateFirestore();
    } catch (error) {
      firestoreRequest.isError = true;
      isTokenValidating.value = false;
      return;
    }

    router.push({ name: teamCardsRoutesNames.teamCardsPasswordEntry });
  } catch (error: any) {
    const errorStatus = error?.response?.status;

    switch (errorStatus) {
      case 404:
      case 409:
        trackError(teamCardsStartPage.general, error);
        router.push({ name: teamCardsRoutesNames.teamCardsTokenExpired });
        break;
      case 422:
        trackError(teamCardsStartPage.general, error);
        router.push({ name: teamCardsRoutesNames.teamCardsAppDownload });
        break;
      default:
        trackError(teamCardsStartPage.general, error);
        hasError.value = true;
    }
    isTokenValidating.value = false;
  }
};

const onSlideChange = (i: number) => {
  currentSlide.value = i;
};

const onMetroLogoClick = () => {
  trackAnalytics(teamCardsStartPageEvents.clickOnMetroLogo, {
    'click-on-metro-logo-on-team-cards': 'clicked',
  });
};

const updateFirestore = async (): Promise<void> => {
  const data = {
    nextRoute: teamCardsRoutesNames.teamCardsPasswordEntry,
  };

  return await updateDataInFirestore(data, Collection.TEAMCARD_REG);
};

firestoreRequest.onNext = onStartNowClick;
</script>

<style scoped>
.action-btn {
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .action-btn {
    margin-bottom: 34px;
    max-width: 240px;
  }
}

@media screen and (min-width: 1024px) {
  .mfs-button.action-btn {
    align-self: start !important;
  }
}

.tablet-and-up {
  display: none;
}

.metro-logo {
  position: absolute;
  top: 18px;
  left: 24px;
  z-index: 201;
}

.help-slider {
  background-color: #f2f5f8;
}

.text-container {
  padding: 22px 24px 32px 24px;
  text-align: center;
}

.home-container {
  min-height: 100vh;
}

@media screen and (min-width: 768px) {
  .up-to-tablet {
    display: none;
  }

  .tablet-and-up {
    display: initial;
  }

  .home-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .text-container {
    padding: 104px 0 74px 0;
  }

  .slider-information-margin {
    display: flex;
    flex-direction: column;
  }

  .help-slider {
    margin: 0;
    padding: 50px 0 80px 0;
  }

  .big-text {
    margin-bottom: 48px;
  }
}

@media screen and (min-width: 1024px) {
  .home-container {
    flex-direction: row-reverse;
  }

  .text-container,
  .help-slider {
    flex: 1;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .help-slider {
    overflow: hidden;
  }

  .slider-information-margin {
    text-align: left;
    margin-left: 48px;
  }
}

@media screen and (min-width: 1280px) {
  .slider-information-margin {
    margin-left: 80px;
  }
}

@media screen and (min-width: 1900px) {
  .slider-information-margin {
    margin-left: 200px;
  }
}

@media screen and (min-width: 2800px) {
  .slider-information-margin {
    margin-left: 400px;
  }
}
</style>
