/**
 * Checks if theme tag is present in HTML head
 * @returns {Boolean} return true or false.
 */
function isThemeTagPresent(): boolean {
  return !!document.head.querySelector('meta[name="theme-color"]');
}

/**
 * Set new color for theme tag, used mostly by Apple OS. iOS and desktop Safari.
 * @param {String} newThemeColor - new color, can be a hex value, rgb, shv and hsl. Default to almost white. Almost, because "pure" white is treated as neutral by Apple devices and change to #fff is not always respected.
 */
export function setNewThemeColor(newThemeColor = '#fcfcfc'): void {
  if (isThemeTagPresent()) {
    // @ts-ignore: TS notifies about possible empty object, but this check
    // is handled via isThemeTagPresent() function
    document.head.querySelector('meta[name="theme-color"]').setAttribute('content', newThemeColor);
  }
}
