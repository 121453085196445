<template>
  <svg
    width="56px"
    height="56px"
    viewBox="0 0 56 56"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-988.000000, -745.000000)">
        <g transform="translate(420.000000, 96.000000)">
          <g transform="translate(88.000000, 478.000000)">
            <g transform="translate(352.000000, 0.000000)"></g>
            <g
              transform="translate(352.000000, 173.000000)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <g transform="translate(132.000000, 0.000000)">
                <path
                  d="M39.8462609,12.4556659 L33.794087,6.12626319 C32.6993919,5.03640705 32.4252411,3.36723417 33.1137391,1.98395113 L33.1137391,1.98395113 C33.629257,0.95187285 34.6091249,0.230857857 35.7470791,0.0462637085 C36.8850334,-0.13833044 38.0422535,0.236015132 38.8570435,1.05229647 L39.8462609,2.04244066 L40.8354783,1.05229647 C41.6502682,0.236015132 42.8074884,-0.13833044 43.9454426,0.0462637085 C45.0833969,0.230857857 46.0632647,0.95187285 46.5787826,1.98395113 L46.5787826,1.98395113 C47.2696512,3.36439061 47.0015836,5.0323407 45.9130435,6.12626319 L39.8462609,12.4556659 Z"
                  stroke="#0064FE"
                ></path>
                <path
                  d="M0,45.4333162 C7.82608696,45.4333162 4.69565217,47 25.0434783,47 C31.1290435,47 32.685913,44.6290851 32.733913,40.2110367"
                  stroke="#002D72"
                ></path>
                <path
                  d="M0,29.5429642 L4.97113043,29.5429642 C5.57535815,29.5433057 6.14774373,29.2718006 6.53008696,28.8034895 L13.4045217,22.0375042 C14.3928753,21.0624193 15.8007725,20.6427859 17.1610435,20.9178475 L22.9836522,22.0855492 C24.1231304,22.3132406 24.8723478,23.2240061 24.3756522,25.2189169 C24.1778156,26.0146237 23.5172671,26.6105008 22.706087,26.7250223 L18.7826087,27.2806728 C18.3067724,27.3283148 17.8708871,27.5680119 17.5755251,27.9444586 C17.280163,28.3209053 17.1508387,28.8015826 17.2173913,29.2755835 L17.2173913,34.616931 C17.086432,35.2257357 16.7647302,35.7768003 16.2991304,36.1898815 L11.9624348,39.6136079"
                  stroke="#002D72"
                ></path>
                <path
                  d="M18.7826087,40.2110367 L44.8695652,40.2110367 C46.5984566,40.2110367 48,38.8081802 48,37.077669 L48,19.8441469 C48,18.1136357 46.5984566,16.7107792 44.8695652,16.7107792 L20.3478261,16.7107792 C18.6189347,16.7107792 17.2173913,18.1136357 17.2173913,19.8441469 L17.2173913,20.9262032"
                  stroke="#002D72"
                ></path>
                <line x1="29.0149565" y1="25.0664263" x2="48" y2="25.0664263" id="Path" stroke="#002D72"></line>
                <line x1="40.6956522" y1="33.4220734" x2="37.5652174" y2="33.4220734" id="Path" stroke="#002D72"></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
