<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#0052b4" d="M256 0h256v511.9H0v-256z" />
      <path
        fill="#eee"
        d="M256 0H100.2L66.7 14.9 33.4 0H0v33.4l15.7 31.3L0 100.2V256h33.4l34-13.6 32.8 13.6h33.4v-75.2l75.2 75.2H256l-7.7-21.3 7.7-10.2v-15.7l-75.2-75.2H256v-33.4l-11.8-33.4L256 33.4z"
      />
      <path fill="#d80027" d="M256 224.5l-91-91h-31.4L256 256z" />
      <path
        fill="#eee"
        d="M381.8 91.5l-11.3 35h-36.7l29.7 21.4-11.3 35 29.6-21.7 29.7 21.6L400 148l29.7-21.5H393l-11.3-34.9zm61.3 72.5l-11.3 35h-36.7l29.7 21.4-11.3 35 29.6-21.7 29.7 21.6-11.4-34.9 29.7-21.5h-36.7zm-122.8 10.3L309 209.2h-36.7l29.7 21.5-11.3 34.9 29.6-21.6 29.7 21.6-11.4-35 29.7-21.5h-36.7zM379.4 304l-11.3 34.9h-36.7l29.7 21.5-11.3 34.9 29.6-21.6 29.7 21.6-11.4-35 29.7-21.5h-36.7z"
      />
      <path
        fill="#d80027"
        d="M33.4 0v33.4H0v66.8h33.4V256h66.8V100.2H256V33.4H100.2V0zm348.4 116.8l-5.6 17.4h-18.4l14.9 10.8-5.7 17.4 14.8-10.8 14.9 10.8L391 145l14.8-10.8h-18.3zm61.2 72.5l-5.6 17.4H419l14.9 10.8-5.7 17.4 14.8-10.8 15 10.9-5.7-17.4 14.8-10.8h-18.3l-5.7-17.4zm-122.8 10.2l-5.6 17.5h-18.4l14.9 10.7-5.7 17.5 14.8-10.8 14.9 10.8-5.7-17.5 14.8-10.7H326l-5.7-17.5zm59.1 129.7l-5.6 17.5h-18.4l14.9 10.7-5.7 17.5 14.8-10.8 14.8 10.8-5.6-17.5 14.8-10.7H385z"
      />
    </g>
  </svg>
</template>
