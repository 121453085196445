<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#0052b4" d="M256 0h256v512H0V256z" />
      <path
        fill="#eee"
        d="M256 0H100.2L66.7 14.9 33.4 0H0v33.4l15.7 31.3L0 100.2v155.9h33.4l34-13.6 32.8 13.6h33.4v-75.3l75.2 75.3H256l-7.7-21.4 7.7-10.2v-15.7l-75.2-75.2H256v-33.4l-11.8-33.4L256 33.4z"
      />
      <path fill="#d80027" d="M256 224.5l-91-91h-31.4L256 256.1z" />
      <path fill="#d80027" d="M33.4 0v33.4H0v66.8h33.4v155.9h66.8V100.2H256V33.4H100.2V0z" />
      <path fill="#ffda44" d="M289.4 133.6V256c0 59.6 77.9 78 77.9 78s78-18.4 78-78V133.6h-156z" />
      <path
        fill="#ff9811"
        d="M356.2 178c0 12.4-10 44.6-22.3 44.6s-22.2-32.2-22.2-44.5c0-12.3 22.2-22.3 22.2-22.3s22.3 10 22.3 22.3z"
      />
      <path
        fill="#a2001d"
        d="M415.2 202.3a92.2 92.2 0 0 0 6.4-28c0-10.2-13.3-18.5-13.3-18.5s-13.4 8.3-13.4 18.6c0 6.4 2.8 19.2 6.4 28l-7.7 17.3a38.9 38.9 0 0 0 14.7 3 38.9 38.9 0 0 0 14.7-3z"
      />
      <path fill="#6da544" d="M350.6 256s-11.1 22.3-11.1 44.5H395c0-22.2-11.1-44.5-11.1-44.5l-16.7-11.1-16.7 11.1z" />
      <path fill="#d80027" d="M384 256v-5.6a16.7 16.7 0 0 0-33.4 0v5.6z" />
    </g>
  </svg>
</template>
