<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path
        fill="#eee"
        d="M0 122.5L89 0h67l356 55.7v66.7l-28.4 33.5 28.4 33.3V256l-26.6 32.2 26.6 34.6v66.8L485 421l27 35.3V512H0v-55.7l27.2-34.2L0 389.6v-66.8L30.6 251 0 189.2z"
      />
      <path
        fill="#338af3"
        d="M0 0v122.5h89V0zm155.9 0v122.4H256V55.7h256V0H155.9zM256 122.4v66.8h256v-66.8zm0 66.8H155.9V256H89v-66.8H0v133.6h512V256H256zM0 389.6v66.7h512v-66.7z"
      />
    </g>
  </svg>
</template>
