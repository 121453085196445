<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#eee" d="M0 167l254.6-36.6L512 166.9v178l-254.6 36.4L0 344.9z" />
      <path fill="#d80027" d="M0 0h512v166.9H0z" />
      <path fill="#333" d="M0 344.9h512V512H0z" />
      <path
        fill="#ff9811"
        d="M345 228.2h-66.7a22.3 22.3 0 0 0-44.6 0H167a23 23 0 0 0 23 22.2h-.8c0 12.3 10 22.3 22.3 22.3 0 12.3 10 22.3 22.2 22.3h44.6c12.3 0 22.2-10 22.2-22.3 12.3 0 22.3-10 22.3-22.3h-.8a23 23 0 0 0 23-22.2z"
      />
    </g>
  </svg>
</template>
