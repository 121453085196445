import type { AionData, Phone, TaxResidencies, Utm } from '@/types/OnboardingStore';

// interface for onboarding data
export interface OnboardingRequest {
  salutation: string;
  firstName: string;
  lastName: string;
  phone: Phone;
  dateOfBirth: string;
  placeOfBirth: string;
  countryOfBirth: string;
  incomeLevel: string;
  idOrigin: string;
  idType: IdType;
  address: Address;
  nace: string;
  businessType: number | null;
  taxResidencies: TaxResidencies;
  callbackUri?: string;
  optins: Array<string>;
  metroAccountId: string | null;
  aionData: AionData;
  utm: Utm;
  password?: string;
  title?: string | null;
  companyType?: CompanyType;
  company: {
    address: Address;
    startDate: string;
  };
  pep: {
    isPep: boolean;
    description: string;
    since: string;
  };
}

export interface Address {
  street: string;
  streetNumber: string;
  zip: string;
  city: string;
  country: string;
}

export enum IdType {
  PASSPORT = 'PASSPORT',
  IDCARD = 'IDCARD',
  RESIDENCY_PERMIT = 'RESIDENCY_PERMIT',
  PASSPORT_AND_RESIDENCY_PERMIT = 'PASSPORT_AND_RESIDENCY_PERMIT',
  PASSPORT_AND_CITY_REGISTRATION = 'PASSPORT_AND_CITY_REGISTRATION',
  IDCARD_AND_CITY_REGISTRATION = 'IDCARD_AND_CITY_REGISTRATION',
}

export enum CompanyType {
  GMBH = 'GMBH',
  GGMBH = 'GGMBH',
  GMBH_CO_KG = 'GMBH_CO_KG',
  AG = 'AG',
  GBR = 'GBR',
  UG = 'UG',
  VEREIN = 'VEREIN',
  EV = 'EV',
  EG = 'EG',
  KG = 'KG',
  OHG = 'OHG',
  KGAA = 'KGAA',
  EK = 'EK',
  KDOR = 'KDOR',
  SOLE_TRADER = 'SOLETRADER',
  SME = 'SME_PRIMARY',
  OTHER = 'OTHER',
}

export enum RepresentativeStatus {
  PRIMARY_REPRESENTATIVE = 'PRIMARY_REPRESENTATIVE',
  SECONDARY_REPRESENTATIVE = 'SECONDARY_REPRESENTATIVE',
  UBO = 'UBO',
}
