<template>
  <svg
    width="90px"
    height="90px"
    viewBox="0 0 100 100"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>email-send</title>
    <defs>
      <polygon id="path-1" points="0 0 65.609 0 65.609 60.0449 0 60.0449"></polygon>
    </defs>
    <g id="email-send" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="1.1-Send-to-teamcard_Desktop" transform="translate(-670.000000, -242.000000)">
        <g id="Group" transform="translate(466.000000, 96.000000)">
          <g id="email-send" transform="translate(204.000000, 146.000000)">
            <path
              d="M91.2395232,99 L7.76052323,99 C3.48152323,99 0.000523233623,95.519 0.000523233623,91.239 L0.000523233623,47.413 C0.000523233623,46.919 0.243523234,46.457 0.650523234,46.177 L17.3465232,34.699 C18.0295232,34.229 18.9635232,34.402 19.4315232,35.085 C19.9015232,35.768 19.7285232,36.701 19.0455232,37.17 L3.00052323,48.202 L3.00052323,91.239 C3.00052323,93.864 5.13552323,96 7.76052323,96 L91.2395232,96 C93.8645232,96 96.0005232,93.864 96.0005232,91.239 L96.0005232,48.202 L79.9555232,37.17 C79.2725232,36.701 79.0995232,35.768 79.5685232,35.085 C80.0385232,34.401 80.9715232,34.229 81.6545232,34.699 L98.3495232,46.177 C98.7575232,46.457 99.0005232,46.919 99.0005232,47.413 L99.0005232,91.239 C99.0005232,95.519 95.5185232,99 91.2395232,99"
              id="Fill-1"
              fill="#233566"
            ></path>
            <path
              d="M14.0232232,86.478 C13.5832232,86.478 13.1472232,86.286 12.8512232,85.915 C12.3332232,85.268 12.4382232,84.324 13.0852232,83.807 L33.9542232,67.111 C34.2202232,66.898 34.5502232,66.783 34.8912232,66.783 L64.1082232,66.783 C64.4492232,66.783 64.7792232,66.898 65.0462232,67.111 L85.9162232,83.807 C86.5632232,84.324 86.6672232,85.268 86.1492232,85.915 C85.6342232,86.561 84.6902232,86.667 84.0412232,86.149 L63.5822232,69.783 L35.4182232,69.783 L14.9592232,86.149 C14.6832232,86.371 14.3522232,86.478 14.0232232,86.478"
              id="Fill-3"
              fill="#233566"
            ></path>
            <g id="Group-7" transform="translate(16.695923, 0.000000)">
              <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
              </mask>
              <g id="Clip-6"></g>
              <path
                d="M64.109,60.0449 C63.281,60.0449 62.609,59.3739 62.609,58.5449 L62.609,5.6739 C62.609,4.1999 61.41,2.9999 59.935,2.9999 L5.674,2.9999 C4.2,2.9999 3,4.1999 3,5.6739 L3,58.5449 C3,59.3739 2.329,60.0449 1.5,60.0449 C0.671,60.0449 0,59.3739 0,58.5449 L0,5.6739 C0,2.5449 2.546,-0.0001 5.674,-0.0001 L59.935,-0.0001 C63.064,-0.0001 65.609,2.5449 65.609,5.6739 L65.609,58.5449 C65.609,59.3739 64.937,60.0449 64.109,60.0449"
                id="Fill-5"
                fill="#0064FE"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M32.8044,28.0435 C30.1794,28.0435 28.0434,30.1795 28.0434,32.8045 C28.0434,35.4295 30.1794,37.5655 32.8044,37.5655 C35.4294,37.5655 37.5654,35.4295 37.5654,32.8045 C37.5654,30.1795 35.4294,28.0435 32.8044,28.0435 M32.8044,40.5655 C28.5254,40.5655 25.0434,37.0845 25.0434,32.8045 C25.0434,28.5245 28.5254,25.0435 32.8044,25.0435 C37.0834,25.0435 40.5654,28.5245 40.5654,32.8045 C40.5654,37.0845 37.0834,40.5655 32.8044,40.5655"
                id="Fill-8"
                fill="#0064FE"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M32.7453,50.9941 C31.3143,50.9941 29.8833,50.8301 28.4873,50.5011 C19.3563,48.3361 13.4073,39.6911 14.6463,30.3901 C15.8873,21.0911 23.8853,14.3061 33.2703,14.6101 C43.3933,15.1581 51.1663,23.5011 50.9993,33.6051 L50.9993,35.9341 C50.9993,39.6391 47.9873,42.6521 44.2833,42.6521 C40.5783,42.6521 37.5653,39.6391 37.5653,35.9341 L37.5653,32.8041 C37.5653,31.9751 38.2373,31.3041 39.0653,31.3041 C39.8933,31.3041 40.5653,31.9751 40.5653,32.8041 L40.5653,35.9341 C40.5653,37.9841 42.2333,39.6521 44.2833,39.6521 C46.3323,39.6521 47.9993,37.9841 47.9993,35.9341 L47.9993,33.5801 C48.1403,25.0721 41.6143,18.0661 33.1413,17.6061 C25.3523,17.3661 18.6563,23.0211 17.6203,30.7871 C16.5853,38.5541 21.5533,45.7741 29.1773,47.5811 C31.6453,48.1631 34.2473,48.1291 36.7023,47.4861 C37.5053,47.2741 38.3243,47.7541 38.5343,48.5561 C38.7443,49.3571 38.2643,50.1771 37.4633,50.3871 C35.9233,50.7911 34.3343,50.9941 32.7453,50.9941"
                id="Fill-10"
                fill="#0064FE"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M72.4573232,65.6089 C71.9733232,65.6089 71.4973232,65.3749 71.2083232,64.9409 C70.7483232,64.2519 70.9353232,63.3199 71.6243232,62.8609 L96.6683232,46.1649 C97.3563232,45.7059 98.2883232,45.8909 98.7483232,46.5809 C99.2083232,47.2709 99.0213232,48.2019 98.3323232,48.6609 L73.2883232,65.3569 C73.0323232,65.5269 72.7433232,65.6089 72.4573232,65.6089"
              id="Fill-12"
              fill="#233566"
            ></path>
            <path
              d="M26.5422232,65.6089 C26.2562232,65.6089 25.9672232,65.5269 25.7112232,65.3569 L0.668223234,48.6609 C-0.0207767664,48.2019 -0.207776766,47.2709 0.252223234,46.5809 C0.711223234,45.8909 1.64122323,45.7049 2.33222323,46.1649 L27.3752232,62.8609 C28.0652232,63.3199 28.2512232,64.2519 27.7922232,64.9409 C27.5022232,65.3749 27.0272232,65.6089 26.5422232,65.6089"
              id="Fill-14"
              fill="#233566"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
