<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path
        fill="#eee"
        d="M0 155.8l255-30.6 257 30.6v33.4l-27.7 67.2 27.7 66.4v33.4l-256 32.4L0 356.2v-33.4l28.8-68.5L0 189.2z"
      />
      <path fill="#a2001d" d="M0 0h512v155.8H0z" />
      <path fill="#0052b4" d="M0 189.2h512v133.6H0z" />
      <path fill="#496e2d" d="M0 356.2h512V512H0z" />
    </g>
  </svg>
</template>
