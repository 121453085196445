<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#d80027" d="M0 0h144.7l113.2 39.9L367.3 0H512v512H367.3l-108-45.3L144.8 512H0z" />
      <path fill="#eee" d="M144.7 0h222.6v512H144.7z" />
      <path
        fill="#d80027"
        d="M300.5 289.4L345 267l-22.2-11v-22.3L278.3 256l22.2-44.5h-22.2L256 178l-22.3 33.4h-22.2l22.2 44.5-44.5-22.3V256L167 267.1l44.5 22.3-11.2 22.3H245V345h22v-33.3h44.6z"
      />
    </g>
  </svg>
</template>
