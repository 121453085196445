<template>
  <div class="big-text-section">
    <MfsFont font-name="display-4" color="primary-v2" class="big-header">
      {{ t('teamCardsHomeSlider.bigHeader') }}
    </MfsFont>
    <MfsFont fontName="body-2-regular" color="primary-v2" class="big-description mb-0">
      {{ t('teamCardsHomeSlider.bigDescription') }}
    </MfsFont>
  </div>
</template>

<script setup lang="ts">
import MfsFont from '@/components/atoms/MfsFont.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .big-text-section {
    max-width: 424px;
    margin: 0 auto;
  }

  .big-header {
    font-family: CAMetro;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 44px;
    margin-bottom: 16px;
  }

  .big-description {
    font-size: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .big-text-section {
    margin: 0;
  }
}

@media screen and (min-width: 1280px) {
  .big-text-section {
    max-width: 551px;
  }

  .big-header {
    font-size: 56px;
    margin-bottom: 16px;
  }

  .big-description {
    font-size: 18px;
  }
}
</style>
