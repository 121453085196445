<template>
  <div />
</template>

<script setup lang="ts">
import { FRESHCHAT_HOST, MethodType, freshchatUuid } from '@/constants';
import { MFS_FRESHCHAT_TOKEN } from '@/env';
import { Onboarding } from '@/types/ApiName';
import { EventKeys, trackEvents } from '@/utils/analytics/trackEvents';
import { sendRequest } from '@/utils/axios';
import { setCustomDataCobrowse, startCobrowse, type cobrowseCustomData } from '@/utils/cobrowse';
import type { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import { nextTick, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

type ChatPropertyConfig = {
  cssNames?: {
    widget: string;
  };
  headerProperty?: {
    hideChatButton: boolean;
    backgroundColor: string;
    direction: 'ltr';
  };
};

type FreshChatConfig = {
  token: string;
  host: string;
  tags?: string[];
  config?: ChatPropertyConfig;
  externalId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

type frameChangeEventData = {
  success: boolean;
  data: { frameState: string };
  freshchat_uuid: string;
};

type userChangeEventData = {
  success: boolean;
  data: {
    userState: string;
    freshchat_uuid: string;
  };
};

const widgetConfig: FreshChatConfig = {
  token: MFS_FRESHCHAT_TOKEN,
  host: FRESHCHAT_HOST,
  tags: ['0027xq7a9y_logged_out'], // <-- this comes from Freshchat configuration, see https://metro-fs.freshchat.com/a/429764772271982/settings/channels
  config: {
    cssNames: {
      widget: 'custom_fc_frame',
    },
    headerProperty: {
      hideChatButton: Number(document.documentElement.style.getPropertyValue('--mfs-app-width').split('px')[0]) < 600,
      direction: 'ltr',
      backgroundColor: '#0064fe',
    },
  },
};

const route = useRoute();

onMounted(async () => {
  window?.fcWidget?.init(widgetConfig);

  nextTick(() => {
    window?.fcWidget?.on('frame:statechange', (data: frameChangeEventData) => onWidgetFrameStateChange(data));
    window?.fcWidget?.on('user:statechange', (data: userChangeEventData) => onWidgetUserStateChange(data));
    window?.fcWidget?.on('widget:opened', () => onWidgetOpen());
  });
});

const isFcUserCreated = ref(false);
const randomUserId = `${crypto.getRandomValues(new Uint32Array(1))[0]}-${dayjs().format('DD/MM/YY')}`;

function onWidgetOpen(): void {
  if (isFcUserCreated.value) {
    startCobrowse();
  }
  trackEvents(EventKeys.FRESHCHAT_CLICK, route.name);
}

function onWidgetFrameStateChange(data: frameChangeEventData): void {
  if (data.success === false && data.data.frameState === 'not_authenticated') {
    authenticateUser(data);
  }
}

async function onWidgetUserStateChange(data: userChangeEventData): Promise<void> {
  if (data.success) {
    const userData = data.data;
    // authenticate user success
    if (userData) {
      isFcUserCreated.value = true;

      if (userData.userState === 'created') {
        onFcUserCreation();
      }
    }
  } else {
    const userData = data.data;

    if (userData) {
      if (
        userData.userState === 'not_loaded' ||
        userData.userState === 'unloaded' ||
        userData.userState === 'not_created' ||
        userData.userState === 'not_authenticated'
      ) {
        authenticateUser(userData);
      }
    }
  }
}

async function authenticateUser(userData: { freshchat_uuid: string }) {
  const getFreshchatUuid = userData?.freshchat_uuid || localStorage.getItem(freshchatUuid);

  if (getFreshchatUuid) {
    return await authenticateCB(getFreshchatUuid);
  }

  // Generate UUID and create new user
  const resp = await window?.fcWidget?.user?.getUUID();
  const uuid = resp?.data?.uuid;
  if (uuid) {
    localStorage.setItem(freshchatUuid, uuid);
    await authenticateCB(uuid);
  }
}

async function authenticateCB(uuid: string) {
  const request: AxiosRequestConfig = {
    method: MethodType.POST,
    url: Onboarding.AION_FRESHWORKS_JWT_API,
    data: { freshchatUuid: uuid },
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await sendRequest<{ jwt: string }>(request);

    const signedUUID = response.data.jwt;
    window?.fcWidget?.authenticate(signedUUID);
  } catch (error: any) {
    console.error('Freshchat loading failed with error:', error.code);
  }
}

async function onFcUserCreation(): Promise<void> {
  // on user creation, we need to update both Cobrowse info
  // and set custom meta field in Freshchat so that
  // agents can quickly identify the user
  const returnedData = await window?.fcWidget?.user?.get();

  setTimeout(() => {
    window?.fcWidget?.user?.update({
      meta: {
        cobrowse: randomUserId,
      },
    });
  }, 10000);

  startCobrowse();
  updateCobrowseUser(returnedData.data);
}

function updateCobrowseUser(userData: { firstName: string }): void {
  const customUserData: cobrowseCustomData = {
    user_id: randomUserId,
    user_name: userData.firstName,
  };

  setCustomDataCobrowse(customUserData);
}
</script>

<style>
.widget-open-animate {
  transform-origin: right top !important;
}

.custom_fc_frame {
  left: auto !important;
  right: 15px !important;
}

.fc-open.custom_fc_frame {
  right: 0 !important;
}

@media (min-width: 640px) {
  .widget-open-animate {
    transform-origin: right bottom !important;
  }

  .custom_fc_frame {
    right: 48px !important;
    bottom: 48px !important;
  }

  .fc-open.custom_fc_frame {
    right: 48px !important;
  }
}
</style>
