<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#0052b4" d="M0 167l253.8-19.3L512 167v178l-254.9 32.3L0 345z" />
      <path fill="#eee" d="M0 0h512v167H0z" />
      <path fill="#d80027" d="M0 345h512v167H0z" />
      <path fill="#eee" d="M66.2 144.7v127.7c0 72.6 94.9 95 94.9 95s94.9-22.4 94.9-95V144.7z" />
      <path fill="#d80027" d="M88.5 144.7v127.7a54 54 0 0 0 5.6 24.1h134a53.6 53.6 0 0 0 5.6-24.1V144.7z" />
      <path
        fill="#eee"
        d="M205.6 233.7h-33.4v-22.2h22.3v-22.3h-22.3V167H150v22.2h-22.3v22.3H150v22.2h-33.4V256H150v22.3h22.2V256h33.4z"
      />
      <path
        fill="#0052b4"
        d="M124.5 327.6a160 160 0 0 0 36.6 16.6c7.5-2.3 22.3-7.6 36.6-16.6a80 80 0 0 0 30.4-31 38.8 38.8 0 0 0-22.5-7.2c-3 0-6 .4-8.8 1a39 39 0 0 0-71.4 0 39 39 0 0 0-8.8-1c-8.4 0-16.1 2.6-22.5 7.1a80 80 0 0 0 30.4 31.1z"
      />
    </g>
  </svg>
</template>
