<template>
  <div class="bg-base-bg">
    <CoreLayoutV2 :is-with-sticky-header="isWithStickyHeader" :is-onb-step-page="true">
      <template v-slot:header>
        <MfsOnboardingV2Header v-if="isWithStickyHeader" />
      </template>

      <template v-slot:sidebar>
        <MfsOnboardingV2SideBar />
      </template>

      <template v-slot:body>
        <div class="flex min-h-full lg:mx-10 lg:grow lg:flex-col lg:pt-24 xl:mx-0">
          <MfsIcon
            v-if="commonStore.basePageDetails.isBackArrowVisible"
            :size="18"
            color="primary"
            icon-name="arrow-left"
            classes="hidden translate-x-0 cursor-pointer !self-start transition duration-[250] ease-in-out hover:translate-x-[2px] lg:absolute lg:left-[280px] lg:top-8 lg:block"
            @click="goBack"
          />

          <div class="mfs-container">
            <slot />
          </div>
        </div>
      </template>
    </CoreLayoutV2>
  </div>
</template>

<script setup lang="ts">
import MfsIcon from '@/components/atoms/MfsIcon.vue';
import MfsOnboardingV2Header from '@/components/organisms/MfsOnboardingV2Header.vue';
import MfsOnboardingV2SideBar from '@/components/organisms/MfsOnboardingV2SideBar.vue';
import CoreLayoutV2 from '@/components/templates/CoreLayoutV2.vue';
import { useCommonPageFeats } from '@/composables/useCommonPageFeats';
import { useCommonStore } from '@/stores/common';

withDefaults(
  defineProps<{
    isWithStickyHeader?: boolean;
  }>(),
  {
    isWithStickyHeader: true,
  }
);
const { goBack } = useCommonPageFeats();
const commonStore = useCommonStore();
</script>
