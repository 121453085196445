import AD from './country-flags/AD.vue';
import AE from './country-flags/AE.vue';
import AF from './country-flags/AF.vue';
import AG from './country-flags/AG.vue';
import AI from './country-flags/AI.vue';
import AL from './country-flags/AL.vue';
import AM from './country-flags/AM.vue';
import AO from './country-flags/AO.vue';
import AR from './country-flags/AR.vue';
import AS from './country-flags/AS.vue';
import AT from './country-flags/AT.vue';
import AU from './country-flags/AU.vue';
import AW from './country-flags/AW.vue';
import AX from './country-flags/AX.vue';
import AZ from './country-flags/AZ.vue';
import BA from './country-flags/BA.vue';
import BB from './country-flags/BB.vue';
import BD from './country-flags/BD.vue';
import BE from './country-flags/BE.vue';
import BF from './country-flags/BF.vue';
import BG from './country-flags/BG.vue';
import BH from './country-flags/BH.vue';
import BI from './country-flags/BI.vue';
import BJ from './country-flags/BJ.vue';
import BL from './country-flags/BL.vue';
import BM from './country-flags/BM.vue';
import BN from './country-flags/BN.vue';
import BO from './country-flags/BO.vue';
import BR from './country-flags/BR.vue';
import BS from './country-flags/BS.vue';
import BT from './country-flags/BT.vue';
import BW from './country-flags/BW.vue';
import BY from './country-flags/BY.vue';
import BZ from './country-flags/BZ.vue';
import CA from './country-flags/CA.vue';
import CC from './country-flags/CC.vue';
import CD from './country-flags/CD.vue';
import CF from './country-flags/CF.vue';
import CG from './country-flags/CG.vue';
import CH from './country-flags/CH.vue';
import CI from './country-flags/CI.vue';
import CK from './country-flags/CK.vue';
import CL from './country-flags/CL.vue';
import CM from './country-flags/CM.vue';
import CN from './country-flags/CN.vue';
import CO from './country-flags/CO.vue';
import CR from './country-flags/CR.vue';
import CU from './country-flags/CU.vue';
import CV from './country-flags/CV.vue';
import CW from './country-flags/CW.vue';
import CX from './country-flags/CX.vue';
import CY from './country-flags/CY.vue';
import CZ from './country-flags/CZ.vue';
import DE from './country-flags/DE.vue';
import DJ from './country-flags/DJ.vue';
import DK from './country-flags/DK.vue';
import DM from './country-flags/DM.vue';
import DO from './country-flags/DO.vue';
import DZ from './country-flags/DZ.vue';
import EC from './country-flags/EC.vue';
import EE from './country-flags/EE.vue';
import EG from './country-flags/EG.vue';
import EH from './country-flags/EH.vue';
import ER from './country-flags/ER.vue';
import ES from './country-flags/ES.vue';
import ET from './country-flags/ET.vue';
import FI from './country-flags/FI.vue';
import FJ from './country-flags/FJ.vue';
import FK from './country-flags/FK.vue';
import FM from './country-flags/FM.vue';
import FO from './country-flags/FO.vue';
import FR from './country-flags/FR.vue';
import GA from './country-flags/GA.vue';
import GB from './country-flags/GB.vue';
import GD from './country-flags/GD.vue';
import GE from './country-flags/GE.vue';
import GF from './country-flags/GF.vue';
import GG from './country-flags/GG.vue';
import GH from './country-flags/GH.vue';
import GI from './country-flags/GI.vue';
import GL from './country-flags/GL.vue';
import GM from './country-flags/GM.vue';
import GN from './country-flags/GN.vue';
import GQ from './country-flags/GQ.vue';
import GR from './country-flags/GR.vue';
import GT from './country-flags/GT.vue';
import GU from './country-flags/GU.vue';
import GW from './country-flags/GW.vue';
import GY from './country-flags/GY.vue';
import HK from './country-flags/HK.vue';
import HN from './country-flags/HN.vue';
import HR from './country-flags/HR.vue';
import HT from './country-flags/HT.vue';
import HU from './country-flags/HU.vue';
import ID from './country-flags/ID.vue';
import IE from './country-flags/IE.vue';
import IL from './country-flags/IL.vue';
import IM from './country-flags/IM.vue';
import IN from './country-flags/IN.vue';
import IO from './country-flags/IO.vue';
import IQ from './country-flags/IQ.vue';
import IR from './country-flags/IR.vue';
import IS from './country-flags/IS.vue';
import IT from './country-flags/IT.vue';
import JE from './country-flags/JE.vue';
import JM from './country-flags/JM.vue';
import JO from './country-flags/JO.vue';
import JP from './country-flags/JP.vue';
import KE from './country-flags/KE.vue';
import KG from './country-flags/KG.vue';
import KH from './country-flags/KH.vue';
import KI from './country-flags/KI.vue';
import KM from './country-flags/KM.vue';
import KN from './country-flags/KN.vue';
import KP from './country-flags/KP.vue';
import KR from './country-flags/KR.vue';
import KW from './country-flags/KW.vue';
import KY from './country-flags/KY.vue';
import KZ from './country-flags/KZ.vue';
import LA from './country-flags/LA.vue';
import LB from './country-flags/LB.vue';
import LC from './country-flags/LC.vue';
import LI from './country-flags/LI.vue';
import LK from './country-flags/LK.vue';
import LR from './country-flags/LR.vue';
import LS from './country-flags/LS.vue';
import LT from './country-flags/LT.vue';
import LU from './country-flags/LU.vue';
import LV from './country-flags/LV.vue';
import LY from './country-flags/LY.vue';
import MA from './country-flags/MA.vue';
import MC from './country-flags/MC.vue';
import MD from './country-flags/MD.vue';
import ME from './country-flags/ME.vue';
import MG from './country-flags/MG.vue';
import MH from './country-flags/MH.vue';
import MK from './country-flags/MK.vue';
import ML from './country-flags/ML.vue';
import MM from './country-flags/MM.vue';
import MN from './country-flags/MN.vue';
import MO from './country-flags/MO.vue';
import MP from './country-flags/MP.vue';
import MQ from './country-flags/MQ.vue';
import MR from './country-flags/MR.vue';
import MS from './country-flags/MS.vue';
import MT from './country-flags/MT.vue';
import MU from './country-flags/MU.vue';
import MV from './country-flags/MV.vue';
import MW from './country-flags/MW.vue';
import MX from './country-flags/MX.vue';
import MY from './country-flags/MY.vue';
import MZ from './country-flags/MZ.vue';
import NA from './country-flags/NA.vue';
import NE from './country-flags/NE.vue';
import NF from './country-flags/NF.vue';
import NG from './country-flags/NG.vue';
import NI from './country-flags/NI.vue';
import NL from './country-flags/NL.vue';
import NO from './country-flags/NO.vue';
import NP from './country-flags/NP.vue';
import NR from './country-flags/NR.vue';
import NU from './country-flags/NU.vue';
import NZ from './country-flags/NZ.vue';
import OM from './country-flags/OM.vue';
import PA from './country-flags/PA.vue';
import PE from './country-flags/PE.vue';
import PF from './country-flags/PF.vue';
import PG from './country-flags/PG.vue';
import PH from './country-flags/PH.vue';
import PK from './country-flags/PK.vue';
import PL from './country-flags/PL.vue';
import PN from './country-flags/PN.vue';
import PR from './country-flags/PR.vue';
import PS from './country-flags/PS.vue';
import PT from './country-flags/PT.vue';
import PW from './country-flags/PW.vue';
import PY from './country-flags/PY.vue';
import QA from './country-flags/QA.vue';
import RO from './country-flags/RO.vue';
import RS from './country-flags/RS.vue';
import RU from './country-flags/RU.vue';
import RW from './country-flags/RW.vue';
import SA from './country-flags/SA.vue';
import SB from './country-flags/SB.vue';
import SC from './country-flags/SC.vue';
import SD from './country-flags/SD.vue';
import SE from './country-flags/SE.vue';
import SG from './country-flags/SG.vue';
import SI from './country-flags/SI.vue';
import SK from './country-flags/SK.vue';
import SL from './country-flags/SL.vue';
import SM from './country-flags/SM.vue';
import SN from './country-flags/SN.vue';
import SO from './country-flags/SO.vue';
import SR from './country-flags/SR.vue';
import SS from './country-flags/SS.vue';
import ST from './country-flags/ST.vue';
import SV from './country-flags/SV.vue';
import SX from './country-flags/SX.vue';
import SY from './country-flags/SY.vue';
import SZ from './country-flags/SZ.vue';
import TC from './country-flags/TC.vue';
import TD from './country-flags/TD.vue';
import TG from './country-flags/TG.vue';
import TH from './country-flags/TH.vue';
import TJ from './country-flags/TJ.vue';
import TK from './country-flags/TK.vue';
import TL from './country-flags/TL.vue';
import TM from './country-flags/TM.vue';
import TN from './country-flags/TN.vue';
import TO from './country-flags/TO.vue';
import TR from './country-flags/TR.vue';
import TT from './country-flags/TT.vue';
import TV from './country-flags/TV.vue';
import TW from './country-flags/TW.vue';
import TZ from './country-flags/TZ.vue';
import UA from './country-flags/UA.vue';
import UG from './country-flags/UG.vue';
import US from './country-flags/US.vue';
import UY from './country-flags/UY.vue';
import UZ from './country-flags/UZ.vue';
import VA from './country-flags/VA.vue';
import VC from './country-flags/VC.vue';
import VE from './country-flags/VE.vue';
import VG from './country-flags/VG.vue';
import VI from './country-flags/VI.vue';
import VN from './country-flags/VN.vue';
import VU from './country-flags/VU.vue';
import WS from './country-flags/WS.vue';
import XK from './country-flags/XK.vue';
import XX from './country-flags/XX.vue';
import YE from './country-flags/YE.vue';
import ZA from './country-flags/ZA.vue';
import ZM from './country-flags/ZM.vue';
import ZW from './country-flags/ZW.vue';

export const countriesIconsMap: { [key: string]: object } = {
  AD: AD,
  AE: AE,
  AF: AF,
  AG: AG,
  AI: AI,
  AL: AL,
  AM: AM,
  AO: AO,
  AR: AR,
  AS: AS,
  AT: AT,
  AU: AU,
  AW: AW,
  AX: AX,
  AZ: AZ,
  BA: BA,
  BB: BB,
  BD: BD,
  BE: BE,
  BF: BF,
  BG: BG,
  BH: BH,
  BI: BI,
  BJ: BJ,
  BL: BL,
  BM: BM,
  BN: BN,
  BO: BO,
  BR: BR,
  BS: BS,
  BT: BT,
  BW: BW,
  BY: BY,
  BZ: BZ,
  CA: CA,
  CC: CC,
  CD: CD,
  CF: CF,
  CG: CG,
  CH: CH,
  CI: CI,
  CK: CK,
  CL: CL,
  CM: CM,
  CN: CN,
  CO: CO,
  CR: CR,
  CU: CU,
  CV: CV,
  CW: CW,
  CX: CX,
  CY: CY,
  CZ: CZ,
  DE: DE,
  DJ: DJ,
  DK: DK,
  DM: DM,
  DO: DO,
  DZ: DZ,
  EC: EC,
  EE: EE,
  EG: EG,
  EH: EH,
  ER: ER,
  ES: ES,
  ET: ET,
  FI: FI,
  FJ: FJ,
  FK: FK,
  FM: FM,
  FO: FO,
  FR: FR,
  GA: GA,
  GB: GB,
  GD: GD,
  GE: GE,
  GF: GF,
  GG: GG,
  GH: GH,
  GI: GI,
  GL: GL,
  GM: GM,
  GN: GN,
  GQ: GQ,
  GR: GR,
  GT: GT,
  GU: GU,
  GW: GW,
  GY: GY,
  HK: HK,
  HN: HN,
  HR: HR,
  HT: HT,
  HU: HU,
  ID: ID,
  IE: IE,
  IL: IL,
  IM: IM,
  IN: IN,
  IO: IO,
  IQ: IQ,
  IR: IR,
  IS: IS,
  IT: IT,
  JE: JE,
  JM: JM,
  JO: JO,
  JP: JP,
  KE: KE,
  KG: KG,
  KH: KH,
  KI: KI,
  KM: KM,
  KN: KN,
  KP: KP,
  KR: KR,
  KW: KW,
  KY: KY,
  KZ: KZ,
  LA: LA,
  LB: LB,
  LC: LC,
  LI: LI,
  LK: LK,
  LR: LR,
  LS: LS,
  LT: LT,
  LU: LU,
  LV: LV,
  LY: LY,
  MA: MA,
  MC: MC,
  MD: MD,
  ME: ME,
  MG: MG,
  MH: MH,
  MK: MK,
  ML: ML,
  MM: MM,
  MN: MN,
  MO: MO,
  MP: MP,
  MQ: MQ,
  MR: MR,
  MS: MS,
  MT: MT,
  MU: MU,
  MV: MV,
  MW: MW,
  MX: MX,
  MY: MY,
  MZ: MZ,
  NA: NA,
  NE: NE,
  NF: NF,
  NG: NG,
  NI: NI,
  NL: NL,
  NO: NO,
  NP: NP,
  NR: NR,
  NU: NU,
  NZ: NZ,
  OM: OM,
  PA: PA,
  PE: PE,
  PF: PF,
  PG: PG,
  PH: PH,
  PK: PK,
  PL: PL,
  PN: PN,
  PR: PR,
  PS: PS,
  PT: PT,
  PW: PW,
  PY: PY,
  QA: QA,
  RO: RO,
  RS: RS,
  RU: RU,
  RW: RW,
  SA: SA,
  SB: SB,
  SC: SC,
  SD: SD,
  SE: SE,
  SG: SG,
  SI: SI,
  SK: SK,
  SL: SL,
  SM: SM,
  SN: SN,
  SO: SO,
  SR: SR,
  SS: SS,
  ST: ST,
  SV: SV,
  SX: SX,
  SY: SY,
  SZ: SZ,
  TC: TC,
  TD: TD,
  TG: TG,
  TH: TH,
  TJ: TJ,
  TK: TK,
  TL: TL,
  TM: TM,
  TN: TN,
  TO: TO,
  TR: TR,
  TT: TT,
  TV: TV,
  TW: TW,
  TZ: TZ,
  UA: UA,
  UG: UG,
  US: US,
  UY: UY,
  UZ: UZ,
  VA: VA,
  VC: VC,
  VE: VE,
  VG: VG,
  VI: VI,
  VN: VN,
  VU: VU,
  WS: WS,
  XK: XK,
  XX: XX,
  YE: YE,
  ZA: ZA,
  ZM: ZM,
  ZW: ZW,
};
