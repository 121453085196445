<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <circle id="6siryie3xa" cx="12" cy="12" r="10" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-40 -349) translate(25 349) translate(15)">
            <mask id="o51k10dg2b" fill="#fff">
              <use xlink:href="#6siryie3xa" />
            </mask>
            <g mask="url(#o51k10dg2b)">
              <g>
                <path fill="#FFF" d="M0 0H20V10H0z" transform="translate(2 2)" />
                <path fill="#DC143C" d="M0 10H20V20H0z" transform="translate(2 2)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
