import type { Env } from '@/types/env';
import devEnv from './env.dev';
import envE2eCi from './env.e2e-ci';
import envE2eLocal from './env.e2e-local';
import ppEnv from './env.pp';
import prodEnv from './env.prod';

let envVariables: Env;
const hostnameForConfig = window?.location?.hostname;

if (import.meta.env.MODE === 'e2e-ci') {
  envVariables = envE2eCi;
} else if (import.meta.env.MODE === 'e2e-local') {
  envVariables = envE2eLocal;
} else if (import.meta.env.DEV || /^get.dev.metro-fs.com/.test(hostnameForConfig)) {
  envVariables = devEnv;
} else if (/^get.pp.metro-fs.com/.test(hostnameForConfig)) {
  envVariables = ppEnv;
} else {
  envVariables = prodEnv;
}

// use only to augment Vue types
// TODO: probably remove after migrating to Vue 3
export default envVariables;

export const {
  MFS_POSTHOG_API_HOST,
  MFS_POSTHOG_TOKEN,
  MFS_PLACES_API_KEY,
  MFS_TERMS_BUCKET,
  MFS_COBROWSE_API,
  MFS_COBROWSE_LICENSE,
  MFS_BV2_API_BASE_URI,
  MFS_FIREBASE_APIKEY,
  MFS_FIREBASE_APP_ID,
  MFS_FIREBASE_AUTHDOMAIN,
  MFS_FIREBASE_AUTH_EMULATOR_HOST,
  MFS_FIREBASE_MASSENGER_SENDER_ID,
  MFS_FIREBASE_PROJECT_ID,
  MFS_FIRESTORE_EMULATOR_HOST,
  MFS_FIRESTORE_EMULATOR_PORT,
  MFS_SENTRY_DSN,
  MFS_ENV,
  MFS_FRESHCHAT_TOKEN,
  MFS_SSO_CONTEXT_URI,
  MFS_HOMEPAGE,
  MFS_FIREBASE_ANDROID_DOWNLOAD_URL,
  MFS_FIREBASE_APP_URL,
  MFS_FIREBASE_IOS_DOWNLOAD_URL,
} = envVariables;
