import { MFS_ENV, MFS_SENTRY_DSN } from '@/env';
import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/vue';
import { version } from './../../package.json';

const hostnameForConfig = window?.location?.hostname;

function _initSentry(app: any) {
  Sentry.init({
    app,
    dsn: MFS_SENTRY_DSN,
    environment: MFS_ENV,
    integrations: [
      Sentry.replayIntegration(),
      captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  if (MFS_ENV === 'production') {
    Sentry.setTag('release_version', version);
  }
}

// initialize sentry
export function initializeSentry(app: any) {
  if (
    import.meta.env.MODE !== 'e2e-ci' &&
    import.meta.env.MODE !== 'e2e-local' &&
    !/^localhost/.test(hostnameForConfig)
  ) {
    _initSentry(app);
  }
}
