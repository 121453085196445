<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <path d="M0 0H24V24H0z" transform="translate(-312 -20) translate(312 20)" />
          <path
            fill="currentColor"
            d="M8.138 6.769c2.558 0 4.638 2.404 4.638 5.36 0 1.733-.736 3.367-1.944 4.365v1.25l3.395 1.198c1.226.434 2.049 1.59 2.049 2.878v1.449c0 .414-.335.75-.75.75H.75c-.415 0-.75-.336-.75-.75V21.82c0-1.289.824-2.445 2.048-2.878l3.396-1.198v-1.25C4.235 15.496 3.5 13.861 3.5 12.127 3.5 9.173 5.58 6.77 8.14 6.77zm0 1.5C6.408 8.269 5 10.001 5 12.129c0 1.397.619 2.69 1.617 3.374.204.14.327.371.327.618v2.154c0 .318-.2.601-.5.707l-3.896 1.375c-.627.221-1.048.809-1.048 1.463v.699h13.276v-.699c0-.654-.421-1.241-1.048-1.463l-3.896-1.375c-.299-.106-.5-.39-.5-.707V16.12c0-.248.122-.478.326-.618.998-.685 1.618-1.977 1.618-3.375 0-2.127-1.408-3.859-3.138-3.859zm11.496-3.415c0-.545-.442-.987-.988-.987-.545 0-.986.442-.986.987 0 .545.441.987.986.987.546 0 .988-.442.988-.987zm-2.936 0c0-.545-.442-.987-.987-.987-.545 0-.987.442-.987.987 0 .545.442.987.987.987.545 0 .987-.442.987-.987zm-3.711.987c.545 0 .987-.442.987-.987 0-.545-.442-.987-.987-.987-.544 0-.987.442-.987.987 0 .545.443.987.987.987zM24 .751v8.803c0 .414-.335.75-.75.75h-3.14l-4.188 3.724c-.14.125-.318.19-.498.19-.104 0-.209-.023-.307-.067-.27-.12-.443-.388-.443-.684v-3.163h-.717c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.467c.415 0 .75.336.75.75v2.243l3.154-2.804c.137-.121.314-.189.498-.189H22.5V1.5H9.326v3.163c0 .414-.336.75-.75.75-.415 0-.75-.336-.75-.75V.75c0-.415.336-.75.75-.75H23.25c.415 0 .75.335.75.75z"
            transform="translate(-312 -20) translate(312 20)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
