import { featureFlagsNames } from '@/constants';
import posthog from '@/plugins/posthog';

const featureFlagsStatus: {
  [key in keyof typeof featureFlagsNames]: boolean;
} = {
  DEV_TEST: false,
  RECORD_SESSION: false,
  MAINTENANCE_MODE: false,
  NEW_COMPANY_SELECTION: false,
};

export function initFeatureFlags(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!posthog) {
      reject(new Error('PostHog plugin does not exist'));
    }
    if (!posthog.onFeatureFlags) {
      reject(new Error('PostHog does not have onFeatureFlags method'));
    }

    posthog.onFeatureFlags(() => {
      if (posthog.isFeatureEnabled(featureFlagsNames.DEV_TEST)) {
        featureFlagsStatus.DEV_TEST = true;
      }

      if (posthog.isFeatureEnabled(featureFlagsNames.RECORD_SESSION)) {
        posthog.startSessionRecording();
        featureFlagsStatus.RECORD_SESSION = true;
      }

      if (posthog.isFeatureEnabled(featureFlagsNames.MAINTENANCE_MODE)) {
        featureFlagsStatus.MAINTENANCE_MODE = true;
      }

      if (posthog.isFeatureEnabled(featureFlagsNames.NEW_COMPANY_SELECTION)) {
        featureFlagsStatus.NEW_COMPANY_SELECTION = true;
      }

      resolve();
    });
  });
}

export default featureFlagsStatus;
