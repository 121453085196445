import { getDefaultValidation } from '@/utils/analytics/defaultValidation';
import { getDefaultValidationSme } from '@/utils/analytics/defaultValidationSme';
import { validationEvents } from '@/utils/analytics/eventNames';
import { validationEventsSme } from '@/utils/analytics/smeEventNames';
import type { Validation } from '@vuelidate/core';
import type { RouteRecordName } from 'vue-router';
import { getPageKey } from './analytics/getPageKey';
import { trackAnalytics } from './analytics/sendAnalyticsUtil';

const getDefaultValidationObject = (eventName: string) => {
  const defaultValidation: Record<string, object> = { ...getDefaultValidation, ...getDefaultValidationSme };
  return defaultValidation[eventName] || defaultValidation['default'];
};

const returnFirstErrElemFromForm = (vuelidate: Validation) => {
  const el = vuelidate.$silentErrors[0].$property;
  return document.getElementById(vuelidate[el].inputId.$response);
};

const scrollToAndFocusElem = (elem: HTMLElement | null) => {
  if (!elem) return;

  elem.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });

  setTimeout(() => {
    elem.focus();
  }, 500);
};

export const validateFormSubmit = async (onSuccess: Function, routeName: RouteRecordName, vuelidate: Validation) => {
  const pageName = routeName ? getPageKey(routeName) : '';
  const eventName = 'validate' + pageName.split(' ').join('');
  const validationEventsObject = { ...validationEvents, ...validationEventsSme };

  vuelidate.$touch();
  const isValid = await vuelidate.$validate();

  if (!routeName) {
    console.warn('Please provide a name for the route');
  }

  let analyticsObj = {
    validation: isValid,
    ...getDefaultValidationObject(eventName),
  };

  if (!isValid) {
    const errorsObject = vuelidate.$silentErrors.reduce(
      (errorObj: any, error: any) => Object.assign(errorObj, { [`validate-${error.$property}`]: error.$message }),
      {}
    );
    analyticsObj = {
      ...analyticsObj,
      ...errorsObject,
    };

    // try to scroll to first error and fail silently if not successful
    try {
      scrollToAndFocusElem(returnFirstErrElemFromForm(vuelidate));
      // eslint-disable-next-line no-empty
    } catch {}
  } else {
    onSuccess();
  }

  trackAnalytics(validationEventsObject[eventName], analyticsObj);
};
