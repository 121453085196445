import { type RouteRecordRaw } from 'vue-router';
import { indexRoutesNames, teamCardsRoutesNames } from './routeNames';

const AppDownload = () => import('@/components/pages/team-cards/AppDownload.vue');
const PasswordEntry = () => import('@/components/pages/team-cards/PasswordEntry.vue');
/**
 * The router configuration. It uses standard Vue syntax. If you want to guard a route, use `meta` object.
 * Possible options are:
 * - `meta: { requiresAuth: true }` for routes that require authentication
 * - `meta: { allowFromName: [] }` for routes that can be accessed from the given names
 * - `meta: { requireFeatureFlagKey: string as keyof typeof featureFlagsNames }` for routes that can be accessed only if certain feature flag is enabled
 */
const TeamCardsRoutes: Array<RouteRecordRaw> = [
  {
    path: 'password-entry',
    component: PasswordEntry,
    name: teamCardsRoutesNames.teamCardsPasswordEntry,
    meta: {
      allowFromName: [indexRoutesNames.home, teamCardsRoutesNames.teamCardsHome],
    },
  },
  {
    path: 'app-download',
    component: AppDownload,
    name: teamCardsRoutesNames.teamCardsAppDownload,
    meta: {
      allowFromName: [
        indexRoutesNames.home,
        indexRoutesNames.login,
        teamCardsRoutesNames.teamCardsHome,
        teamCardsRoutesNames.teamCardsPasswordEntry,
        teamCardsRoutesNames.teamCardsTokenExpired,
      ],
    },
  },
];

export default TeamCardsRoutes;
