import { MFS_TERMS_BUCKET } from '@/env';
import posthog from '@/plugins/posthog';
import { getStorageDownloadUrl, readDataFromFirestore } from '@/service/firebase';
import type { FirestoreRegistrations } from '@/types/Firestore';
import type { Company, OnboardingState, TermsDetails } from '@/types/OnboardingStore';
import type { TermsVersion } from '@/types/TermsVersion';
import type { RetrievedUser } from '@/utils/leadsSales';
import { TermsIdType, type EachAgreementConfig } from '@/utils/legalAgreementConfigV2';
import {
  accountRole,
  accountStatus,
  addressData,
  aionData,
  company,
  companyType,
  isAppView,
  isFunctionalCookieEnabled,
  isLead,
  isRegActivation,
  isUpdateRegData,
  nextRoute,
  optins,
  personalData,
  primaryRepresentative,
  submittedScreens,
  teamCardsJwt,
  termsDetails,
  utm,
} from '@/utils/onboardingStore';
import axios, { type AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

export const useOnboardingStore = defineStore('onboarding', {
  state: (): OnboardingState => ({
    personalData,
    addressData,
    submittedScreens,
    aionData,
    companyType,
    company,
    termsDetails,
    isUpdateRegData,
    isAppView,
    isFunctionalCookieEnabled,
    isLead,
    utm,
    nextRoute,
    teamCardsJwt,
    isRegActivation,
    accountStatus,
    accountRole,
    optins,
    primaryRepresentative,
  }),
  actions: {
    setLeadsUserData({ firstName, lastName, email, phone, metroAccountId }: RetrievedUser) {
      this.personalData.firstName = firstName;
      this.personalData.lastName = lastName;
      this.personalData.email = email;
      this.personalData.metroAccountId = metroAccountId;
      this.personalData.phone = phone;
      this.personalData.isMetroCustomer = true;
      this.isLead = true;
    },
    async getFirestoreData(user: { uid: string; email: string | null }): Promise<void> {
      let firestoreData: FirestoreRegistrations;

      try {
        firestoreData = await readDataFromFirestore(user.uid, this.accountRole);
      } catch (error) {
        throw new Error('Firebase get data failed with error: ' + error);
      }

      if (firestoreData) {
        // set posthog id to merge pre and post login sessions
        if (firestoreData.posthogId) posthog.identify(firestoreData.posthogId);

        if (firestoreData.nextRoute) this.nextRoute = firestoreData.nextRoute;

        if (!firestoreData.data) return;

        // set personal data with new values from firestore
        Object.keys(firestoreData.data)
          .filter((key) => key in this.personalData)
          // @ts-ignore
          .forEach((key) => (this.personalData[key] = firestoreData.data[key]));

        const personalData = {
          ...this.personalData,
          email: user.email || '',
        };

        if (firestoreData.data.date || firestoreData.data.dateOfBirth) {
          // get date for existing users and store as dateOfBirth
          const dateOfBirth = firestoreData.data.dateOfBirth ? firestoreData.data.dateOfBirth : firestoreData.data.date;
          personalData.dateOfBirth = new Date(dateOfBirth).toISOString();
        }

        if (firestoreData.data.idOrigin) {
          personalData.idOrigin = firestoreData.data.idOrigin;
        }

        if (firestoreData.data.isLead) {
          this.isLead = true;
        }

        this.company.isAddressSameAsPersonal = (firestoreData.data.company as Company)?.isAddressSameAsPersonal ?? true;

        if (!this.company.isAddressSameAsPersonal && firestoreData.data.company?.address) {
          this.company.address = firestoreData.data.company.address;
        }

        if ((firestoreData.data.company as Company)?.startDate) {
          this.company.startDate = (firestoreData.data.company as Company).startDate;
        }

        this.companyType = firestoreData.data.companyType;
        this.personalData = personalData;

        this.aionData.referralId = firestoreData.data.aionData?.referralId || this.aionData.referralId;
        this.utm = firestoreData.data.utm;
        if (firestoreData.data.address) {
          this.addressData = { personalAddress: firestoreData.data.address };
        }
        this.optins = firestoreData.data.optins;

        // set primary representative data with new values from firestore
        Object.keys(firestoreData.data)
          .filter((key) => key in this.primaryRepresentative)
          // @ts-ignore
          .forEach((key) => (this.primaryRepresentative[key] = firestoreData.data[key]));
      }
    },
    async fetchTermsDetails(agreements: EachAgreementConfig[]) {
      let listOfPdfRequiredTermsId: TermsIdType[] = [];

      agreements.forEach((el) => {
        listOfPdfRequiredTermsId = [...listOfPdfRequiredTermsId, ...el.showTermsPdf];
      });

      const locTermsDetails: Partial<TermsDetails> = {};

      try {
        listOfPdfRequiredTermsId.forEach(async (termsId) => {
          const downloadUrl = await getStorageDownloadUrl(MFS_TERMS_BUCKET, `${termsId}/de_DE/index.json`);

          const { data }: AxiosResponse<TermsVersion> = await axios.get(downloadUrl, {
            responseType: 'json',
          });

          locTermsDetails[termsId] = data;
        });

        this.termsDetails = locTermsDetails;
      } catch (err) {
        console.error('Terms loading failed with error: ', err);
      }
    },
  },
});
