<template>
  <svg
    width="25px"
    height="24px"
    viewBox="0 0 25 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="check-small-filled"
        d="M12.00015,-0.00015 C18.62715,-0.00015 24.00015,5.37285 24.00015,11.99985 C24.00015,18.62835 18.62715,23.99985 12.00015,23.99985 C5.37165,23.99985 0.00015,18.62835 0.00015,11.99985 C0.00015,5.37285 5.37165,-0.00015 12.00015,-0.00015 Z M19.06665,7.19535 C18.48315,6.60585 17.53365,6.60135 16.94565,7.18335 L16.94565,7.18335 L10.42065,13.63935 L7.05465,10.31085 C6.46665,9.72735 5.51715,9.73335 4.93365,10.32135 C4.35165,10.91085 4.35615,11.86035 4.94565,12.44235 L4.94565,12.44235 L9.36615,16.81635 C9.65715,17.10585 10.03815,17.24985 10.42065,17.24985 C10.80165,17.24985 11.18265,17.10585 11.47515,16.81635 L11.47515,16.81635 L19.05465,9.31635 C19.64415,8.73285 19.64865,7.78485 19.06665,7.19535 Z"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-549.000000, -1218.000000)">
        <g transform="translate(549.500000, 1218.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <mask fill="white">
            <use xlink:href="#check-small-filled"></use>
          </mask>
          <use fill="currentColor" xlink:href="#check-small-filled"></use>
        </g>
      </g>
    </g>
  </svg>
</template>
