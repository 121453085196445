import posthog from '@/plugins/posthog';
import { EventId } from '@/utils/analytics/eventIds';
import { getEventName } from '@/utils/analytics/getEventName';
import { PersonalInfoList } from '@/utils/analytics/personalInfoList';

const urlInfo = () => ({
  $current_url: window.location.href,
  $pathname: window.location.pathname,
  path: window.location.pathname,
  url: window.location.href,
});

export function trackAnalytics(eventName: string, eventValue: object, options?: object) {
  const payload = Object.assign(eventValue, urlInfo());

  posthog.capture(eventName, payload, options);
}

export function trackError(eventName: string, error: any, isFirebaseError = false) {
  const apiErrorValue = {
    error: error?.response?.data?.code,
    'error-status': error?.response?.status,
  };

  const firebaseErrorValue = {
    error: error?.code,
    'error-status': null,
  };

  const eventValue = isFirebaseError ? firebaseErrorValue : apiErrorValue;

  const payload = Object.assign(eventValue, urlInfo());

  posthog.capture(eventName, payload);
}

export function trackInputBlurEvent(e: MouseEvent | FocusEvent, value?: string) {
  if (value) return;

  const target = e.target as HTMLInputElement;
  const { id } = target;
  const eventName = getEventName(id as EventId);

  if (eventName && !!id && !id.startsWith('select') && !Object.values<string>(PersonalInfoList).includes(id)) {
    trackAnalytics(eventName, {
      [id]: value,
    });
  }
}
