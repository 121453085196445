<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#ffda44" d="M0 378.4l252.9-28.8L512 378.4V512H322.8L256 481l-66.8 31H0z" />
      <path fill="#6da544" d="M0 256l249.8-28L512 256v122.4H0z" />
      <path fill="#eee" d="M0 133.6l255.3-28.3L512 133.6V256H0z" />
      <path fill="#0052b4" d="M0 0h189.2L256 30l66.8-30H512v133.6H0z" />
      <path fill="#ffda44" d="M137.7 55.7l6.9 21.2H167L148.9 90l6.9 21.3-18.1-13.1-18 13.1 6.8-21.3-18-13h22.3z" />
      <path fill="#d80027" d="M189.2 0h133.6v512H189.2z" />
    </g>
  </svg>
</template>
