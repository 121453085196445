interface Country {
  name: string;
  dialCode: string;
  idType: string[];
  idOrigin?: string;
}

// supported idTypes for different idOrigin
const idType = {
  default: ['IDCARD', 'PASSPORT', 'RESIDENCY_PERMIT'],
  passport: ['PASSPORT'],
  passportOrIdcard: ['PASSPORT', 'IDCARD'],
  passportAndResidencyPermit: ['PASSPORT_AND_RESIDENCY_PERMIT'],
  passportOrIdcardAndCityReg: ['PASSPORT_AND_CITY_REGISTRATION', 'IDCARD_AND_CITY_REGISTRATION'],
};

/*
 * List of all countries
 * Source: https://github.com/yogakurniawan/vue-tel-input-vuetify/blob/master/lib/all-countries.js
 */
export const countries: Record<string, Country> = {
  AF: { name: 'Afghanistan', dialCode: '93', idType: idType.passportAndResidencyPermit },
  EG: { name: 'Ägypten', dialCode: '20', idType: idType.passportAndResidencyPermit },
  AX: { name: 'Ålandinseln', dialCode: '358', idType: idType.passportAndResidencyPermit },
  AL: { name: 'Albanien', dialCode: '355', idType: idType.passportAndResidencyPermit },
  DZ: { name: 'Algerien', dialCode: '213', idType: idType.passportAndResidencyPermit },
  AS: { name: 'Amerikanisch-Samoa', dialCode: '1684', idType: idType.passportAndResidencyPermit },
  VI: { name: 'Amerikanische Jungferninseln', dialCode: '1340', idType: idType.passportAndResidencyPermit },
  AD: { name: 'Andorra', dialCode: '376', idType: idType.passportAndResidencyPermit },
  AO: { name: 'Angola', dialCode: '244', idType: idType.passportAndResidencyPermit },
  AI: { name: 'Anguilla', dialCode: '1264', idType: idType.passportAndResidencyPermit },
  AG: { name: 'Antigua und Barbuda', dialCode: '1268', idType: idType.passportAndResidencyPermit },
  GQ: { name: 'Äquatorialguinea', dialCode: '240', idType: idType.passportAndResidencyPermit },
  AR: { name: 'Argentinien', dialCode: '54', idType: idType.passportAndResidencyPermit },
  AM: { name: 'Armenien', dialCode: '374', idType: idType.passportAndResidencyPermit },
  AW: { name: 'Aruba', dialCode: '297', idType: idType.passportAndResidencyPermit },
  AZ: { name: 'Aserbaidschan', dialCode: '994', idType: idType.passportAndResidencyPermit },
  ET: { name: 'Äthiopien', dialCode: '251', idType: idType.passportAndResidencyPermit },
  AU: { name: 'Australien', dialCode: '61', idType: idType.passportAndResidencyPermit },
  BS: { name: 'Bahamas', dialCode: '1242', idType: idType.passportAndResidencyPermit },
  BH: { name: 'Bahrain', dialCode: '973', idType: idType.passportAndResidencyPermit },
  BD: { name: 'Bangladesch', dialCode: '880', idType: idType.passportAndResidencyPermit },
  BB: { name: 'Barbados', dialCode: '1246', idType: idType.passportAndResidencyPermit },
  BY: { name: 'Belarus', dialCode: '375', idType: idType.passportAndResidencyPermit },
  BE: { name: 'Belgien', dialCode: '32', idType: idType.passportOrIdcard },
  BZ: { name: 'Belize', dialCode: '501', idType: idType.passportAndResidencyPermit },
  BJ: { name: 'Benin', dialCode: '229', idType: idType.passportAndResidencyPermit },
  BM: { name: 'Bermuda', dialCode: '1441', idType: idType.passportAndResidencyPermit },
  BT: { name: 'Bhutan', dialCode: '975', idType: idType.passportAndResidencyPermit },
  BO: { name: 'Bolivien', dialCode: '591', idType: idType.passportAndResidencyPermit },
  BQ: { name: 'Bonaire, Sint Eustatius und Saba', dialCode: '599', idType: idType.passportAndResidencyPermit },
  BA: { name: 'Bosnien und Herzegowina', dialCode: '387', idType: idType.passportAndResidencyPermit },
  BW: { name: 'Botsuana', dialCode: '267', idType: idType.passportAndResidencyPermit },
  BR: { name: 'Brasilien', dialCode: '55', idType: idType.passportAndResidencyPermit },
  VG: { name: 'Britische Jungferninseln', dialCode: '1284', idType: idType.passportAndResidencyPermit },
  IO: { name: 'Britisches Territorium im Indischen Ozean', dialCode: '246', idType: idType.passportAndResidencyPermit },
  BN: { name: 'Brunei Darussalam', dialCode: '673', idType: idType.passportAndResidencyPermit },
  BG: { name: 'Bulgarien', dialCode: '359', idType: idType.passportOrIdcardAndCityReg },
  BF: { name: 'Burkina Faso', dialCode: '226', idType: idType.passportAndResidencyPermit },
  BI: { name: 'Burundi', dialCode: '257', idType: idType.passportAndResidencyPermit },
  CV: { name: 'Cabo Verde', dialCode: '238', idType: idType.passportAndResidencyPermit },
  CL: { name: 'Chile', dialCode: '56', idType: idType.passportAndResidencyPermit },
  CN: { name: 'China', dialCode: '86', idType: idType.passportAndResidencyPermit },
  CK: { name: 'Cookinseln', dialCode: '682', idType: idType.passportAndResidencyPermit },
  CR: { name: 'Costa Rica', dialCode: '506', idType: idType.passportAndResidencyPermit },
  CW: { name: 'Curaçao', dialCode: '599', idType: idType.passportAndResidencyPermit },
  CI: { name: 'Côte d’Ivoire', dialCode: '225', idType: idType.passportAndResidencyPermit },
  DE: { name: 'Deutschland', dialCode: '49', idType: idType.passportOrIdcard },
  DM: { name: 'Dominica', dialCode: '1767', idType: idType.passportAndResidencyPermit },
  DO: { name: 'Dominikanische Republik', dialCode: '1', idType: idType.passportAndResidencyPermit },
  DJ: { name: 'Dschibuti', dialCode: '253', idType: idType.passportAndResidencyPermit },
  DK: { name: 'Dänemark', dialCode: '45', idType: idType.passport },
  EC: { name: 'Ecuador', dialCode: '593', idType: idType.passportAndResidencyPermit },
  SV: { name: 'El Salvador', dialCode: '503', idType: idType.passportAndResidencyPermit },
  ER: { name: 'Eritrea', dialCode: '291', idType: idType.passportAndResidencyPermit },
  EE: { name: 'Estland', dialCode: '372', idType: idType.passportOrIdcard },
  SZ: { name: 'Eswatini', dialCode: '268', idType: idType.passportAndResidencyPermit },
  FK: { name: 'Falklandinseln', dialCode: '500', idType: idType.passportAndResidencyPermit },
  FJ: { name: 'Fidschi', dialCode: '679', idType: idType.passportAndResidencyPermit },
  FI: { name: 'Finnland', dialCode: '358', idType: idType.passportOrIdcard },
  FR: { name: 'Frankreich', dialCode: '33', idType: idType.passportOrIdcard },
  GF: { name: 'Französisch-Guayana', dialCode: '594', idType: idType.passportAndResidencyPermit },
  PF: { name: 'Französisch-Polynesien', dialCode: '689', idType: idType.passportAndResidencyPermit },
  FO: { name: 'Färöer', dialCode: '298', idType: idType.passportAndResidencyPermit },
  GA: { name: 'Gabun', dialCode: '241', idType: idType.passportAndResidencyPermit },
  GM: { name: 'Gambia', dialCode: '220', idType: idType.passportAndResidencyPermit },
  GE: { name: 'Georgien', dialCode: '995', idType: idType.passportAndResidencyPermit },
  GH: { name: 'Ghana', dialCode: '233', idType: idType.passportAndResidencyPermit },
  GI: { name: 'Gibraltar', dialCode: '350', idType: idType.passportAndResidencyPermit },
  GD: { name: 'Grenada', dialCode: '1473', idType: idType.passportAndResidencyPermit },
  GR: { name: 'Griechenland', dialCode: '30', idType: idType.passportOrIdcardAndCityReg },
  GL: { name: 'Grönland', dialCode: '299', idType: idType.passportAndResidencyPermit },
  GP: { name: 'Guadeloupe', dialCode: '590', idType: idType.passportAndResidencyPermit },
  GU: { name: 'Guam', dialCode: '1671', idType: idType.passportAndResidencyPermit },
  GT: { name: 'Guatemala', dialCode: '502', idType: idType.passportAndResidencyPermit },
  GG: { name: 'Guernsey', dialCode: '44', idType: idType.passportAndResidencyPermit },
  GN: { name: 'Guinea', dialCode: '224', idType: idType.passportAndResidencyPermit },
  GW: { name: 'Guinea-Bissau', dialCode: '245', idType: idType.passportAndResidencyPermit },
  GY: { name: 'Guyana', dialCode: '592', idType: idType.passportAndResidencyPermit },
  HT: { name: 'Haiti', dialCode: '509', idType: idType.passportAndResidencyPermit },
  HN: { name: 'Honduras', dialCode: '504', idType: idType.passportAndResidencyPermit },
  IN: { name: 'Indien', dialCode: '91', idType: idType.passportAndResidencyPermit },
  ID: { name: 'Indonesien', dialCode: '62', idType: idType.passportAndResidencyPermit },
  IQ: { name: 'Irak', dialCode: '964', idType: idType.default, idOrigin: 'blocked' },
  IR: { name: 'Iran', dialCode: '98', idType: idType.default, idOrigin: 'blocked' },
  IE: { name: 'Irland', dialCode: '353', idType: idType.passportAndResidencyPermit },
  IS: { name: 'Island', dialCode: '354', idType: idType.passportAndResidencyPermit },
  IM: { name: 'Isle of Man', dialCode: '44', idType: idType.passportAndResidencyPermit },
  IL: { name: 'Israel', dialCode: '972', idType: idType.passportAndResidencyPermit },
  IT: { name: 'Italien', dialCode: '39', idType: idType.passportOrIdcard },
  JM: { name: 'Jamaika', dialCode: '1876', idType: idType.passportAndResidencyPermit },
  JP: { name: 'Japan', dialCode: '81', idType: idType.passportAndResidencyPermit },
  YE: { name: 'Jemen', dialCode: '967', idType: idType.passportAndResidencyPermit },
  JE: { name: 'Jersey', dialCode: '44', idType: idType.passportAndResidencyPermit },
  JO: { name: 'Jordanien', dialCode: '962', idType: idType.passportAndResidencyPermit },
  KY: { name: 'Kaimaninseln', dialCode: '1345', idType: idType.passportAndResidencyPermit },
  KH: { name: 'Kambodscha', dialCode: '855', idType: idType.passportAndResidencyPermit },
  CM: { name: 'Kamerun', dialCode: '237', idType: idType.passportAndResidencyPermit },
  CA: { name: 'Kanada', dialCode: '1', idType: idType.passportAndResidencyPermit },
  KZ: { name: 'Kasachstan', dialCode: '7', idType: idType.passportAndResidencyPermit },
  QA: { name: 'Katar', dialCode: '974', idType: idType.passportAndResidencyPermit },
  KE: { name: 'Kenia', dialCode: '254', idType: idType.passportAndResidencyPermit },
  KG: { name: 'Kirgisistan', dialCode: '996', idType: idType.passportAndResidencyPermit },
  KI: { name: 'Kiribati', dialCode: '686', idType: idType.passportAndResidencyPermit },
  CC: { name: 'Kokosinseln', dialCode: '61', idType: idType.passportAndResidencyPermit },
  CO: { name: 'Kolumbien', dialCode: '57', idType: idType.passportAndResidencyPermit },
  KM: { name: 'Komoren', dialCode: '269', idType: idType.passportAndResidencyPermit },
  CG: { name: 'Kongo-Brazzaville', dialCode: '242', idType: idType.passportAndResidencyPermit },
  CD: { name: 'Kongo-Kinshasa', dialCode: '243', idType: idType.passportAndResidencyPermit },
  XK: { name: 'Kosovo', dialCode: '383', idType: idType.passportAndResidencyPermit },
  HR: { name: 'Kroatien', dialCode: '385', idType: idType.passportOrIdcardAndCityReg },
  CU: { name: 'Kuba', dialCode: '53', idType: idType.passportAndResidencyPermit },
  KW: { name: 'Kuwait', dialCode: '965', idType: idType.passportAndResidencyPermit },
  LA: { name: 'Laos', dialCode: '856', idType: idType.passportAndResidencyPermit },
  LS: { name: 'Lesotho', dialCode: '266', idType: idType.passportAndResidencyPermit },
  LV: { name: 'Lettland', dialCode: '371', idType: idType.passportOrIdcardAndCityReg },
  LB: { name: 'Libanon', dialCode: '961', idType: idType.passportAndResidencyPermit },
  LR: { name: 'Liberia', dialCode: '231', idType: idType.passportAndResidencyPermit },
  LY: { name: 'Libyen', dialCode: '218', idType: idType.passportAndResidencyPermit },
  LI: { name: 'Liechtenstein', dialCode: '423', idType: idType.passportAndResidencyPermit },
  LT: { name: 'Litauen', dialCode: '370', idType: idType.passportOrIdcardAndCityReg },
  LU: { name: 'Luxemburg', dialCode: '352', idType: idType.passportOrIdcardAndCityReg },
  MG: { name: 'Madagaskar', dialCode: '261', idType: idType.passportAndResidencyPermit },
  MW: { name: 'Malawi', dialCode: '265', idType: idType.passportAndResidencyPermit },
  MY: { name: 'Malaysia', dialCode: '60', idType: idType.passportAndResidencyPermit },
  MV: { name: 'Malediven', dialCode: '960', idType: idType.passportAndResidencyPermit },
  ML: { name: 'Mali', dialCode: '223', idType: idType.passportAndResidencyPermit },
  MT: { name: 'Malta', dialCode: '356', idType: idType.passportOrIdcardAndCityReg },
  MA: { name: 'Marokko', dialCode: '212', idType: idType.passportAndResidencyPermit },
  MH: { name: 'Marshallinseln', dialCode: '692', idType: idType.passportAndResidencyPermit },
  MQ: { name: 'Martinique', dialCode: '596', idType: idType.passportAndResidencyPermit },
  MR: { name: 'Mauretanien', dialCode: '222', idType: idType.passportAndResidencyPermit },
  MU: { name: 'Mauritius', dialCode: '230', idType: idType.passportAndResidencyPermit },
  YT: { name: 'Mayotte', dialCode: '262', idType: idType.passportAndResidencyPermit },
  MX: { name: 'Mexiko', dialCode: '52', idType: idType.passportAndResidencyPermit },
  FM: { name: 'Mikronesien', dialCode: '691', idType: idType.passportAndResidencyPermit },
  MC: { name: 'Monaco', dialCode: '377', idType: idType.passportAndResidencyPermit },
  MN: { name: 'Mongolei', dialCode: '976', idType: idType.passportAndResidencyPermit },
  ME: { name: 'Montenegro', dialCode: '382', idType: idType.passportAndResidencyPermit },
  MS: { name: 'Montserrat', dialCode: '1664', idType: idType.passportAndResidencyPermit },
  MZ: { name: 'Mosambik', dialCode: '258', idType: idType.passportAndResidencyPermit },
  MM: { name: 'Myanmar', dialCode: '95', idType: idType.passportAndResidencyPermit },
  NA: { name: 'Namibia', dialCode: '264', idType: idType.passportAndResidencyPermit },
  NR: { name: 'Nauru', dialCode: '674', idType: idType.passportAndResidencyPermit },
  NP: { name: 'Nepal', dialCode: '977', idType: idType.passportAndResidencyPermit },
  NC: { name: 'Neukaledonien', dialCode: '687', idType: idType.passportAndResidencyPermit },
  NZ: { name: 'Neuseeland', dialCode: '64', idType: idType.passportAndResidencyPermit },
  NI: { name: 'Nicaragua', dialCode: '505', idType: idType.passportAndResidencyPermit },
  NL: { name: 'Niederlande', dialCode: '31', idType: idType.passportOrIdcard },
  NE: { name: 'Niger', dialCode: '227', idType: idType.passportAndResidencyPermit },
  NG: { name: 'Nigeria', dialCode: '234', idType: idType.passportAndResidencyPermit },
  NU: { name: 'Niue', dialCode: '683', idType: idType.passportAndResidencyPermit },
  KP: { name: 'Nordkorea', dialCode: '850', idType: idType.default, idOrigin: 'blocked' },
  MK: { name: 'Nordmazedonien', dialCode: '389', idType: idType.passportAndResidencyPermit },
  NF: { name: 'Norfolkinsel', dialCode: '672', idType: idType.passportAndResidencyPermit },
  NO: { name: 'Norwegen', dialCode: '47', idType: idType.passportOrIdcard },
  MP: { name: 'Nördliche Marianen', dialCode: '1670', idType: idType.passportAndResidencyPermit },
  OM: { name: 'Oman', dialCode: '968', idType: idType.passportAndResidencyPermit },
  AT: { name: 'Österreich', dialCode: '43', idType: idType.passportOrIdcard },
  PK: { name: 'Pakistan', dialCode: '92', idType: idType.passportAndResidencyPermit },
  PW: { name: 'Palau', dialCode: '680', idType: idType.passportAndResidencyPermit },
  PS: { name: 'Palästinensische Autonomiegebiete', dialCode: '970', idType: idType.passportAndResidencyPermit },
  PA: { name: 'Panama', dialCode: '507', idType: idType.passportAndResidencyPermit },
  PG: { name: 'Papua-Neuguinea', dialCode: '675', idType: idType.passportAndResidencyPermit },
  PY: { name: 'Paraguay', dialCode: '595', idType: idType.passportAndResidencyPermit },
  PE: { name: 'Peru', dialCode: '51', idType: idType.passportAndResidencyPermit },
  PH: { name: 'Philippinen', dialCode: '63', idType: idType.passportAndResidencyPermit },
  PL: { name: 'Polen', dialCode: '48', idType: idType.passportOrIdcard },
  PT: { name: 'Portugal', dialCode: '351', idType: idType.passportOrIdcardAndCityReg },
  PR: { name: 'Puerto Rico', dialCode: '1', idType: idType.passportAndResidencyPermit },
  MD: { name: 'Republik Moldau', dialCode: '373', idType: idType.passportAndResidencyPermit },
  RW: { name: 'Ruanda', dialCode: '250', idType: idType.passportAndResidencyPermit },
  RO: { name: 'Rumänien', dialCode: '40', idType: idType.passport },
  RU: { name: 'Russland', dialCode: '7', idType: idType.passportAndResidencyPermit },
  RE: { name: 'Réunion', dialCode: '262', idType: idType.passportAndResidencyPermit },
  SB: { name: 'Salomonen', dialCode: '677', idType: idType.passportAndResidencyPermit },
  ZM: { name: 'Sambia', dialCode: '260', idType: idType.passportAndResidencyPermit },
  WS: { name: 'Samoa', dialCode: '685', idType: idType.passportAndResidencyPermit },
  SM: { name: 'San Marino', dialCode: '378', idType: idType.passportAndResidencyPermit },
  SA: { name: 'Saudi-Arabien', dialCode: '966', idType: idType.passportAndResidencyPermit },
  SE: { name: 'Schweden', dialCode: '46', idType: idType.passportOrIdcard },
  CH: { name: 'Schweiz', dialCode: '41', idType: idType.passportAndResidencyPermit },
  SN: { name: 'Senegal', dialCode: '221', idType: idType.passportAndResidencyPermit },
  RS: { name: 'Serbien', dialCode: '381', idType: idType.passportAndResidencyPermit },
  SC: { name: 'Seychellen', dialCode: '248', idType: idType.passportAndResidencyPermit },
  SL: { name: 'Sierra Leone', dialCode: '232', idType: idType.passportAndResidencyPermit },
  ZW: { name: 'Simbabwe', dialCode: '263', idType: idType.passportAndResidencyPermit },
  SG: { name: 'Singapur', dialCode: '65', idType: idType.passportAndResidencyPermit },
  SX: { name: 'Sint Maarten', dialCode: '1721', idType: idType.passportAndResidencyPermit },
  SK: { name: 'Slowakei', dialCode: '421', idType: idType.passportOrIdcard },
  SI: { name: 'Slowenien', dialCode: '386', idType: idType.passportOrIdcardAndCityReg },
  SO: { name: 'Somalia', dialCode: '252', idType: idType.passportAndResidencyPermit },
  HK: { name: 'Sonderverwaltungsregion Hongkong', dialCode: '852', idType: idType.passportAndResidencyPermit },
  MO: { name: 'Sonderverwaltungsregion Macau', dialCode: '853', idType: idType.passportAndResidencyPermit },
  ES: { name: 'Spanien', dialCode: '34', idType: idType.passportOrIdcard },
  LK: { name: 'Sri Lanka', dialCode: '94', idType: idType.passportAndResidencyPermit },
  BL: { name: 'St. Barthélemy', dialCode: '590', idType: idType.passportAndResidencyPermit },
  SH: { name: 'St. Helena', dialCode: '290', idType: idType.passportAndResidencyPermit },
  KN: { name: 'St. Kitts und Nevis', dialCode: '1869', idType: idType.passportAndResidencyPermit },
  LC: { name: 'St. Lucia', dialCode: '1758', idType: idType.passportAndResidencyPermit },
  MF: { name: 'St. Martin', dialCode: '590', idType: idType.passportAndResidencyPermit },
  PM: { name: 'St. Pierre und Miquelon', dialCode: '508', idType: idType.passportAndResidencyPermit },
  VC: { name: 'St. Vincent und die Grenadinen', dialCode: '1784', idType: idType.passportAndResidencyPermit },
  SD: { name: 'Sudan', dialCode: '249', idType: idType.default, idOrigin: 'blocked' },
  SR: { name: 'Suriname', dialCode: '597', idType: idType.passportAndResidencyPermit },
  SY: { name: 'Syrien', dialCode: '963', idType: idType.default, idOrigin: 'blocked' },
  ST: { name: 'São Tomé und Príncipe', dialCode: '239', idType: idType.passportAndResidencyPermit },
  ZA: { name: 'Südafrika', dialCode: '27', idType: idType.passportAndResidencyPermit },
  KR: { name: 'Südkorea', dialCode: '82', idType: idType.passportAndResidencyPermit },
  SS: { name: 'Südsudan', dialCode: '211', idType: idType.passportAndResidencyPermit },
  TJ: { name: 'Tadschikistan', dialCode: '992', idType: idType.passportAndResidencyPermit },
  TW: { name: 'Taiwan', dialCode: '886', idType: idType.passportAndResidencyPermit },
  TZ: { name: 'Tansania', dialCode: '255', idType: idType.passportAndResidencyPermit },
  TH: { name: 'Thailand', dialCode: '66', idType: idType.passportAndResidencyPermit },
  TL: { name: 'Timor-Leste', dialCode: '670', idType: idType.passportAndResidencyPermit },
  TG: { name: 'Togo', dialCode: '228', idType: idType.passportAndResidencyPermit },
  TK: { name: 'Tokelau', dialCode: '690', idType: idType.passportAndResidencyPermit },
  TO: { name: 'Tonga', dialCode: '676', idType: idType.passportAndResidencyPermit },
  TT: { name: 'Trinidad und Tobago', dialCode: '1868', idType: idType.passportAndResidencyPermit },
  TD: { name: 'Tschad', dialCode: '235', idType: idType.passportAndResidencyPermit },
  CZ: { name: 'Tschechische Republik', dialCode: '420', idType: idType.passportOrIdcard },
  TN: { name: 'Tunesien', dialCode: '216', idType: idType.passportAndResidencyPermit },
  TM: { name: 'Turkmenistan', dialCode: '993', idType: idType.passportAndResidencyPermit },
  TC: { name: 'Turks- und Caicosinseln', dialCode: '1649', idType: idType.passportAndResidencyPermit },
  TV: { name: 'Tuvalu', dialCode: '688', idType: idType.passportAndResidencyPermit },
  TR: { name: 'Türkei', dialCode: '90', idType: idType.passportAndResidencyPermit },
  UG: { name: 'Uganda', dialCode: '256', idType: idType.passportAndResidencyPermit },
  UA: { name: 'Ukraine', dialCode: '380', idType: idType.passportOrIdcard },
  HU: { name: 'Ungarn', dialCode: '36', idType: idType.passportOrIdcardAndCityReg },
  UY: { name: 'Uruguay', dialCode: '598', idType: idType.passportAndResidencyPermit },
  UZ: { name: 'Usbekistan', dialCode: '998', idType: idType.passportAndResidencyPermit },
  VU: { name: 'Vanuatu', dialCode: '678', idType: idType.passportAndResidencyPermit },
  VA: { name: 'Vatikanstadt', dialCode: '39', idType: idType.passportAndResidencyPermit },
  VE: { name: 'Venezuela', dialCode: '58', idType: idType.passportAndResidencyPermit },
  AE: { name: 'Vereinigte Arabische Emirate', dialCode: '971', idType: idType.passportAndResidencyPermit },
  US: { name: 'Vereinigte Staaten', dialCode: '1', idType: idType.default, idOrigin: 'blocked' },
  GB: { name: 'Vereinigtes Königreich', dialCode: '44', idType: idType.passportOrIdcardAndCityReg },
  VN: { name: 'Vietnam', dialCode: '84', idType: idType.passportAndResidencyPermit },
  WF: { name: 'Wallis und Futuna', dialCode: '681', idType: idType.passportAndResidencyPermit },
  CX: { name: 'Weihnachtsinsel', dialCode: '61', idType: idType.passportAndResidencyPermit },
  EH: { name: 'Westsahara', dialCode: '212', idType: idType.passportAndResidencyPermit },
  CF: { name: 'Zentralafrikanische Republik', dialCode: '236', idType: idType.passportAndResidencyPermit },
  CY: { name: 'Zypern', dialCode: '357', idType: idType.passportOrIdcardAndCityReg },
};

export const dialCodeToIso2Map: { [dialCode: string]: string } = {};

export const countryItemsWithDialCode = Object.entries(countries).map(([iso2, country]) => {
  dialCodeToIso2Map[country.dialCode] = iso2;

  return {
    text: `${country.name} +${country.dialCode}`,
    value: country.dialCode,
    iso2: iso2,
  };
});

export const countryItems = Object.entries(countries).map(([iso2, country]) => {
  return {
    text: `${country.name}`,
    value: iso2,
  };
});

export const isBlockerListIdOrigin = (iso2: string): boolean => {
  return countries[iso2].idOrigin === 'blocked';
};
