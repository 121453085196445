<template>
  <svg
    width="8px"
    height="14px"
    viewBox="0 0 8 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="chevron-right"
        d="M9.22531721,5.46440625 C9.52081721,5.17415625 9.99631721,5.17865625 10.2858172,5.47490625 L16.2858172,11.5979063 C16.5715672,11.8896563 16.5715672,12.3569063 16.2858172,12.6479063 L10.5265672,18.5249063 C10.2363172,18.8211563 9.76156721,18.8256563 9.46531721,18.5354063 C9.16981721,18.2451563 9.16606721,17.7711563 9.45481721,17.4749063 L14.7003172,12.1229063 L9.21481721,6.52490625 C9.07156721,6.37865625 9.00031721,6.18965625 9.00031721,5.99990625 C9.00031721,5.80490625 9.07531721,5.61065625 9.22531721,5.46440625 Z"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1148.000000, -1167.000000)">
        <g transform="translate(1139.500000, 1162.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <mask fill="white">
            <use xlink:href="#chevron-right"></use>
          </mask>
          <use fill="currentColor" xlink:href="#chevron-right"></use>
        </g>
      </g>
    </g>
  </svg>
</template>
