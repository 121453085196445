import { trackAnalytics } from '@/utils/analytics/sendAnalyticsUtil';
import type { DirectiveBinding } from 'vue';

const clickFn = function ({ eventName, eventValue }: { eventName: string; eventValue: object }) {
  let eventObject = {};

  if (eventValue) {
    eventObject = {
      ...eventValue,
    };
  }

  trackAnalytics(eventName, eventObject);
};

let clickFnRef: EventListener | null = null;

const trackClickDirective = {
  beforeMount: (el: HTMLElement, binding: DirectiveBinding) => {
    const { eventName, eventValue } = binding.value;
    clickFnRef = clickFn.bind(null, { eventName, eventValue });

    el.addEventListener('click', clickFnRef);
  },
  unmounted: (el: HTMLElement) => {
    el.removeEventListener('click', clickFnRef!);
  },
};

export { trackClickDirective };
