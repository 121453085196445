<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#496e2d" d="M445.2 256zm-155.8 0z" />
      <path fill="#0052b4" d="M256 0h256v512H0V256z" />
      <path
        fill="#eee"
        d="M345 256l4.8 14.6H365l-12.4 9 4.7 14.6-12.4-9-12.4 9 4.8-14.6-12.4-9h15.3zm-63 26l13.7 7 10.9-10.8-2.4 15.1 13.6 7-15.1 2.4-2.4 15.1-7-13.6-15.1 2.4 10.8-10.9zm-26 63l14.6-4.7V325l9 12.4 14.6-4.8-9 12.4 9 12.4-14.6-4.7-9 12.4v-15.3zm26 63l7-13.6-10.8-10.9 15.1 2.4 7-13.6 2.4 15 15.1 2.5-13.6 7 2.4 15-10.9-10.8zm63 26l-4.7-14.5H325l12.4-9-4.8-14.6 12.4 9 12.4-9-4.7 14.6 12.4 9h-15.3zm63-26l-13.6-7-10.9 10.9 2.4-15.2-13.6-7 15-2.3 2.5-15.1 7 13.6 15-2.4-10.8 10.9zm26-63l-14.5 4.8V365l-9-12.4-14.6 4.7 9-12.4-9-12.4 14.6 4.8 9-12.4v15.3zm-26-63l-7 13.7 10.9 10.9-15.2-2.4-7 13.6-2.3-15.1-15.1-2.4 13.6-7-2.4-15.1 10.9 10.8z"
      />
      <path fill="#496e2d" d="M445.2 256zm-155.8 0z" />
      <path
        fill="#eee"
        d="M256 0H100.2L66.7 14.9 33.4 0H0v33.4l15.7 31.3L0 100.2V256h33.4l34-13.6 32.8 13.6h33.4v-75.2l75.2 75.2H256l-7.7-21.3 7.7-10.2v-15.7l-75.2-75.2H256v-33.4l-11.8-33.4L256 33.4z"
      />
      <path fill="#d80027" d="M256 224.5l-91-91h-31.4L256 256z" />
      <path fill="#d80027" d="M33.4 0v33.4H0v66.8h33.4V256h66.8V100.2H256V33.4H100.2V0z" />
    </g>
  </svg>
</template>
