<template>
  <svg
    width="19px"
    height="16px"
    viewBox="0 0 19 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="arrow-left"
        d="M20.25,11.2509375 L5.5605,11.2509375 L11.03175,5.7804375 C11.325,5.4871875 11.325,5.0124375 11.03175,4.7199375 C10.7385,4.4266875 10.2645,4.4266875 9.97125,4.7199375 L3.2205,11.4699375 C3.1515,11.5389375 3.09675,11.6214375 3.0585,11.7121875 C3.02025,11.8029375 3,11.9011875 3,12.0009375 C3,12.1006875 3.02025,12.1989375 3.0585,12.2896875 C3.09675,12.3804375 3.1515,12.4629375 3.2205,12.5319375 L9.97125,19.2819375 C10.1175,19.4289375 10.3095,19.5016875 10.5015,19.5016875 C10.6935,19.5016875 10.8855,19.4289375 11.03175,19.2819375 C11.325,18.9894375 11.325,18.5146875 11.03175,18.2214375 L5.5605,12.7509375 L20.25,12.7509375 C20.66475,12.7509375 21,12.4149375 21,12.0009375 C21,11.5869375 20.66475,11.2509375 20.25,11.2509375"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-950.000000, -1166.000000)">
        <g transform="translate(947.500000, 1162.000000)">
          <polygon points="0 0 24 0 24 24 0 24"></polygon>
          <mask fill="white">
            <use xlink:href="#arrow-left"></use>
          </mask>
          <use fill="currentColor" xlink:href="#arrow-left"></use>
        </g>
      </g>
    </g>
  </svg>
</template>
