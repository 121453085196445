<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#ffda44" d="M0 167l252.9-29.3L512 167v178l-255.7 25.7L0 345z" />
      <path fill="#d80027" d="M0 0h512v167H0z" />
      <path fill="#6da544" d="M0 345h512v167H0z" />
    </g>
  </svg>
</template>
