<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#0052b4" d="M0 0h512v144.7l-40.5 112.6 40.5 110V512H0V367.3l42.2-114L0 144.7z" />
      <path fill="#eee" d="M0 144.7h512v222.6H0z" />
      <path fill="#ffda44" d="M204.6 267.1l51.4-89 51.4 89z" />
      <path fill="#6da544" d="M322.8 296.5L256 330l-66.8-33.4V252h133.6z" />
      <path
        fill="#ffda44"
        d="M319 182l-23.6 23.5a55.5 55.5 0 0 1-39.4 95 55.7 55.7 0 0 1-39.3-95L193 182a89 89 0 1 0 126 0z"
      />
    </g>
  </svg>
</template>
