<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#ececec" d="M0 0h512v512H0z" />
      <path fill="#ffda44" d="M512 426.7V85.3L256 66.8 0 85.3v341.4l256 18.5z" />
      <path fill="#d80027" d="M0 255.9l256 22.3 256-22.3v-85.3H0z" />
      <path fill="#333" d="M0 85.3h512V0H0zm0 255.9h512v-85.3H0z" />
      <path fill="#d80027" d="M512 426.7H0V512h512z" />
      <path fill="#eee" d="M341.4 256a85.4 85.4 0 1 1-170.8 0 85.4 85.4 0 0 1 170.8 0z" />
      <path
        fill="#333"
        d="M287.3 260.4L256 246.8l7.7-26a16.6 16.6 0 0 0-4.4-15.6l7.8-7.8a27.7 27.7 0 0 0-19.6-8.2 27.7 27.7 0 0 0-19.7 8.2l7.8 7.8a16.6 16.6 0 0 0-4.8 11.8c0 1.7.2 3.4.7 4.9l-11.9 11.8h21.6s-9 13.4-13.8 24.4c-4.9 11 0 24.4 11.3 29.4l6.5 2.9 10.8 10.1v11.2l-11.1 11H267v-22.2l10.2-10.1h21l.3-.7a22 22 0 0 0-11.3-29.3z"
      />
    </g>
  </svg>
</template>
