import { updateDataInFirestore } from '@/service/firebase';
import { useCommonStore } from '@/stores/common';
import { useOnboardingStore } from '@/stores/onboarding';
import type { RouteName } from '@/types/Routing';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

// needs to be outside of the composable, aka "global"
// so it's not new state with every usage of useFirestore
const firestoreRequest = reactive({
  isLoading: false,
  isError: false,
  onNext: () => {},
});

export const useFirestore = () => {
  const router = useRouter();
  const commonStore = useCommonStore();
  const onboardingStore = useOnboardingStore();

  const updateData = async ({ data, nextRoute }: { data: {}; nextRoute?: RouteName }) => {
    firestoreRequest.isLoading = true;

    const details = {
      ...data,
      nextRoute,
    };

    if (!nextRoute) {
      delete details.nextRoute;
    }

    try {
      await updateDataInFirestore(details);

      if (nextRoute) {
        router.push({ name: nextRoute });
      }
    } catch (error) {
      firestoreRequest.isError = true;
    } finally {
      firestoreRequest.isLoading = false;
    }
  };

  const loadUserData = async () => {
    try {
      await onboardingStore.getFirestoreData(commonStore.currentUser!);
    } catch {
      firestoreRequest.isError = true;
    }
  };

  const onRetry = () => {
    firestoreRequest.isError = false;
    firestoreRequest.onNext && firestoreRequest.onNext();
  };

  const onCancel = () => {
    firestoreRequest.isError = false;
  };

  return {
    firestoreRequest,
    updateData,
    loadUserData,

    onRetry,
    onCancel,
  };
};
