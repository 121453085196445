<template>
  <TextErrorImageScreen
    :title="t('teamCardsExpiredToken.title')"
    :description="t('teamCardsExpiredToken.description')"
    :success-message="isInviteResendSuccess && t('teamCardsExpiredToken.successMessage')"
    :error-message="hasError && t('apiErrors.teamCardsApiErrors.DEFAULT_MESSAGE')"
    :button-label="t('teamCardsExpiredToken.buttonLabel')"
    @click="onInviteResendClick"
    :is-button-loading="isButtonLoading"
  />
</template>

<script setup lang="ts">
import TextErrorImageScreen from '@/components/organisms/TextErrorImageScreen.vue';
import { MethodType, teamCardsJwtLocalStorageKey } from '@/constants';
import { teamCardsRoutesNames } from '@/router/routeNames';
import { TeamCards } from '@/types/ApiName';
import { teamCardsTokenExpired } from '@/utils/analytics/errorEventNames';
import { teamCardsTokenExpiredEvents } from '@/utils/analytics/eventNames';
import { trackAnalytics, trackError } from '@/utils/analytics/sendAnalyticsUtil';
import { sendRequest } from '@/utils/axios';
import { type AxiosRequestConfig } from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const router = useRouter();

const isButtonLoading = ref(false);
const isInviteResendSuccess = ref(false);
const hasError = ref(false);

const onInviteResendClick = async (): Promise<void> => {
  isButtonLoading.value = true;

  trackAnalytics(teamCardsTokenExpiredEvents.clickSendNewEmail, {
    'click-send-new-team-cards-email': 'clicked',
  });

  const request: AxiosRequestConfig = {
    method: MethodType.POST,
    url: TeamCards.INVITE_RESEND,
    data: { token: localStorage.getItem(teamCardsJwtLocalStorageKey)! },
  };

  try {
    await sendRequest(request);
    isInviteResendSuccess.value = true;
    localStorage.removeItem(teamCardsJwtLocalStorageKey);

    trackAnalytics(teamCardsTokenExpiredEvents.successNewEmailSent, {
      'success-new-team-cards-email-sent': true,
    });
  } catch (error: any) {
    if (error?.response?.status === 422) {
      router.push({ name: teamCardsRoutesNames.teamCardsAppDownload });
    } else {
      hasError.value = true;
    }
    trackError(teamCardsTokenExpired.inviteResendError, error);
  } finally {
    isButtonLoading.value = false;
  }
};
</script>
