<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <path
        id="vxcrvcg5la"
        d="M18.755 1.876l.04.003c.041.003.083.007.124.018.028.007.052.02.078.03.024.01.05.015.073.028.014.008.023.02.036.03.023.014.043.033.065.052.032.03.06.06.086.094.009.012.022.02.029.033.008.011.009.024.015.036.02.038.035.077.048.118.008.027.016.053.02.08.007.039.006.079.005.119-.001.03 0 .058-.005.087-.002.009 0 .018-.002.027l-3.044 13.768c-.042.192-.172.352-.35.434-.083.038-.172.057-.26.057-.103 0-.205-.026-.297-.075l-4.396-2.364-2.382 3.995c-.116.192-.321.304-.537.304-.055 0-.111-.007-.166-.023-.272-.074-.46-.32-.46-.602v-5.523l-6.447-2.451c-.241-.091-.401-.32-.403-.578-.002-.257.153-.49.39-.586l17.508-7.07c.016-.008.034-.006.051-.011.047-.015.093-.027.142-.03.027-.002.052.001.079.003zM8.726 13.217v2.64l1.193-1.998-1.193-.642zm8.996-8.937l-8.546 7.76 6.104 3.282L17.722 4.28zm-1.978.107L2.963 9.549l5.002 1.902 7.779-7.064z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-306 -238) translate(296 228) translate(10 10)">
            <path d="M0 0H20V20H0z" />
            <use fill="currentColor" xlink:href="#vxcrvcg5la" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
