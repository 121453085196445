<template>
  <section class="steps">
    <MfsFont
      fontName="body-2-bold"
      color="primary"
      :class="[
        'mb-0',
        'steps__title',
        {
          'steps__title--ios': isIos,
        },
      ]"
    >
      {{ t('teamCardsHomeSlider.slider.step') }}
    </MfsFont>
    <ul v-for="i in 3" :key="i" class="steps__numbers">
      <ol :class="currentSlide === i && 'active'" @click="onClickSlide(i)">
        {{
          i
        }}
      </ol>
    </ul>
  </section>
</template>

<script setup lang="ts">
import MfsFont from '@/components/atoms/MfsFont.vue';
import { teamCardsStartPageEvents } from '@/utils/analytics/eventNames';
import { trackAnalytics } from '@/utils/analytics/sendAnalyticsUtil';
import { MOBILE_PLATFORMS, getMobileOS } from '@/utils/detectMobileOs';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['switch-to-slide']);

defineProps<{
  currentSlide: number;
}>();

const isIos = ref(false);

onMounted(() => {
  isIos.value = getMobileOS() === MOBILE_PLATFORMS.IOS;
});

const onClickSlide = (slideNumber: number) => {
  emit('switch-to-slide', slideNumber);

  trackAnalytics(teamCardsStartPageEvents.clickOnSlide, {
    'click-on-slide-number-on-team-cards': slideNumber,
  });
};
</script>

<style scoped>
.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.steps__title,
.steps__numbers ol {
  font-family: CAMetro;
  font-weight: 800;
}

.steps__title--ios {
  margin-top: 2px;
}

.steps__numbers {
  list-style: none;
  margin: 0;
  padding: 0;
}
.steps__numbers ol {
  cursor: pointer;
  display: inline-block;
  color: #cce0ff;
  font-size: 14px;
  padding: 0 0 0 6px;
  transition: color 250ms ease-in-out;
}
.steps__numbers ol.active {
  color: var(--mfs-color-primary);
}

@media (hover: hover) {
  .steps__numbers ol:hover {
    color: var(--mfs-color-primary);
  }
}
</style>
