export const MOBILE_PLATFORMS = {
  IOS: 'iOS',
  ANDROID: 'Android',
};

export function getMobileOS(): string {
  const ua = navigator.userAgent;

  if (/android/i.test(ua)) {
    return MOBILE_PLATFORMS.ANDROID;
  } else if (isiOS()) {
    return MOBILE_PLATFORMS.IOS;
  }

  return 'Other';
}

function isiOS(): boolean {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13+ detection
    (navigator.userAgent.includes('Mac') && navigator.maxTouchPoints > 1)
  );
}

// returns true for touch screen OS except for laptops with touch feature
export function isMobileOS(): boolean {
  return 'ontouchstart' in document.documentElement;
}
