import { MFS_FIREBASE_APP_URL } from '@/env';
import { indexRoutesNames, onboardingV2RoutesNames, teamCardsRoutesNames } from '@/router/routeNames';
import { updateDataInFirestore } from '@/service/firebase';
import { useCommonStore } from '@/stores/common';
import { useOnboardingStore } from '@/stores/onboarding';
import { AccountRole } from '@/types/AccountRole';
import { AccountStatus } from '@/types/AccountStatus';
import { EventKeys, trackEvents } from '@/utils/analytics/trackEvents';
import { getPathString } from '@/utils/helpers';
import { ref } from 'vue';
import { useRoute, useRouter, type RouteRecordName } from 'vue-router';

export const useCommonPageFeats = () => {
  const router = useRouter();
  const route = useRoute();
  const commonStore = useCommonStore();
  const onboardingStore = useOnboardingStore();

  const isFreshChatDialogOpen = ref(false);

  const toggleFreshChatDialog = () => {
    isFreshChatDialogOpen.value = !isFreshChatDialogOpen.value;
  };

  const trackGoBack = (currentRoute: RouteRecordName, previousRoute: string) => {
    trackEvents(EventKeys.BACK_CLICK, currentRoute, previousRoute);
  };

  const goBack = () => {
    const previousRoute = commonStore.basePageDetails.previousRoute;
    const currentUser = commonStore.currentUser;

    if (route.name === indexRoutesNames.login) {
      return router.push({ name: indexRoutesNames.home });
    }

    // for the cases where we don't use the template with previousRoute value
    // (usually the pre-auth routes)
    if (!previousRoute) {
      trackGoBack(route.name!, indexRoutesNames.home);
      return router.push({ name: indexRoutesNames.home });
    }

    if (previousRoute && currentUser) {
      updateDataInFirestore({ nextRoute: previousRoute });
      trackGoBack(route.name!, previousRoute);

      if (route.name !== previousRoute) {
        return router.push({ name: previousRoute });
      }
    }

    router.back();
  };

  const onClickLogout = () => {
    trackEvents(EventKeys.LOGOUT_BUTTON_CLICK, getPathString(route.path), route.name);
  };

  const logout = () => {
    trackEvents(EventKeys.LOGOUT_CONFIRM, getPathString(route.path));
    logOutAndResetState();

    router.push({ name: indexRoutesNames.home });
  };
  /**
   * This method will check if current user has finished registration
   * or is still in progress and redirect accordingly (to one of "end screens"
   * or to nextRoute stored in Firestore).
   * @param {boolean} shouldRedirectToStart Flag to decide the next route
   */
  const redirectLoggedInUser = async (shouldRedirectToStart: boolean) => {
    const token = await commonStore.currentUser!.getIdTokenResult();
    const claims = token?.claims;
    const { accountStatus, roles } = claims;

    if (!claims) {
      logOutAndResetState();
      router.push({ name: indexRoutesNames.home });
      return;
    }

    onboardingStore.accountStatus = accountStatus;
    onboardingStore.accountRole = roles;

    if (roles === AccountRole.TEAMCARD_MEMBER && accountStatus === AccountStatus.MFS_ONBOARDED) {
      router.push({ name: teamCardsRoutesNames.teamCardsAppDownload });

      logOutAndResetState();

      return;
    }

    const nextOnboardedRoute = shouldRedirectToStart
      ? onboardingV2RoutesNames.phoneNumberEntry
      : onboardingV2RoutesNames.appDownload;

    switch (accountStatus) {
      case AccountStatus.MFS_ONBOARDED:
        return router.push({ name: nextOnboardedRoute });

      case AccountStatus.AION_ONBOARDED:
        return (window.location.href = MFS_FIREBASE_APP_URL);

      case AccountStatus.ACTIVATED:
        return router.push({ name: onboardingStore.nextRoute! });
    }

    if (onboardingStore.nextRoute && onboardingStore.nextRoute !== route.name) {
      router.push({ name: onboardingStore.nextRoute! });
      return;
    }

    router.push({ name: onboardingV2RoutesNames.phoneNumberEntry });
  };

  const openFreshChat = () => {
    if (onboardingStore.isFunctionalCookieEnabled) {
      window?.fcWidget?.open();
      window?.fcWidget?.show();
    } else {
      isFreshChatDialogOpen.value = true;
    }
  };

  const logOutAndResetState = async () => {
    await commonStore.logOut();
    onboardingStore.$reset();
    localStorage.clear();
  };

  return {
    trackGoBack,
    goBack,
    onClickLogout,
    logout,
    redirectLoggedInUser,
    openFreshChat,
    logOutAndResetState,
    isFreshChatDialogOpen,
    toggleFreshChatDialog,
  };
};
