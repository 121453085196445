<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#333" d="M0 167l254.6-36.6L512 166.9v178l-254.6 36.4L0 344.9z" />
      <path fill="#0052b4" d="M0 0h512v166.9H0z" />
      <path fill="#eee" d="M0 344.9h512V512H0z" />
    </g>
  </svg>
</template>
