<template>
  <div
    :class="[
      'relative mb-6 flex align-middle transition-opacity duration-300',
      {
        'cursor-pointer': shouldAllowNavigation,
        'opacity-50': !isCompleted && !isActive,
      },
    ]"
    @click="navigateToStep"
  >
    <span v-show="!isCompleted" class="mr-3 size-[22px] rounded-full border-2 border-primary-v2" />

    <MfsIcon v-show="isCompleted" icon-name="checkmark-outline-thick" :size="22" color="primary-v2" classes="mr-3" />

    <p class="m-0 font-metro font-bold text-primary-v2">{{ singleStep }}</p>

    <span
      :class="[
        {
          'absolute top-px size-[22px] animate-ping-once rounded-full bg-primary-v2 opacity-40': isCompleted,
        },
      ]"
    />

    <span
      v-if="!isLast"
      :class="[
        'absolute left-[10px] top-[24px] z-1 !h-[23px] w-[2px] bg-primary-v2 opacity-40 transition-all duration-300',
      ]"
    />

    <span
      :class="[
        'z-1 h-0 transition-all duration-300',
        'absolute left-[10px] top-[24px] w-[2px] bg-primary-v2',
        {
          '!h-0': !isCompleted && !isLast,
          '!h-[23px]': isCompleted && !isLast,
        },
      ]"
    />
  </div>
</template>

<script setup lang="ts">
import MfsIcon from '@/components/atoms/MfsIcon.vue';
import { onboardingV2RoutesNames, smeRoutesNames, teamCardsRoutesNames } from '@/router/routeNames';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const props = defineProps<{
  singleStep: string;
  isActive: boolean;
  isLast: boolean;
  isCompleted: boolean;
  isFirst: boolean;
  isLoggedOut: boolean;
}>();

const isSme = computed(() => {
  return route.fullPath.includes('sme');
});

const navigateToStep = () => {
  if (!shouldAllowNavigation.value) {
    return;
  }

  isSme.value ? smeStepsRoutes(props.singleStep) : soleTraderStepsRoutes(props.singleStep);
};

const soleTraderStepsRoutes = (singleStep: string): void => {
  switch (singleStep) {
    case t('onboardingV2.sidebar.steps.1'):
      router.push({ name: onboardingV2RoutesNames.phoneNumberEntry });
      break;
    case t('onboardingV2.sidebar.steps.2'):
      router.push({ name: onboardingV2RoutesNames.personalDetails });
      break;
    case t('onboardingV2.sidebar.steps.3'):
      router.push({ name: onboardingV2RoutesNames.businessDetails });
      break;
    default:
      router.push({ name: onboardingV2RoutesNames.legalDetails });
  }
};

const smeStepsRoutes = (singleStep: string): void => {
  switch (singleStep) {
    case t('onboardingV2.sidebar.stepsSme.1'):
      router.push({ name: onboardingV2RoutesNames.phoneNumberEntry });
      break;
    case t('onboardingV2.sidebar.stepsSme.2'):
      router.push({ name: smeRoutesNames.roleSelection });
      break;
    case t('onboardingV2.sidebar.stepsSme.3'):
      router.push({ name: smeRoutesNames.personalDetails });
      break;
    case t('onboardingV2.sidebar.stepsSme.4'):
      router.push({ name: smeRoutesNames.representatives });
      break;
    default:
      router.push({ name: smeRoutesNames.legalDetails });
  }
};

const shouldAllowNavigation = computed(() => {
  return (
    !props.isActive &&
    props.isCompleted &&
    !props.isLoggedOut &&
    route.name !== onboardingV2RoutesNames.appDownload &&
    route.name !== teamCardsRoutesNames.teamCardsAppDownload
  );
});
</script>
