import AlertInfoSmall from './AlertInfoSmall.vue';
import ArrowDown from './ArrowDown.vue';
import ArrowLeft from './ArrowLeft.vue';
import ArrowRight from './ArrowRight.vue';
import ArrowUp from './ArrowUp.vue';
import BankIcon from './BankIcon.vue';
import Bin from './Bin.vue';
import Buildings from './Buildings.vue';
import CashbackIcon from './CashbackIcon.vue';
import CheckboxOff from './CheckboxOff.vue';
import CheckCircleOutline from './CheckCircleOutline.vue';
import CheckmarkOutline from './CheckmarkOutline.vue';
import CheckmarkOutlineThick from './CheckmarkOutlineThick.vue';
import CheckmarkOutlineThickV2 from './CheckmarkOutlineThickV2.vue';
import CheckSmallFilled from './CheckSmallFilled.vue';
import ChevronDown from './ChevronDown.vue';
import ChevronLeft from './ChevronLeft.vue';
import ChevronRight from './ChevronRight.vue';
import ChevronUp from './ChevronUp.vue';
import Close from './Close.vue';
import { countriesIconsMap } from './countriesIconsMapping';
import CrossOutline from './CrossOutline.vue';
import Earth from './Earth.vue';
import ErrorWarning from './ErrorWarning.vue';
import Eye from './Eye.vue';
import EyeOff from './EyeOff.vue';
import FileUpload from './FileUpload.vue';
import HelpCircle from './HelpCircle.vue';
import IdIcon from './IdIcon.vue';
import Legal from './Legal.vue';
import Login from './Login.vue';
import Logout from './Logout.vue';
import Message from './Message.vue';
import MoreThreeDots from './MoreThreeDots.vue';
import Pen from './Pen.vue';
import Person from './Person.vue';
import PlusInCircle from './PlusInCircle.vue';
import Search from './Search.vue';
import Send from './Send.vue';
import SoleTrader from './SoleTrader.vue';

export const iconsMap: { [key: string]: object } = {
  'alert-info-small': AlertInfoSmall,
  'checkmark-outline': CheckmarkOutline,
  'checkmark-outline-thick': CheckmarkOutlineThick,
  'checkmark-outline-thick-v2': CheckmarkOutlineThickV2,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  bin: Bin,
  buildings: Buildings,
  'check-circle-outline': CheckCircleOutline,
  'check-small-filled': CheckSmallFilled,
  'checkbox-off': CheckboxOff,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  close: Close,
  'cross-outline': CrossOutline,
  earth: Earth,
  'error-warning': ErrorWarning,
  eye: Eye,
  'eye-off': EyeOff,
  'help-circle': HelpCircle,
  'file-upload': FileUpload,
  legal: Legal,
  logout: Logout,
  message: Message,
  send: Send,
  login: Login,
  'more-three-dots': MoreThreeDots,
  'bank-icon': BankIcon,
  'id-icon': IdIcon,
  'cashback-icon': CashbackIcon,
  pen: Pen,
  person: Person,
  'plus-in-circle': PlusInCircle,
  search: Search,
  'sole-trader': SoleTrader,
  ...countriesIconsMap,
};

export const iconNames = Object.keys(iconsMap);

// Vue specific format
export const mfsIconsMap = Object.entries(iconsMap).reduce(
  (res, [iconName, component]) => ({
    ...res,
    [iconName]: { component },
  }),
  {}
);
