/*
 * Default validation values that should be passed to Analytics in case of no errors
 */

type ValidationFieldset = Record<`validate-${string}`, null>;

const validateCompanyDetails: ValidationFieldset = {
  'validate-company-name-sme': null,
  'validate-company-reg-number-sme': null,
  'validate-place-of-juri-sme': null,
  'validate-tax-number-sme': null,
  'validate-registration-day-sme': null,
  'validate-registration-month-sme': null,
  'validate-registration-year-sme': null,
  'validate-nace-sme': null,
};

const validateCompanyAddress: ValidationFieldset = {
  'validate-street-name-company-sme': null,
  'validate-house-number-company-sme': null,
  'validate-city-company-sme': null,
  'validate-postcode-company-sme': null,
};

const validateAddressDetailsSme: ValidationFieldset = {
  'validate-street-name-sme': null,
  'validate-house-number-sme': null,
  'validate-city-sme': null,
  'validate-postcode-sme': null,
};

const validatePersonalDetailsSme: ValidationFieldset = {
  'validate-salutation-sme': null,
  'validate-firstname-sme': null,
  'validate-lastname-sme': null,
  'validate-birthday-day-sme': null,
  'validate-birthday-month-sme': null,
  'validate-birthday-year-sme': null,
  'validate-birthplace-sme': null,
};

const validateTaxDetailsSme: ValidationFieldset = {
  'validate-tax-id-sme': null,
};

export const getDefaultValidationSme = {
  validateCompanyDetails,
  validateCompanyAddress,
  validateAddressDetailsSme,
  validatePersonalDetailsSme,
  validateTaxDetailsSme,
};
