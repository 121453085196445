<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#6da544" d="M0 378.3l254-37.1 258 37V512H0z" />
      <path fill="#ffda44" d="M0 256.1l252.2-33.3L512 256v122.4H0z" />
      <path fill="#0052b4" d="M0 133.7L249.7 97 512 133.7v122.4H0z" />
      <path fill="#d80027" d="M0 0h512v133.7H0z" />
    </g>
  </svg>
</template>
