<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#eee" d="M0 144.7l255.3-36.5L512 144.7v222.6L250.5 407 0 367.3z" />
      <path fill="#d80027" d="M0 0h512v144.7H0z" />
      <path fill="#0052b4" d="M0 367.3h512V512H0z" />
      <path
        fill="#6da544"
        d="M319 182l-23.6 23.5a55.5 55.5 0 0 1-39.4 95 55.7 55.7 0 0 1-39.3-95L193 182a89 89 0 1 0 126 0z"
      />
      <path
        fill="#ffda44"
        d="M256 211.5l8.3 25.5H291l-21.7 15.8 8.3 25.5-21.7-15.8-21.7 15.8 8.3-25.5-21.7-15.8h26.8z"
      />
    </g>
  </svg>
</template>
