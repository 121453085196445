type EventFieldset = Record<string, string>;

export const companyTypeSelection: EventFieldset = {
  clickOnCompanyTypeOption: 'Select Legal Type Option in Company Type Selection',
  clickBack: 'Click Back On Company Type Selection SME',
};

export const roleSelection: EventFieldset = {
  clickOnRoleOption: 'Click on a Role Option in Role Select',
  clickBack: 'Click Back On Role Selection SME',
};

export const notSupportedPrivatePerson: EventFieldset = {
  clickBack: 'Click Back On Not Supported Private Person SME',
};

export const usTaxResidency: EventFieldset = {
  selectUsTaxResidencyStatus: 'Select US Tax residency Status SME',
  clickContinue: 'Click Continue On US Tax Residency SME',
  clickBack: 'Click Back On US Tax Residency SME',
};

export const representativeQuestion: EventFieldset = {
  selectRepresentativeStaus: 'Select Representative Status SME',
  clickContinue: 'Click Continue On Representative Question SME',
  clickBack: 'Click Back On Representative Question SME',
};

export const notSupportedNonRepresentative: EventFieldset = {
  clickBack: 'Click Back On Not Supported Non Representative SME',
};

export const companyDetails: EventFieldset = {
  selectNace: 'Select Nace SME',
  clickContinue: 'Click Continue On Company Details SME',
  clickBack: 'Click Back On Company Details SME',
};

export const companyAddress: EventFieldset = {
  clickContinue: 'Click Continue On Company Address SME',
  clickBack: 'Click Back On Company Address SME',
};

export const personalDetails: EventFieldset = {
  selectSalutation: 'Select Salutation SME',
  selectTitle: 'Select Title SME',
  selectCountryOfBirth: 'Select Country Of Birth SME',
  clickContinue: 'Click Continue On Personal Details SME',
  clickBack: 'Click Back On Personal Details SME',
};

export const addressDetails: EventFieldset = {
  clickContinue: 'Click Continue On Address Details SME',
  clickBack: 'Click Back On Address Details SME',
};

export const identityDetails: EventFieldset = {
  selectIdOrigin: 'Select ID Origin SME',
  selectIdType: 'Select ID Type SME',
  clickContinue: 'Click Continue On Identity Details SME',
  clickBack: 'Click Back On Identity Details SME',
};

export const taxDetails: EventFieldset = {
  selectTaxResidency: 'Select Tax Residency SME',
  clickContinue: 'Click Continue on Personal Tax Details SME',
  clickBack: 'Click Back On Tax Details SME',
  clickAddAfterMaxTaxResidency: 'Click Add More Tax Residency After Allowed Limit on Tax Details SME',
};

export const representatives: EventFieldset = {
  clickContinue: 'Click Continue On Representatives SME',
  clickBack: 'Click Back On Representatives SME',
  selectSalutation: 'Select Salutation SME',
};

export const addRepresentative: EventFieldset = {
  clickContinue: 'Click Continue on Add Representative SME',
  clickBack: 'Click Back on Add Representative SME',
};

export const editRepresentative: EventFieldset = {
  clickContinue: 'Click Continue on Edit Representative SME',
  clickBack: 'Click Back on Edit Representative SME',
};

export const uploadDocumentsCompany: EventFieldset = {
  clickContinue: 'Click Continue on Upload Documents of Company SME',
  clickUpload: 'Click Upload on Upload Documents of Company SME',
  clickDelete: 'Click Delete on Upload Documents of Company SME',
  clickBack: 'Click Back On Upload Documents SME',
};

export const legalDetails: EventFieldset = {
  clickContinue: 'Click Continue on Legal Details SME',
  clickBack: 'Click Back On Legal Details SME',
};

export const inviteRepresentative: EventFieldset = {
  clickContinue: 'Click Continue on Invite Representative SME',
  clickBack: 'Click Back On Invite Representative SME',
};

export const representativeStatus: EventFieldset = {
  clickContinue: 'Click Continue on Representative Status SME',
  clickBack: 'Click Back On Representative Status SME',
};

export const validationEventsSme: EventFieldset = {
  validateCompanyDetails: 'Validate Company Details SME',
  validateCompanyAddress: 'Validate Company Address SME',
  validateAddressDetailsSme: 'Validate Address Details SME',
  validatePersonDetails: 'Validate Person Details SME',
  validatePersonalDetailsSme: 'Validate Personal Details SME',
  validateTaxDetailsSme: 'Validate Tax Details SME',
  validateAddRepresentative: 'Validate Add Representative SME',
};

export const personSelection: EventFieldset = {
  selectPerson: 'Select a Person',
};
