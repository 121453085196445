<template>
  <div
    class="flex size-full flex-1 flex-col md:grid md:grid-cols-8 md:content-start md:gap-x-16 lg:gap-x-10 xl:grid-cols-12"
  >
    <div v-if="$slots.title" class="md:col-start-1 md:col-end-9 xl:col-start-2 xl:col-end-12">
      <slot name="title" />
    </div>

    <div v-if="$slots.infoText" class="mfs-onbv2-page-body">
      <slot name="infoText" />
    </div>

    <slot />
  </div>
</template>
