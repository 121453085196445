<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <circle id="arfaxft4fa" cx="12" cy="12" r="10" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-40 -251) translate(25 251) translate(15)">
            <mask id="2rz3go4w4b" fill="#fff">
              <use xlink:href="#arfaxft4fa" />
            </mask>
            <g mask="url(#2rz3go4w4b)">
              <g>
                <path fill="#000" d="M0 0H20V6.667H0z" transform="translate(2 2)" />
                <path fill="#F20000" d="M0 6.667H20V13.334H0z" transform="translate(2 2)" />
                <path fill="#FC0" d="M0 13.333H20V20H0z" transform="translate(2 2)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
