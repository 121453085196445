<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path id="3rpvf00sec" d="M0 0L0 20 40 20 40 0z" />
      <path id="r2oplrj0de" d="M20 10h20v10L20 10zm0 0v10H0l20-10zm0 0H0V0l20 10zm0 0V0h20L20 10z" />
      <circle id="6j2334v0da" cx="12" cy="12" r="10" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-40 -300) translate(25 300) translate(15)">
            <mask id="4b5idmla3b" fill="#fff">
              <use xlink:href="#6j2334v0da" />
            </mask>
            <g mask="url(#4b5idmla3b)">
              <g transform="translate(-8 2)">
                <mask id="q4ncir0xed" fill="#fff">
                  <use xlink:href="#3rpvf00sec" />
                </mask>
                <g mask="url(#q4ncir0xed)">
                  <path fill="#012169" fill-rule="nonzero" d="M0 0L0 20 40 20 40 0z" />
                  <path fill="#000" fill-rule="nonzero" stroke="#FFF" stroke-width="4" d="M0 0l40 20m0-20L0 20" />
                  <g>
                    <mask id="7x8h6r8h8f" fill="#fff">
                      <use xlink:href="#r2oplrj0de" />
                    </mask>
                    <path
                      fill="#000"
                      fill-rule="nonzero"
                      stroke="#C8102E"
                      stroke-width="2.667"
                      d="M0 0l40 20m0-20L0 20"
                      mask="url(#7x8h6r8h8f)"
                    />
                  </g>
                  <path fill="#000" fill-rule="nonzero" stroke="#FFF" stroke-width="6.667" d="M20 0v20M0 10h40" />
                  <path fill="#000" fill-rule="nonzero" stroke="#C8102E" stroke-width="4" d="M20 0v20M0 10h40" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
