/* eslint-disable */
const cssProperties = {
  colors: {
    primary: '#0064fe',
    'primary-v2': '#002d72',
    'primary-v3': '#001432',
    secondary: '#ffed00',

    'base-border': '#e5e7eb',
    'base-border-dark': '#ccd0d6',
    'base-border-light': '#e6eaf1',
    'base-info-text': '#000000de',
    'base-bg': '#f2f5f8',
    'base-tint': '#99abc7',
    'base-tint-darker': '#6681aa',
    'base-lighter': '#e6f0ff',

    info: '#3abff8',
    success: '#38d200',
    warning: '#ffb219',
    error: '#ff350d',

    // basics
    white: '#ffffff',
  },
};

export { cssProperties };
/* eslint-enable */
