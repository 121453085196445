<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path
        fill="#ffda44"
        d="M0 322.8l253.6-18.4L512 322.8v33.4l-258 15.3L0 356.2zm0 66.8l257.2-13.8L512 389.6V423l-253 16.9L0 423z"
      />
      <path fill="#338af3" d="M0 0h512v322.8H0zm0 356.2h512v33.4H0zM0 423h512v89H0z" />
      <path fill="#eee" d="M117.3 161.5l-50-22.1 50-22 22-50.1 22.2 50 50 22-50 22.2-22.1 50z" />
      <path fill="#d80027" d="M139.4 94.9l13.6 30.9 31 13.6-31 13.6-13.6 31-13.6-31-31-13.6 31-13.6z" />
    </g>
  </svg>
</template>
