<template>
  <EmailNotificationContainer
    :headline="t('offlineOnboardingEmailSent.headline')"
    :description-primary="t('offlineOnboardingEmailSent.descriptionPrimary')"
    :description-secondary="t('offlineOnboardingEmailSent.descriptionSecondary')"
  />
</template>

<script setup lang="ts">
import EmailNotificationContainer from '@/components/molecules/EmailNotificationContainer.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
