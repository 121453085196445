import type { Env } from '@/types/env';

export default {
  MFS_APP_DOMAIN: 'metro-fs.com',
  MFS_ENV: 'pre-production',
  MFS_HOMEPAGE: 'https://metro-fs.com',
  MFS_COBROWSE_API: 'https://assist.metro-fs.com',
  MFS_COBROWSE_LICENSE: '3x8W1EBfggALGg',
  MFS_POSTHOG_TOKEN: 'phc_GXFbHYJpRf15IecJqDN3EVv3u5i5r0DHyPhADdhCvTr',
  MFS_POSTHOG_API_HOST: 'https://analytics.metro-fs.com',
  MFS_BV2_API_BASE_URI: 'https://api.pp.metro-fs.com/v1',
  MFS_TERMS_BUCKET: 'pp-metro-fs-eu-terms-versions-active',

  // sso
  MFS_SSO_CONTEXT_URI: 'https://get.pp.metro-fs.com',

  // firebase
  MFS_FIREBASE_APIKEY: 'AIzaSyCSFELpsTJIbInS8QtRNI1uOF5aWmvRtfw',
  MFS_FIREBASE_AUTHDOMAIN: 'pp-metro-fs.firebaseapp.com',
  MFS_FIREBASE_PROJECT_ID: 'pp-metro-fs',
  MFS_FIREBASE_MASSENGER_SENDER_ID: '240433582942',
  MFS_FIREBASE_APP_ID: '1:240433582942:web:a6319aad7660cc052d75cf',
  MFS_FIREBASE_APP_URL: 'https://link.pp.metro-fs.com/app',
  MFS_FIREBASE_IOS_DOWNLOAD_URL: 'https://link.pp.metro-fs.com/ios-download',
  MFS_FIREBASE_ANDROID_DOWNLOAD_URL: 'https://link.pp.metro-fs.com/android-download',

  // freshchat
  MFS_FRESHCHAT_TOKEN: 'f88c7b45-82f2-42b4-b93c-7590ef38448f',

  // google places
  MFS_PLACES_API_KEY: 'AIzaSyCmrxUlXb-JiwShDZ1kFrA5I6NdU0NrO6s',

  // sentry's Data Source Name (DSN)
  MFS_SENTRY_DSN: 'https://42b2d510bf34447a91e148cbbf050f5d@o4504928279199744.ingest.sentry.io/4504928283066368',
} as Env;
