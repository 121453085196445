type ErrorEventFieldset = Record<string, string>;

export const login: ErrorEventFieldset = {
  general: 'Error for Login',
  incorrectPassword: 'Error For Login Incorrect Password',
  userNotFound: 'Error For Login User Not Found',
};

export const passwordEntry: ErrorEventFieldset = {
  general: 'Error for Password Entry',
};

export const notSupportedCustomer: ErrorEventFieldset = {
  notifyEmail: 'Error for Unsupported Customer Email',
};

export const notSupportedNationality: ErrorEventFieldset = {
  notifyEmail: 'Error for Unsupported Nationality Email',
};

export const legal: ErrorEventFieldset = {
  registration: 'Error for Onboarding Registration',
  downloadPdf: 'Error For Legal Download PDF',
};

export const resetPasswordTrigger: ErrorEventFieldset = {
  general: 'Error for Reset Password Trigger',
};

export const resetPasswordConfirmation: ErrorEventFieldset = {
  general: 'Error for Reset Password Email Resend',
};

export const resetPasswordInput: ErrorEventFieldset = {
  general: 'Error for Reset Password',
  expiredResetCode: 'Error For Password Expired Reset Code',
  invalidResetCode: 'Error For Password Invalid Reset Code',
};

export const changeEmail: ErrorEventFieldset = {
  general: 'Error for Activation',
  expiredToken: 'Error For Activation Expired Token',
};

export const otpEmailEntry: ErrorEventFieldset = {
  general: 'Error for OTP Email Entry',
  tooManyRequests: 'Error for OTP Email Entry Too Many Requests',
  payloadValidation: 'Error for OTP Email Entry Payload Validation',
};

export const otpEntry: ErrorEventFieldset = {
  triggerResendError: 'Error for Trigger Resend On OTP Entry',
  otpSubmitError: 'Error for OTP Submit On OTP Entry',
  signInWithFirebaseError: 'Error for Sign In With Firebase On OTP Entry',
};

export const teamCardsStartPage: ErrorEventFieldset = {
  general: 'Error for Team Cards Start Click',
};

export const teamCardsPasswordEntry: ErrorEventFieldset = {
  general: 'Error for Team Cards Password Entry',
};

export const teamCardsTokenExpired: ErrorEventFieldset = {
  inviteResendError: 'Error for Team Cards Token Expired',
};
