<template>
  <Transition :duration="200">
    <div v-if="isOpened">
      <div class="fixed left-0 top-0 z-[202] size-full bg-black/50 md:bg-white/70" />
      <div
        class="fixed left-0 top-0 z-[203] flex size-full items-center justify-center"
        @click.self="isPersistent ? bounceDialog() : closeModal()"
      >
        <div
          :class="[
            'mfs-dialog relative mx-6 my-10 flex flex-col overflow-hidden rounded-2xl bg-white shadow-mfs-xl md:!mx-auto md:w-[560px] md:max-w-[560px] xl:w-[664px] xl:max-w-[664px]',
            { 'mfs-dialog--bounce': bounceBabyBounce },
          ]"
          :style="width ? { width: `${width}px`, maxWidth: `${width}px` } : undefined"
          tabindex="-1"
          aria-modal="true"
          role="dialog"
        >
          <div class="flex w-full items-center justify-end bg-white px-4 pb-2 pt-4">
            <button v-if="hasCloseIcon" @click="closeModal">
              <MfsIcon :size="20" color="primary-v3" icon-name="close" />
            </button>
          </div>

          <div
            class="markdown-container cursor-pointer-events-auto relative w-full overflow-y-auto overflow-x-hidden bg-white px-6 pb-8 font-lato text-sm leading-page-info-text"
          >
            <div v-if="title && title.length" class="mb-5 mt-1">
              <MfsFont font-name="title" color="primary-v2" class="text-left">
                {{ title }}
              </MfsFont>
            </div>

            <div v-if="markdownText" class="markdown-container" v-html="sanitizedMarkdownText" />

            <slot />
          </div>

          <div class="bottom-white absolute bottom-0 left-0 h-8 bg-white" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import MfsFont from '@/components/atoms/MfsFont.vue';
import MfsIcon from '@/components/atoms/MfsIcon.vue';
import DOMPurify from 'dompurify';
import { ref, computed } from 'vue';

const bounceBabyBounce = ref(false);

const props = withDefaults(
  defineProps<{
    title?: string;
    width?: string;
    isPersistent?: boolean;
    isOpened: boolean;
    hasCloseIcon?: boolean;
    markdownText?: string;
  }>(),
  {
    isPersistent: false,
    isOpened: false,
    hasCloseIcon: true,
  }
);

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const closeModal = (): void => {
  emit('close');
};

const bounceDialog = (): void => {
  bounceBabyBounce.value = true;
  setTimeout(() => {
    bounceBabyBounce.value = false;
  }, 300);
};

const sanitizedMarkdownText = computed(() => {
  if (!props.markdownText) return;
  return DOMPurify.sanitize(props.markdownText);
});
</script>

<style scoped>
.mfs-dialog {
  max-height: 90vh;
}

.markdown-container {
  text-align: left;
  max-height: calc(100% - 44px);
}

.bottom-white {
  width: calc(100% - 15px);
}

.mfs-dialog--bounce {
  animation: bounce 0.3s ease-in-out;
}

@keyframes bounce {
  0% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

/* transition */
.v-leave-active .mfs-dialog {
  transition: all 0.2s ease-in-out;
}

.v-enter-active .mfs-dialog {
  transition: all 0.2s ease-in-out;
}

.v-enter-from .mfs-dialog,
.v-leave-to .mfs-dialog {
  opacity: 0;
  transform: translateY(100vh);
}

/* Styles for Markdown content, `::v-deep` assures the selector works in Scoped CSS: */
.markdown-container :deep(h1) {
  font-size: 28px;
  letter-spacing: -0.1px;
  color: var(--mfs-color-primary-v2);
  font-family: CAMetro;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 1em;
}

.markdown-container :deep(h2),
.markdown-container :deep(h3),
.markdown-container :deep(h4),
.markdown-container :deep(h5) {
  color: var(--mfs-color-primary-v2);
  font-family: CAMetro;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 1em;
  margin-top: 1em;
}

.markdown-container :deep(ul) {
  margin-bottom: 1em;
}

.markdown-container :deep(table) {
  border-collapse: collapse;
  margin: 0 0 1em 0;
  width: 100%;
}

.markdown-container :deep(table td),
.markdown-container :deep(table th) {
  padding: 1em;
}

.markdown-container :deep(table td:nth-child(1)) {
  padding-left: 1em;
  width: 50%;
}

.markdown-container :deep(table th:nth-child(2)),
.markdown-container :deep(table th:nth-child(3)) {
  background-color: #f3f5f8;
  color: #002d72;
  width: 25%;
}

.markdown-container :deep(table td:nth-child(2)),
.markdown-container :deep(table td:nth-child(3)) {
  background-color: #f3f5f8;
  color: #0064fe;
  width: 25%;
}

.markdown-container :deep(table th:nth-child(3)),
.markdown-container :deep(table td:nth-child(3)) {
  border-left: solid 2em #fff;
}
</style>
