<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#338af3" d="M0 256L256 0h256v512H0z" />
      <path
        fill="#eee"
        d="M367.3 144.7l-78 22.3h.1v66.7l9.2 11-9.2 11.3c0 45.5 45.3 67 66.8 74.6l11.5-8.8 10.7 8.8c21.5-7.7 66.8-29.1 66.8-74.6l-8-11 8-11.3V167l-77.9-22.3z"
      />
      <path
        fill="#d80027"
        d="M445.2 167v-33.4H289.4V167h66.8v66.7h-66.8V256h66.8v74.6a111 111 0 0 0 11.1 3.4s4.4-1 11.1-3.4V256h66.8v-22.3h-66.8V167z"
      />
      <path
        fill="#eee"
        d="M0 0h33.4l32 16.9L100.3 0H256v33.4l-14.8 33.5 14.8 33.3v33.4l-9.3 33.7 9.3 41.5v15.7l-6.2 11.6L256 256h-47.2l-39.3-7-35.9 7.1-33.4-.1-32.6-16.6L33.4 256H0V100.2l14.2-35.8L0 33.4z"
      />
      <path fill="#d80027" d="M256 224.5l-91-91h-31.4L256 256z" />
      <path fill="#d80027" d="M33.4 0v33.4H0v66.8h33.4V256h66.8V100.2H256V33.4H100.2V0z" />
      <path fill="#0052b4" d="M180.8 133.6H256v75.2zm-47.2 47.2v75.3l75.2-.1z" />
    </g>
  </svg>
</template>
