import { CompanyType } from '@/types/OnboardingRequest';
import { type TranslateResult } from 'vue-i18n';
import featureFlagsStatus from './featureFlags';

export const convertPascalToKebabCase = (pascalCaseStr: string): string => {
  return pascalCaseStr
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase();
};

export const convertSnakeToCamelCase = (snakeCaseStr: string): string => {
  return snakeCaseStr.toLowerCase().replace(/([_][a-z])/g, (value: string) => value.toUpperCase().replace('_', ''));
};

export const getEmailDomain = (email: string): string => {
  return email.split('@')[1];
};

export const getPaddedMetroId = (metroId: string): string => {
  const subStr = metroId.split('-');
  const cardNumberSuffix = subStr[3] ? `-${subStr[3]}` : '';
  return `${subStr[0].padStart(3, '0')}-${subStr[1].padStart(6, '0')}-${subStr[2].padStart(2, '0')}${cardNumberSuffix}`;
};

export const getPathString = (path: string) => {
  const pathSplit = path.split('/');
  return pathSplit[pathSplit.length - 1];
};

export const getIsGbrOrVerein = (companyType: CompanyType | null): boolean => {
  return companyType === CompanyType.GBR || companyType === CompanyType.VEREIN;
};

export const isInSmeFlow = () => {
  return !!(
    featureFlagsStatus.NEW_COMPANY_SELECTION ||
    (localStorage.getItem('smeFlag') && (import.meta.env.DEV || /get\.dev\.metro-fs\.com/.test(import.meta.url)))
  );
};

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export const objectToSelectItems = (object: { [key: string]: string | TranslateResult | undefined }) => {
  return Object.entries(object).map(([key, value]) => ({ text: value, value: key }));
};
