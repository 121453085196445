<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#d80027" d="M367.3 0H512v512H367.3l-29.7-257.3z" />
      <path fill="#6da544" d="M0 0h144.7l36 254.6-36 257.4H0z" />
      <path fill="#eee" d="M144.7 0h222.6v512H144.7z" />
      <path fill="#6da544" d="M189.2 256a66.8 66.8 0 1 0 133.6 0v-22.3H189.2z" />
      <path
        fill="#ff9811"
        d="M345 211.5h-66.7a22.3 22.3 0 1 0-44.6 0H167a23 23 0 0 0 22.2 22.2c0 12.6 10 22.3 22.3 22.3 0 12.3 10 22.3 22.2 22.3h44.6c12.3 0 22.2-10 22.2-22.3 12.3 0 22.3-10 22.3-22.3a22.9 22.9 0 0 0 22.2-22.2z"
      />
    </g>
  </svg>
</template>
