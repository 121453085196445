<template>
  <svg
    width="8px"
    height="14px"
    viewBox="0 0 8 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="chevron-left"
        d="M4.71472346,15.5248125 C4.42447346,15.2293125 4.42897346,14.7538125 4.72522346,14.4643125 L10.8482235,8.4643125 C11.1399735,8.1785625 11.6072235,8.1785625 11.8982235,8.4643125 L17.7752235,14.2235625 C18.0714735,14.5138125 18.0759735,14.9885625 17.7857235,15.2848125 C17.4954735,15.5803125 17.0214735,15.5840625 16.7252235,15.2953125 L11.3732235,10.0498125 L5.77522346,15.5353125 C5.62897346,15.6785625 5.43997346,15.7498125 5.25022346,15.7498125 C5.05522346,15.7498125 4.86097346,15.6748125 4.71472346,15.5248125 Z"
      ></path>
    </defs>

    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1099.000000, -1167.000000)">
        <g transform="translate(1091.500000, 1162.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <mask fill="white">
            <use xlink:href="#chevron-left"></use>
          </mask>
          <use
            fill="currentColor"
            transform="translate(11.250223, 11.999906) rotate(270.000000) translate(-11.250223, -11.999906) "
            xlink:href="#chevron-left"
          ></use>
        </g>
      </g>
    </g>
  </svg>
</template>
