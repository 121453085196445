import { type RouteRecordRaw } from 'vue-router';
import { onboardingV2RoutesNames } from './routeNames';

const NotSupportedCustomer = () => import('@/components/pages/onboarding-v2/sole-trader/NotSupportedCustomer.vue');
const NotSupportedNationality = () =>
  import('@/components/pages/onboarding-v2/sole-trader/NotSupportedNationality.vue');
const UsTaxResidency = () => import('@/components/pages/onboarding-v2/sole-trader/UsTaxResidency.vue');
const BusinessDetails = () => import('@/components/pages/onboarding-v2/sole-trader/BusinessDetails.vue');
const PersonalDetails = () => import('@/components/pages/onboarding-v2/sole-trader/PersonalDetails.vue');
const AddressDetails = () => import('@/components/pages/onboarding-v2/sole-trader/AddressDetails.vue');
const IdentityDetails = () => import('@/components/pages/onboarding-v2/sole-trader/IdentityDetails.vue');
const TaxDetails = () => import('@/components/pages/onboarding-v2/sole-trader/TaxDetails.vue');
const LegalDetails = () => import('@/components/pages/onboarding-v2/sole-trader/LegalDetails.vue');

/**
 * The router configuration. It uses standard Vue syntax. If you want to guard a route, use `meta` object.
 * Possible options are:
 * - `meta: { requiresAuth: true }` for routes that require authentication
 * - `meta: { allowFromName: [] }` for routes that can be accessed from the given names
 * - `meta: { requireFeatureFlagKey: string as keyof typeof featureFlagsNames }` for routes that can be accessed only if certain feature flag is enabled
 */
const onboardingSoleTrader: Array<RouteRecordRaw> = [
  {
    path: 'not-supported-customer',
    component: NotSupportedCustomer,
    name: onboardingV2RoutesNames.notSupportedCustomer,
    meta: { requiresAuth: true },
  },
  {
    path: 'us-tax-residency',
    component: UsTaxResidency,
    name: onboardingV2RoutesNames.usTaxResidency,
    meta: { requiresAuth: true },
  },
  {
    path: 'personal-details',
    component: PersonalDetails,
    name: onboardingV2RoutesNames.personalDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'address-details',
    component: AddressDetails,
    name: onboardingV2RoutesNames.addressDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'identity-details',
    component: IdentityDetails,
    name: onboardingV2RoutesNames.identityDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'not-supported-nationality',
    component: NotSupportedNationality,
    name: onboardingV2RoutesNames.notSupportedNationality,
    meta: { requiresAuth: true },
  },
  {
    path: 'tax-details',
    component: TaxDetails,
    name: onboardingV2RoutesNames.taxDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'business-details',
    component: BusinessDetails,
    name: onboardingV2RoutesNames.businessDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'legal-details',
    component: LegalDetails,
    name: onboardingV2RoutesNames.legalDetails,
    meta: { requiresAuth: true },
  },
];

export default onboardingSoleTrader;
