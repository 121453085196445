import { AccountRole } from '@/types/AccountRole';
import { CompanyType, IdType } from '@/types/OnboardingRequest';
import type {
  Address,
  AddressData,
  AionData,
  Company,
  IsAppView,
  IsFunctionalCookieEnabled,
  IsUpdateRegData,
  NextRoute,
  PersonalData,
  PrimaryRepCompany,
  PrimaryRepresentative,
  Representative,
  TaxResidencies,
  TeamCardsJwt,
  TermsDetails,
  Utm,
} from '@/types/OnboardingStore';

export const taxResidencies: TaxResidencies = [
  {
    country: 'DE',
    taxId: '',
  },
];

export const personalData: PersonalData = {
  isMetroCustomer: false,
  metroAccountId: undefined,
  firstName: '',
  lastName: '',
  salutation: '',
  email: '',
  phone: {
    countryCode: '49',
    number: '',
  },
  placeOfBirth: '',
  countryOfBirth: 'DE',
  incomeLevel: '',
  idOrigin: 'DE',
  idType: IdType.PASSPORT,
  isUsTaxResident: null,
  dateOfBirth: undefined,
  naceSelected: 'I3',
  taxResidencies: taxResidencies,
  title: undefined,
};

export const addressData: AddressData = {
  personalAddress: {
    streetName: '',
    houseNumber: '',
    postcode: '',
    city: '',
    country: 'DE',
  },
};

export const companyAddressData: Address = {
  streetName: '',
  houseNumber: '',
  postcode: '',
  city: '',
  country: 'DE',
};

export const company: Company = {
  isAddressSameAsPersonal: true,
  address: companyAddressData,
  startDate: '',
};

export const representative: Representative = {
  id: '',
  salutation: '',
  title: '',
  firstName: '',
  lastName: '',
  isRepresentative: false,
  isSignature: false,
  isUbo: false,
};

export const representatives: Representative[] = [];

export const primaryRepCompany: PrimaryRepCompany = {
  name: '',
  registrationNumber: '',
  registrationCourt: '',
  registrationDate: '',
  taxNumber: '',
  nace: 'I3',
  address: companyAddressData,
};

export const primaryRepresentative: PrimaryRepresentative = {
  isUsTaxResident: null,
  isRepresentative: null,
  salutation: '',
  firstName: '',
  lastName: '',
  dateOfBirth: undefined,
  cityOfBirth: '',
  countryOfBirth: 'DE',
  isUbo: null,
  address: addressData.personalAddress,
  idOrigin: 'DE',
  idType: IdType.PASSPORT,
  taxResidencies: taxResidencies,
  representatives: representatives,
  company: primaryRepCompany,
};

export const submittedScreens: Array<string> = [];

export const aionData: AionData = {
  referralId: null,
};

export const companyType: CompanyType | null = null;

export const termsDetails: Partial<TermsDetails> = {};

export const isUpdateRegData: IsUpdateRegData = false;
export const isAppView: IsAppView = false;
export const isFunctionalCookieEnabled: IsFunctionalCookieEnabled = true;
export const isLead = false;

export const nextRoute: NextRoute = null;

export const teamCardsJwt: TeamCardsJwt = '';

export const isRegActivation = false;

export const accountStatus = '';

export const accountRole = AccountRole.SOLETRADER;

export const optins: Array<string> = [];

export const utm: Utm = {
  source: null,
  medium: null,
  campaign: null,
  keyword: null,
  content: null,
  term: null,
};
