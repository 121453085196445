<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#eee" d="M0 0h512v512H0z" />
      <path fill="#d80027" d="M345 256c0 22.3-39.8 78-89 78s-89-55.7-89-78a89 89 0 1 1 178 0z" />
      <path fill="#0052b4" d="M345 256a89 89 0 1 1-178 0" />
      <path
        fill="#333"
        d="M350.4 334.7l23.7-23.6 15.7 15.7-23.6 23.6zm-39.3 39.4l23.6-23.7 15.7 15.8-23.6 23.6zm86.6 7.8l23.6-23.6L437 374l-23.6 23.7zm-39.4 39.4l23.6-23.6 15.8 15.7L374 437zm15.8-63l23.6-23.6 15.7 15.7-23.6 23.7zm-39.4 39.4l23.6-23.6 15.8 15.7-23.7 23.6zm63-220.4l-63-63 15.8-15.7 63 63zm-63-15.7l-23.6-23.7 15.7-15.7 23.7 23.6zm39.4 39.3l-23.7-23.6 15.8-15.7 23.6 23.6zm7.8-86.6l-23.6-23.6L374 75l23.7 23.6zm39.4 39.4L397.7 130l15.7-15.8L437 138zM90.7 358.3l63 63-15.8 15.7-63-63zm63 15.7l23.6 23.7-15.7 15.7-23.7-23.6zm-39.4-39.3l23.6 23.6-15.7 15.8-23.6-23.7zm23.6-23.6l63 63-15.7 15.7-63-63zm15.8-220.4l-63 63L75 137.9l63-63zm23.6 23.6l-63 63-15.7-15.8 63-63zm23.6 23.6l-63 63-15.7-15.7 63-63z"
      />
    </g>
  </svg>
</template>
