<template>
  <MfsDialog :is-opened="firestoreRequest.isError" @close="$emit('cancel-btn-click')" width="360" is-persistent>
    <MfsFont font-name="headline-2" tag="h2" color="primary-v2" class="mt-0 text-left">
      {{ t('firestoreError.heading') }}
    </MfsFont>

    <p class="m-0 pb-8">
      {{ t('firestoreError.text') }}
    </p>

    <div class="flex justify-center">
      <MfsButton
        id="back-button"
        class="mr-[18px] grow"
        :is-block="false"
        is-outlined
        :label="t('firestoreError.cancel')"
        @click="onCancel"
      />
      <MfsButton
        class="grow"
        id="continue-button"
        :is-block="false"
        :label="t('firestoreError.retry')"
        @click="onRetry"
      />
    </div>
  </MfsDialog>
</template>

<script setup lang="ts">
import MfsButton from '@/components/atoms/MfsButton.vue';
import MfsDialog from '@/components/atoms/MfsDialog.vue';
import MfsFont from '@/components/atoms/MfsFont.vue';
import { useFirestore } from '@/composables/useFirestore';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { firestoreRequest, onCancel, onRetry } = useFirestore();
</script>
