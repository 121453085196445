<template>
  <MfsMetroLogoBackground>
    <div class="mr-6 flex h-16 items-center justify-end sm:hidden">
      <MfsIcon
        id="btn-header-freshchat"
        :size="24"
        color="primary"
        icon-name="message"
        @click="openFreshChat"
        classes="cursor-pointer"
      />
    </div>

    <CoreLayoutV2 :is-with-sticky-header="false" :is-standalone-page="true" :hide-header-on-desktops="false">
      <template v-slot:body>
        <div class="mfs-container">
          <MfsGrid>
            <div class="md:text-center">
              <MfsHeader predefined-style="page-header">
                {{ t('homeEmail.heading') }}
              </MfsHeader>
            </div>
          </MfsGrid>

          <MfsGrid>
            <template v-slot:body>
              <p
                v-if="onboardingStore.isRegActivation"
                class="m-0 mb-3 font-lato font-bold text-primary-v2 md:text-center"
              >
                {{ t('homeEmail.descriptionActivation') }}
              </p>

              <MfsTextField
                data-cy="email-input"
                :id="emailInputId"
                type="email"
                v-model="onboardingStore.personalData.email"
                :label="t('common.inputLabel.emailLabel')"
                :is-disabled="isSubmitButtonDisabled"
                @keyup-enter="onContinueClick"
                :error-messages="v$.email.$errors[0]?.$message"
              />

              <div class="my-5">
                <MfsButton
                  data-cy="submit-btn"
                  id="btn-email-entry"
                  :label="t('homeEmail.buttonLabel')"
                  :is-disabled="isSubmitButtonDisabled"
                  :is-loading="isSubmitButtonDisabled"
                  @click="onContinueClick"
                />
              </div>

              <OnboardingTermsInfoAndDialog />

              <MfsError v-if="hasError" :error-message="String(errorMessage)" classes="mt-5 mb-5" />
            </template>
          </MfsGrid>
        </div>
      </template>
    </CoreLayoutV2>
  </MfsMetroLogoBackground>
</template>

<script setup lang="ts">
import MfsMetroLogoBackground from '@/components/atoms/background/MfsMetroLogoBackground.vue';
import MfsTextField from '@/components/atoms/input/MfsTextField.vue';
import MfsButton from '@/components/atoms/MfsButton.vue';
import MfsError from '@/components/atoms/MfsError.vue';
import MfsHeader from '@/components/atoms/MfsHeader.vue';
import MfsIcon from '@/components/atoms/MfsIcon.vue';
import OnboardingTermsInfoAndDialog from '@/components/organisms/OnboardingTermsInfoAndDialog.vue';
import CoreLayoutV2 from '@/components/templates/CoreLayoutV2.vue';
import MfsGrid from '@/components/templates/MfsGrid.vue';
import { useCommonPageFeats } from '@/composables/useCommonPageFeats';
import { emailLocalStorageKey, MethodType } from '@/constants';
import { indexRoutesNames, teamCardsRoutesNames } from '@/router/routeNames';
import { useCommonStore } from '@/stores/common';
import { useOnboardingStore } from '@/stores/onboarding';
import { Onboarding } from '@/types/ApiName';
import type { PersonalData } from '@/types/OnboardingStore';
import { otpEmailEntry } from '@/utils/analytics/errorEventNames';
import { otpEmailEntryEvents } from '@/utils/analytics/eventNames';
import { trackAnalytics, trackError } from '@/utils/analytics/sendAnalyticsUtil';
import { EventKeys, NameKeys, trackEvents } from '@/utils/analytics/trackEvents';
import { sendRequest } from '@/utils/axios';
import { getEmailDomain } from '@/utils/helpers';
import { fetchLeadsMetroUser, getLeadsTokenFromLs, removeLeadsTokenFromLs } from '@/utils/leadsSales';
import { validateFormSubmit } from '@/utils/validation';
import { validateEmail, validateRequired } from '@/validators';
import useVuelidate from '@vuelidate/core';
import type { AxiosRequestConfig } from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useI18n, type TranslateResult } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const commonStore = useCommonStore();
const onboardingStore = useOnboardingStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const { openFreshChat } = useCommonPageFeats();

const isSubmitButtonDisabled = ref<boolean>(false);
const hasError = ref<boolean>(false);
const errorMessage = ref<TranslateResult>(t('apiErrors.otpApiErrors.DEFAULT_MESSAGE'));
const email = computed(() => onboardingStore.personalData.email);

const emailInputId = 'email-input';

const rules = computed(() => ({
  email: {
    inputId: () => emailInputId,
    validateRequired,
    validateEmail,
  },
}));
const v$ = useVuelidate(rules, { email });

onMounted(() => {
  commonStore.basePageDetails = {
    ...commonStore.basePageDetails,
    headerTitle: t('homeEmail.heading'),
    isBackArrowVisible: false,
  };
  document.title = String(t('pageTitles.default'));
  createHomePageEssentials();

  if ('session-expired' in router.currentRoute.value.query) {
    errorMessage.value = t('apiErrors.firebaseErrors.SESSION_EXPIRED_MESSAGE');
    hasError.value = true;
  }
});

const createHomePageEssentials = async () => {
  const leadsToken = getLeadsTokenFromLs();

  if (leadsToken && !onboardingStore.personalData.metroAccountId) {
    try {
      const { data } = await fetchLeadsMetroUser(leadsToken);
      onboardingStore.setLeadsUserData(data);
    } catch {
      removeLeadsTokenFromLs();
    }
  }
};

const onContinueClick = () => {
  trackEvents(EventKeys.CONTINUE_CLICK, route.name, NameKeys.OTP_EMAIL_ENTRY_NEXT);

  validateFormSubmit(onEmailSubmit, route.name!, v$.value);
};

const onEmailSubmit = async () => {
  isSubmitButtonDisabled.value = true;

  try {
    await sendOtpRequest(onboardingStore.personalData);
  } catch (error: any) {
    if (error) {
      errorMessage.value = error;
    }
    hasError.value = true;
    isSubmitButtonDisabled.value = false;
  }
};

const sendOtpRequest = async (personalData: PersonalData) => {
  localStorage.setItem(emailLocalStorageKey, personalData.email);
  onboardingStore.personalData.email = personalData.email;
  onboardingStore.isRegActivation = false;

  trackAnalytics(otpEmailEntryEvents.triggerOtp, {
    'trigger-otp': true,
    'email-domain-name': getEmailDomain(personalData.email),
  });

  const request: AxiosRequestConfig = {
    method: MethodType.POST,
    url: Onboarding.AUTH_GENERATE_OTP,
    data: {
      email: personalData.email,
    },
  };

  try {
    const response = await sendRequest<{ exists: boolean }>(request);

    if (response.data?.exists === true) {
      trackAnalytics(otpEmailEntryEvents.otpApiResponse, {
        'otp-api-response': 'User exists without password/OTP',
      });
    } else if (response.data?.exists === false) {
      trackAnalytics(otpEmailEntryEvents.otpApiResponse, {
        'otp-api-response': 'New user',
      });
    }

    router.push({ name: indexRoutesNames.otpEntry });
  } catch (error: any) {
    const errorStatus = error?.response?.status;
    const errorCode = error?.response?.data?.code;

    switch (errorStatus) {
      case 409:
        if (errorCode === 'TEAMCARD_NEEDS_PASSWORD_AUTHENTICATION') {
          trackAnalytics(otpEmailEntryEvents.otpApiResponse, {
            'otp-api-response': 'Teamcard user exists with password',
          });
          router.push({ name: teamCardsRoutesNames.teamCardsAppDownload });
        } else {
          trackAnalytics(otpEmailEntryEvents.otpApiResponse, {
            'otp-api-response': 'User exists with password',
          });
          router.push({ name: indexRoutesNames.login });
        }
        break;
      case 422:
        if (errorCode === 'TEAMCARD_PASSWORD_NOT_SET') {
          trackAnalytics(otpEmailEntryEvents.otpApiResponse, {
            'otp-api-response': 'Teamcard user password not set',
          });
          router.push({ name: teamCardsRoutesNames.teamCardsEmailSent });
          break;
        } else {
          trackError(otpEmailEntry.payloadValidation, error);
          throw new Error(t('apiErrors.otpApiErrors.PAYLOAD_VALIDATION_FAILED').toString());
        }
      case 429:
        trackError(otpEmailEntry.tooManyRequests, error);
        router.push({ name: indexRoutesNames.otpEntry });
        break;
      default:
        trackError(otpEmailEntry.general, error);
        throw new Error();
    }
  }
};
</script>
