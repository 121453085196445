<template>
  <svg
    width="56px"
    height="56px"
    viewBox="0 0 56 56"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-636.000000, -745.000000)">
        <g id="Group-2" transform="translate(420.000000, 96.000000)">
          <g id="Group" transform="translate(88.000000, 478.000000)">
            <g id="Group-13" transform="translate(0.000000, 173.000000)">
              <g id="Group-7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                <g id="Group" transform="translate(133.000000, 0.000000)">
                  <line x1="15.326087" y1="46.9746033" x2="47" y2="46.9746033" stroke="#0064FE"></line>
                  <line x1="20.4347826" y1="42.8730163" x2="20.4347826" y2="32.619049" stroke="#0064FE"></line>
                  <line x1="26.5652174" y1="42.8730163" x2="26.5652174" y2="32.619049" stroke="#0064FE"></line>
                  <line x1="36.7826087" y1="42.8730163" x2="36.7826087" y2="32.619049" stroke="#0064FE"></line>
                  <line x1="42.9130435" y1="42.8730163" x2="42.9130435" y2="32.619049" stroke="#0064FE"></line>
                  <line x1="18.3913043" y1="32.619049" x2="28.6086957" y2="32.619049" stroke="#0064FE"></line>
                  <line x1="34.7391304" y1="32.619049" x2="44.9565217" y2="32.619049" stroke="#0064FE"></line>
                  <polygon
                    stroke="#002D72"
                    points="0 8.00952728 20.4347826 8.00952728 20.4347826 22.3650816 0 22.3650816"
                  ></polygon>
                  <path
                    d="M3.46573913,8.00952728 L5.01469565,0.807140604 C5.1349152,0.253872616 5.67925849,-0.0968559767 6.23056522,0.0237374969 L24.0823913,3.9202451 C24.632708,4.04092636 24.9818856,4.5858321 24.863,5.13841642 L22.602913,15.579006 C22.4826623,16.1312927 21.9397002,16.4817203 21.389087,16.3624091 L20.4347826,16.1573297"
                    stroke="#002D72"
                  ></path>
                  <path
                    d="M7.15217391,15.1873044 C7.15217391,16.2863214 7.73640102,17.3018549 8.68478259,17.8513634 C9.63316416,18.4008718 10.8016184,18.4008718 11.75,17.8513634 C12.6983816,17.3018549 13.2826087,16.2863214 13.2826087,15.1873044 C13.2826087,14.0882875 12.6983816,13.072754 11.75,12.5232455 C10.8016184,11.973737 9.63316416,11.973737 8.68478259,12.5232455 C7.73640102,13.072754 7.15217391,14.0882875 7.15217391,15.1873044 L7.15217391,15.1873044 Z"
                    stroke="#002D72"
                  ></path>
                  <path
                    d="M25.5353043,19.2888914 L31.0996957,15.5667012 C31.4425688,15.3377187 31.8889094,15.3377187 32.2317826,15.5667012 L46.5361304,25.1357036 C46.8206341,25.3257048 46.9918261,25.6458711 46.9918261,25.9888337 L46.9918261,27.4920653 C46.9918261,28.0583763 46.5343779,28.517462 45.970087,28.517462 L17.3613913,28.517462 C16.7971004,28.517462 16.3396522,28.0583763 16.3396522,27.4920653"
                    stroke="#0064FE"
                  ></path>
                </g>
              </g>
              <g transform="translate(133.000000, 40.000000)"></g>
            </g>
            <g transform="translate(128.000000, 0.000000)"></g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
