<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="checkbox-off"
        d="M11.75.25a2 2 0 012 2v9.5a2 2 0 01-2 2h-9.5a2 2 0 01-2-2v-9.5c0-1.1.9-2 2-2h9.5zM12 1H2a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V2a1 1 0 00-1-1z"
      />
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="14" height="14"></rect>
      <mask fill="white">
        <use xlink:href="#checkbox-off"></use>
      </mask>
      <use fill="currentColor" xlink:href="#checkbox-off"></use>
    </g>
  </svg>
</template>
