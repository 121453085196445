import { MFS_COBROWSE_API, MFS_COBROWSE_LICENSE } from '@/env';

export type cobrowseCustomData = {
  user_id?: string | null;
  user_name?: string | null;
  user_email?: string | null;
  device_id?: string | null;
  device_name?: string | null;
};

export function startCobrowse() {
  //@ts-ignore
  CobrowseIO.api = MFS_COBROWSE_API;
  //@ts-ignore
  CobrowseIO.license = MFS_COBROWSE_LICENSE;
  //@ts-ignore
  CobrowseIO.start();
}

export function setCustomDataCobrowse(customData: cobrowseCustomData) {
  //@ts-ignore
  CobrowseIO.customData = {
    user_id: null,
    user_name: null,
    user_email: null,
    device_id: null,
    device_name: null,
    ...customData,
  };
}
