<template>
  <component
    :is="tagComponent"
    :class="[
      'whitespace-pre-line sm:whitespace-normal',
      {
        'transition duration-300 ease-in-out': withTransition,
        '!whitespace-pre-line': isLineBreakEnabled,
      },
      {
        'font-metro font-bold leading-normal text-primary-v2': predefinedStyle === 'helper-header',
        'font-metro text-double font-extrabold leading-page-header tracking-page-header text-primary-v2 md:text-center':
          predefinedStyle === 'page-header' || predefinedStyle === 'onb-page-header',
        'font-lato font-bold leading-normal text-primary-v2': predefinedStyle === 'input-helper-header',
        'font-metro text-2xl font-extrabold leading-normal text-primary-v2': predefinedStyle === 'section-header',
      },
      {
        '!mb-8': predefinedStyle === 'page-header',
        'mb-3 lg:!mb-4': predefinedStyle === 'onb-page-header',
        'mb-3': predefinedStyle === 'input-helper-header' || predefinedStyle === 'section-header',
      },
    ]"
  >
    <slot></slot>
  </component>
</template>

<script lang="ts">
const predefinedStylesList: Array<string> = [
  'helper-header',
  'page-header',
  'onb-page-header',
  'section-header',
  'input-helper-header',
];
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  predefinedStyle: {
    type: String,
    default: null,
    validator: (value: string) => {
      const isStyleAllowed = predefinedStylesList.indexOf(value) > -1;

      if (!isStyleAllowed) {
        console.info(
          `The font style ${value} you have chosen is not allowed, take one of these:`,
          predefinedStylesList
        );
      }

      return isStyleAllowed;
    },
  },
  hTagNo: {
    type: Number,
    default: 1,
    validator: (value: number) => value <= 6,
  },
  isLineBreakEnabled: {
    type: Boolean,
    default: false,
  },
  withTransition: {
    type: Boolean,
    default: false,
  },
});

const tagComponent = computed(() => {
  return `h${props.hTagNo}`;
});
</script>
