<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#ffda44" d="M0 0l214 251.8L0 512l418-256z" />
      <path fill="#d80027" d="M512 0H0l367.3 256L0 512h512z" />
      <path fill="#333" d="M0 0v512l256-256z" />
      <path fill="#eee" d="M71 197.4l39 36.8 47-25.6-23 48.4 39 36.9-53.2-7-23 48.5-9.9-52.7-53.2-7 47.1-25.6z" />
    </g>
  </svg>
</template>
