<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#eee" d="M90.1 144.8l210.5-27.7L512 144.8v222.6l-209 32.4-213-32.4z" />
      <path fill="#333" d="M0 0h512v144.8H28.2z" />
      <path fill="#496e2d" d="M39.5 367.4H512V512H0z" />
      <path
        fill="#d80027"
        d="M0 .1V512h.1L256 256.1 0 .1zm365.1 178.1a78 78 0 1 0 18.9 154 78 78 0 0 1 0-152.2 78.2 78.2 0 0 0-18.9-1.8z"
      />
      <path fill="#d80027" d="M387.3 206.1l11 34h35.8l-29 21 11.1 34-29-21-28.9 21 11.1-34-29-21h35.8z" />
    </g>
  </svg>
</template>
