<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#0052b4" d="M256 0h256v512H0V256z" />
      <path fill="#496e2d" d="M445.2 256.1zm-155.8 0z" />
      <path
        fill="#eee"
        d="M433 293.6a62.4 62.4 0 0 0 12.2-37.5V144.8a55.4 55.4 0 0 1-33.4 11.1 55.6 55.6 0 0 1-44.5-22.2 55.6 55.6 0 0 1-44.5 22.2 55.4 55.4 0 0 1-33.4-11.1v111.3c0 15 5 27.3 12.3 37.5h131.2z"
      />
      <path
        fill="#ff9811"
        d="M409.8 235.5a91 91 0 0 0 6.3-27.6c0-10.1-13.2-18.3-13.2-18.3s-13.2 8.2-13.2 18.3a91 91 0 0 0 6.3 27.6l-7.6 17.1a38.3 38.3 0 0 0 29 0zm-51.5-55.6a91 91 0 0 0-27 8.3c-8.8 5-9.3 20.5-9.3 20.5s13.7 7.4 22.4 2.3c5.5-3.1 15-11.8 20.8-19.2l18.6-2a38.4 38.4 0 0 0-4.7-14 38.4 38.4 0 0 0-9.7-11.1zm-22.4 72.2a91 91 0 0 0 20.7 19.3c8.8 5 22.5-2.3 22.5-2.3s-.6-15.5-9.3-20.5a91 91 0 0 0-27-8.4l-11.1-15.1a38.4 38.4 0 0 0-9.7 11 38.4 38.4 0 0 0-4.8 14z"
      />
      <path fill="#338af3" d="M299 289.5c20.7 33.3 68.3 44.5 68.3 44.5s47.6-11.2 68.4-44.5H298.9z" />
      <path
        fill="#eee"
        d="M256 0H100.2L66.7 14.9 33.4 0H0v33.4l15.7 31.3L0 100.2V256h33.4l34-13.6 32.8 13.6h33.4v-75.2l75.2 75.2H256l-7.7-21.3 7.7-10.2v-15.7l-75.2-75.2H256v-33.4l-11.8-33.4L256 33.4z"
      />
      <path fill="#d80027" d="M256 224.5l-91-91h-31.4L256 256z" />
      <path fill="#d80027" d="M33.4 0v33.4H0v66.8h33.4V256h66.8V100.2H256V33.4H100.2V0z" />
    </g>
  </svg>
</template>
