import { MethodType } from '@/constants';
import { Onboarding } from '@/types/ApiName';
import type { Phone } from '@/types/OnboardingStore';
import { type AxiosRequestConfig } from 'axios';
import { sendRequest } from './axios';

export type RetrievedUser = {
  firstName: string;
  lastName: string;
  email: string;
  phone: Phone;
  metroAccountId: string;
  isLead: boolean;
};

const lsKeyName = 'leadsSalesToken';

export async function fetchLeadsMetroUser(token: string): Promise<{ data: RetrievedUser }> {
  const request: AxiosRequestConfig = {
    method: MethodType.GET,
    url: Onboarding.LEADS_RETRIEVE_API,
    params: { token },
  };

  return await sendRequest<RetrievedUser>(request);
}

export function setTokenInLs(token: string) {
  localStorage.setItem(lsKeyName, token);
}

export function getLeadsTokenFromLs(): string | null {
  return localStorage.getItem(lsKeyName);
}

export function removeLeadsTokenFromLs() {
  return localStorage.removeItem(lsKeyName);
}

export async function getLeadData(email: string, token: string) {
  const request: AxiosRequestConfig = {
    method: MethodType.GET,
    url: Onboarding.LEADS_EMAIL_RETRIEVE,
    params: { email },
  };

  try {
    const { data } = await sendRequest<RetrievedUser>(request, token);

    return {
      ...data,
      isLead: true,
    };
  } catch (error: any) {
    // errors aren't user facing/affecting user flow so we just exit
    return;
  }
}
