<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#d80027" d="M0 256L256 0h256v512H0z" />
      <path fill="#0052b4" d="M256 256V0H0v256z" />
      <path
        fill="#eee"
        d="M222.6 149.8l-31.3 14.7 16.7 30.3-34-6.5-4.3 34.3-23.6-25.2-23.7 25.2-4.3-34.3-34 6.5 16.7-30.3-31.2-14.7 31.2-14.7-16.6-30.3 34 6.5 4.2-34.3 23.7 25.3L169.7 77l4.3 34.3 34-6.5-16.7 30.3z"
      />
      <circle cx="146.1" cy="149.8" r="47.7" fill="#0052b4" />
      <path fill="#eee" d="M146 175.2a25.4 25.4 0 1 1 .2-50.8 25.4 25.4 0 0 1-.1 50.8z" />
    </g>
  </svg>
</template>
