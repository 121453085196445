import { convertPascalToKebabCase } from '../helpers';
import { PageContext, getPageKey } from './getPageKey';
import { trackAnalytics } from './sendAnalyticsUtil';

const CLICKED = 'clicked';

const onPage = (page: PageContext) => `On ${page} Page`;

export enum ConstKeys {
  BACK_CLICK = 'Click Back',
  FRESHCHAT_CLICK = 'Click Freshchat',
  CONTINUE_CLICK = 'Click Continue',
  LOGOUT_CLICK = 'Click Logout',
}

// These are responsible for mapping events in posthog. Handle with care!
export enum NameKeys {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'click-forgot-password',
  RESET_PASSWORD = 'click-reset',
  CONFIRM_PASSWORD = 'click-confirm-password',
  SUCCESS_PASSWORD_CHANGE = 'success-password-change',
  PASSWORD_ENTRY_CONTINUE = 'password-entry-continue-click',
  OTP_ENTRY_TIME = 'otp-entry-time',
  OTP_SUBMIT_TRIGGER = 'otp-submit-trigger',
  OTP_RESEND = 'resend-otp-click',
  OTP_RESEND_TRIGGER = 'resend-otp-trigger',
  OTP_API_RESPONSE = 'otp-api-response',

  OTP_RESEND_API_RESPONSE = 'resend-otp-api-response',
  OTP_EMAIL_ENTRY_NEXT = 'otp-email-entry-continue-click',
  CHANGE_EMAIL = 'change-email-click',
  SOLETRADER_SELECT_NEXT = 'soletrader-selection-continue-click',
  EMAIL_NOTIFICATION_ACTIVATE_CLICK = 'company-reminder-email-activation-click',
  EMAIL_NOTIFICATION_ACTIVATED_SUCCESSFULLY = 'not-supported-company-email-success',
  IS_SOLETRADER = 'is-soletrader',
  IS_US_TAX_RESIDENT = 'is-us-tax-residency',
  SELECTED_NACE = 'selected-nace',
  BUSINESS_DETAILS_NEXT = 'business-details-continue-click',
  ADDRESS_DETAILS_NEXT = 'address-details-continue-click',
  NATIONALITY_NOTIFICATION_ACTIVATE_CLICK = 'nationality-reminder-email-activation-click',
  NATIONALITY_NOTIFICATION_ACTIVATED_SUCCESSFULLY = 'nationality-reminder-email-success',

  US_TAX_RESIDENT_SELECT_NEXT = 'us-tax-residency-selection-continue-click',
  IDENTITY_DETAILS_NEXT = 'identity-details-continue-click',
  TAX_DETAILS_NEXT = 'tax-details-continue-click',
  USER_OFFLINE_ONBOARDED = 'user-offline-onboarded',
  SELECTED_PHONE_COUNTRY_CODE = 'selected-phone-country-code',
  PHONE_NUMBER_NEXT = 'phone-number-continue-click',
  SWIPE_ON_SLIDE = 'swipe-on-slide',
  CLICK_ON_SLIDE_NUMBER = 'click-on-slide-number',
  SELECTED_LEGAL_FORM = 'selected-legal-form',
  METRO_NUMBER_TOOLTIP = 'metro-number-tooltip',
  SELECTED_ID_DOCUMENT_TYPE = 'selected-document-type',
  SELECTED_TITLE = 'selected-title',
  SELECTED_SALUTATION = 'selected-salutation',
  PERSONAL_DATA_NEXT = 'personal-data-continue-click',
  LEGAL_DETAILS_NEXT = 'legal-details-continue-click',
  LEGAL_CHECKBOX_SCHUFA = 'schufa-checkbox-selected',
  LEGAL_CHECKBOX_SELFACT = 'selfact-checkbox-selected',
  LEGAL_CHECKBOX_MARKETING = 'marketing-consent',

  // TEAMCARDS
  TEAMCARDS_SWIPE_ON_SLIDE = 'swipe-on-slide-on-team-cards',
  TEAMCARDS_CLICK_ON_SLIDE_NUMBER = 'click-on-slide-number-on-team-cards',
  TEAMCARDS_CLICK_SEND_NEW_EMAIL = 'click-send-new-team-cards-email',
  TEAMCARDS_SUCCESS_SEND_NEW_EMAIL = 'success-new-team-cards-email-sent',
  TEAMCARDS_PASSWORD_ENTRY_NEXT = 'team-cards-password-entry-continue-click',
  TEAMCARDS_SELECT_TERMS_CHECKBOX = 'team-cards-terms-checkbox-selected',
  TEAMCARDS_METRO_LOGO_CLICK = 'click-on-metro-logo-on-team-cards',
  TEAMCARDS_START_NEXT = 'click-continue-on-team-cards-start-page',
}

// EventKeys correspond to all the keys in the eventsMap Object below
export enum EventKeys {
  FRESHCHAT_CLICK,
  CONTINUE_CLICK,
  BACK_CLICK,
  LOGOUT_BUTTON_CLICK,
  LOGOUT_CONFIRM,
}

export const eventsMap: { [key in EventKeys]: (value?: any, options?: any) => void } = {
  [EventKeys.FRESHCHAT_CLICK]: (page) => {
    const eventName = `${ConstKeys.FRESHCHAT_CLICK} ${onPage(getPageKey(page))}`;
    const eventKey = `freshchat-${convertPascalToKebabCase(page)}-click`;
    trackAnalytics(eventName, { [eventKey]: CLICKED });
  },
  [EventKeys.CONTINUE_CLICK]: (page, eventKey) => {
    const eventName = `${ConstKeys.CONTINUE_CLICK} ${onPage(getPageKey(page))}`;
    trackAnalytics(eventName, { [eventKey]: CLICKED });
  },
  [EventKeys.BACK_CLICK]: (currentPage, previousPage) => {
    const eventName = `${ConstKeys.BACK_CLICK} To ${getPageKey(previousPage)} From ${getPageKey(currentPage)}`;
    const eventKey = eventName
      .split('Click ')[1]
      .split(' From')[0]
      .split(/(?=[A-Z])/)
      .join('-')
      .replace(/\s/g, '')
      .toLowerCase();

    trackAnalytics(eventName, { [eventKey]: CLICKED });
  },
  [EventKeys.LOGOUT_BUTTON_CLICK]: (path, page) => {
    const eventKey = `logout-${path}-click`;
    const eventName = `${ConstKeys.LOGOUT_CLICK} ${onPage(getPageKey(page))}`;
    trackAnalytics(eventName, { [eventKey]: CLICKED });
  },
  [EventKeys.LOGOUT_CONFIRM]: (path) => {
    const eventName = `${ConstKeys.CONTINUE_CLICK} On ${PageContext.LOGOUT}`;
    const eventKey = `continue-logout-${path}-click`;
    trackAnalytics(eventName, { [eventKey]: CLICKED });
  },
};

export const trackEvents = (eventType: EventKeys, value?: any, options?: any) => {
  const fn = eventsMap[eventType];
  fn ? fn(value, options) : console.error('Unknown tracking event type:', eventType);
};
