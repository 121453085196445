<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#eee" d="M0 0l219.4 257.8L0 512h47.2L512 322.7v-33.3l-28.5-34 28.5-32.8v-33.3L47.2 0z" />
      <path fill="#333" d="M0 141.8v228.4l139.5-114.7z" />
      <path fill="#ffda44" d="M199.6 255.5L0 94.5v47.3L114 256 0 370.2v47.3z" />
      <path fill="#6da544" d="M512 222.6H222.6L0 0v94.4L161.2 256 0 417.5V512l222.7-222.6H512z" />
      <path fill="#d80027" d="M512 0H47.2l189.2 189.3H512z" />
      <path fill="#0052b4" d="M512 512H47.2l189.2-189.3H512z" />
    </g>
  </svg>
</template>
