import { MFS_BV2_API_BASE_URI } from '@/env';
import axios, { type AxiosRequestConfig, type AxiosResponse, type RawAxiosRequestHeaders } from 'axios';

/**
 * Sends axios request to the given endpoint with the set request details.
 */
export async function sendRequest<T>(request: AxiosRequestConfig, authToken?: string): Promise<AxiosResponse<T>> {
  const headers: RawAxiosRequestHeaders = request.headers ?? {};

  if (authToken) {
    headers.Authorization = 'Bearer ' + authToken;
  }

  const config: AxiosRequestConfig = {
    method: request.method,
    url: `${MFS_BV2_API_BASE_URI}/${request.url}`,
    data: request.data,
    headers,
    timeout: request.timeout,
    params: request.params,
    responseType: request.responseType,
  };

  return await axios(config);
}
