import { onAuthStateChangedTrigger, signOutTrigger } from '@/service/firebase';
import type { CommonStoreState } from '@/types/CommonStore';
import { defineStore } from 'pinia';

export const useCommonStore = defineStore('common', {
  state: (): CommonStoreState => {
    return {
      basePageDetails: {
        topHeaderTitle: null,
        headerTitle: null,
        infoText: null,
        step: 1,
        stepsCount: 5,
        isBackArrowVisible: true,
        previousRoute: undefined,
        isHeaderTitleVisible: true,
        isHeaderLogoVisible: false,
        isProgressBarVisible: true,
        sideBarTitle: null,
        stepsDescription18nKey: null,
      },
      isAuthStatusLoading: true,
      isAuthenticated: false,
      currentUser: null,
    };
  },

  actions: {
    async checkActiveSessionAndGetCurrentUser() {
      return new Promise((resolve) => {
        onAuthStateChangedTrigger(async (user) => {
          if (user) {
            this.currentUser = user;
            this.isAuthenticated = true;
            this.isAuthStatusLoading = false;
            user.getIdToken(true);
          }
          resolve(user);
          this.isAuthStatusLoading = false;
        });
      });
    },
    async logOut() {
      this.currentUser = null;
      this.isAuthenticated = false;
      await signOutTrigger();
    },
  },
});
