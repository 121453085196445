<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#0052b4" d="M256 .1h256V512H0V256z" />
      <path
        fill="#eee"
        d="M154.4 300.4l14 29.4 31.8-7.3-14.2 29.3 25.5 20.2-31.8 7.2.1 32.5-25.4-20.3-25.4 20.3v-32.5L97.4 372l25.5-20.2-14.2-29.3 31.7 7.3zm228.9 55.7l7 14.7 15.9-3.7-7.1 14.6 12.7 10.2-15.9 3.5v16.3l-12.6-10.2-12.7 10.2v-16.3l-15.9-3.5 12.8-10.2-7.1-14.6 15.9 3.7zm-65.4-155.8l7 14.7 16-3.6-7.2 14.6 12.8 10-16 3.7.1 16.3-12.7-10.2-12.7 10.2v-16.3l-15.8-3.6 12.7-10-7-14.7 15.8 3.6zm65.4-89l7 14.7 15.9-3.7L399 137l12.7 10-15.9 3.7V167l-12.6-10.2-12.7 10.2v-16.3l-15.9-3.6 12.8-10.1-7.1-14.7 15.9 3.7zm57 66.8l7 14.7 16-3.7-7.1 14.7 12.7 10-15.9 3.7v16.2l-12.6-10.1-12.7 10v-16.1l-15.9-3.6 12.8-10.1-7.1-14.7 15.8 3.7zM399.6 256l5.6 17H423l-14.5 10.4 5.5 17-14.5-10.5-14.4 10.5 5.5-17-14.5-10.5h18zM256 .1H100.2L66.7 15 33.4 0H0v33.4l15.7 31.3L0 100.3v155.8h33.4l34-13.6 32.8 13.6h33.4v-75.2l75.2 75.2H256l-7.7-21.3 7.7-10.2v-15.7l-75.2-75.2H256v-33.4l-11.8-33.4L256 33.5z"
      />
      <path fill="#d80027" d="M256 224.6l-91-91h-31.4L256 256.1z" />
      <path fill="#d80027" d="M33.4.1v33.4H0v66.8h33.4v155.8h66.8V100.3H256V33.5H100.2V.1z" />
    </g>
  </svg>
</template>
