<template>
  <svg
    width="15px"
    height="18px"
    viewBox="0 0 15 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="arrow-down"
        d="M12.0015,3 C12.4155,3 12.7515,3.33525 12.7515,3.75 L12.7515,18.4395 L18.222,12.96825 C18.51525,12.675 18.98925,12.675 19.2825,12.96825 C19.42875,13.1145 19.50225,13.3065 19.50225,13.4985 C19.50225,13.6905 19.42875,13.8825 19.2825,14.02875 L12.53175,20.78025 C12.46275,20.8485 12.381,20.904 12.29025,20.9415 C12.1995,20.97975 12.10125,21 12.0015,21 C11.901,21 11.80275,20.97975 11.712,20.9415 C11.62125,20.904 11.5395,20.8485 11.4705,20.78025 L4.71975,14.02875 C4.4265,13.7355 4.4265,13.2615 4.71975,12.96825 C5.013,12.675 5.487,12.675 5.78025,12.96825 L11.2515,18.4395 L11.2515,3.75 C11.2515,3.33525 11.58675,3 12.0015,3"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1000.000000, -1165.000000)">
        <g transform="translate(995.500000, 1162.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <mask fill="white">
            <use xlink:href="#arrow-down"></use>
          </mask>
          <use fill="currentColor" xlink:href="#arrow-down"></use>
        </g>
      </g>
    </g>
  </svg>
</template>
