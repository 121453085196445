<template>
  <div class="grid h-[64px] grid-cols-[50%_50%] content-center bg-white pl-6 pr-4 pt-0">
    <a href="https://metro-fs.com/">
      <MfsLogoMetro class="relative z-[1000] justify-self-start" />
    </a>

    <MfsIcon
      :size="24"
      color="primary"
      icon-name="message"
      @click="openFreshChat"
      classes="cursor-pointer justify-self-end sm:hidden"
    />
  </div>
  <FreshChatInfoDialog :isOpened="isFreshChatDialogOpen" @close="toggleFreshChatDialog" />
</template>

<script setup lang="ts">
import MfsIcon from '@/components/atoms/MfsIcon.vue';
import MfsLogoMetro from '@/components/atoms/MfsLogoMetro.vue';
import FreshChatInfoDialog from '@/components/organisms/FreshChatInfoDialog.vue';
import { useCommonPageFeats } from '@/composables/useCommonPageFeats';

const { isFreshChatDialogOpen, toggleFreshChatDialog, openFreshChat } = useCommonPageFeats();
</script>
