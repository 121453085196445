import type { Utm } from '@/types/OnboardingStore';
import { getMobileOS, MOBILE_PLATFORMS } from './detectMobileOs';
import { getLeadsTokenFromLs } from './leadsSales';
import { utm as utmParams } from './onboardingStore';
/*
   types
 */

type cookieParamObj = {
  cookieName: string;
  value: string;
};

type utmQueryParamsObject = {
  [key: string]: string | null;
};

/*
   enums
 */

// functional cookie status
export enum Functional {
  TRUE = 'true',
  FALSE = 'false',
}

// statistics cookie status
export enum Statistics {
  TRUE = 'true',
  FALSE = 'false',
}

// third Party cookie status
export enum ThirdParty {
  TRUE = 'true',
  FALSE = 'false',
}

/*
   constants
 */

export const UTM_CAMPAIGN_COOKIE = {
  COOKIE_NAME: 'campaign_url',
  COOKIE_AGE: 2628000,
  PARAM_PREFIX: 'utm_',
  LEADS_SOURCE_NAME: 'metro_de',
  LEADS_MEDIUM_NAME: 'salesforce',
  PARAMS_TO_FIND: [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'utm_keyword',
    'utm-referral',
  ],
};

/*
   functions
 */

/**
 * Checks if wanted cookie and it's value is present in the browser.
 * @param {Object} cookie - Cookie object to check.
 * @param {String} employee.cookieName - The name of the cookie.
 * @param {String} employee.value - The cookie's value.
 * @returns {Boolean} boolean true of false
 */
export function isCookieSet({ cookieName, value }: cookieParamObj): boolean {
  const cookies = document.cookie.split(`;`);
  return cookies.some((cookie) => cookie.trim() === `${cookieName}=${value}`);
}

/**
 * Returns cookie value
 * @param {String} cookieName - The name of the cookie.
 * @returns {String} return string with cookie's value if cookie is found.
 * @returns {Undefined} return undefined if required cookie is not found.
 */
export function returnCookieValue(cookieName: string): string | undefined {
  const cookies = document.cookie.split(`;`);
  const cookieToFind = cookies.find((row) => row.includes(cookieName));

  if (cookieToFind) {
    return cookieToFind.split('=')[1];
  }
  return undefined;
}

/**
 * Returns allowed UTM params from Cookie in an object
 * @param {String} codedUri - coded Uri parameters to extract utm parameters from.
 * @returns {Object} return object with key pairs made of utm parameters.
 */
export function returnUtmParamsFromCookie(codedUri: string): object {
  const utmQueryParams: utmQueryParamsObject = {};
  const decodedUri = decodeURIComponent(codedUri);
  const utmPairs = decodedUri.replace(/^.*?(?=\?)\?/, '').split('&');
  const allowedUtmParams = Object.keys(utmParams);

  utmPairs.forEach((singleUtm) => {
    const pair = singleUtm.split('=');
    const extractedQueryParam = pair[0].split(UTM_CAMPAIGN_COOKIE.PARAM_PREFIX)[1];
    const isQueryParamAllowed = allowedUtmParams.includes(extractedQueryParam);

    if (isQueryParamAllowed) {
      utmQueryParams[extractedQueryParam] = pair[1] || null;
    }
  });

  return utmQueryParams;
}

/**
 * 1) check for UTM params in URL
 * 2) if no UTM params are found - check for document.referrer
 * 3) save output of 1 or 2
 * @returns {Undefined}
 */
export function checkForUtmParamsAndRefAndSaveUrl(): void {
  const queryString = window.location.search;
  let queryParams: Array<string> = [];

  if (queryString.length) {
    queryParams = queryString.slice(1, queryString.length).split('&');
  } else if (document.referrer) {
    queryParams = ['utm_source: referral', `utm_medium=${new URL(document.referrer).hostname}`];
  }

  if (queryParams.filter((pair) => UTM_CAMPAIGN_COOKIE.PARAMS_TO_FIND.indexOf(pair.split('=')[0]) > -1).length) {
    document.cookie = `${UTM_CAMPAIGN_COOKIE.COOKIE_NAME}=${encodeURIComponent(
      `${window.location.origin}/?${queryParams.join('&')}`
    )}; max-age=${UTM_CAMPAIGN_COOKIE.COOKIE_AGE}; path=/; SameSite=Lax;`;
  }
}

// Sets utm params for the web view using cookie as the source
export function getUtmParams(onbUtm: Utm): Utm {
  // extracting and setting UTM params if present from last session
  let utm: Utm = onbUtm ?? utmParams;

  const campaignUrl = returnCookieValue(UTM_CAMPAIGN_COOKIE.COOKIE_NAME);
  if (campaignUrl) {
    const campaignUtm: Utm = utmParams;

    // append utm params for each entry with campaign utm params e.g. source1,source2,...
    Object.entries(utm).forEach(([key, val]) => {
      const utmParamsFromCookie = returnUtmParamsFromCookie(campaignUrl);
      if (key in utmParamsFromCookie) {
        //@ts-ignore
        campaignUtm[key] = val ? `${val},${utmParamsFromCookie[key]}` : utmParamsFromCookie[key];
      }
    });

    // update utm object with utm params from campaign url
    utm = {
      ...utm,
      ...campaignUtm,
    };
  }

  // append utm params with leads sales
  if (getLeadsTokenFromLs()) {
    utm = {
      ...utm,
      source: utm.source
        ? `${utm.source},${UTM_CAMPAIGN_COOKIE.LEADS_SOURCE_NAME}`
        : UTM_CAMPAIGN_COOKIE.LEADS_SOURCE_NAME,
      medium: utm.medium
        ? `${utm.medium},${UTM_CAMPAIGN_COOKIE.LEADS_MEDIUM_NAME}`
        : UTM_CAMPAIGN_COOKIE.LEADS_MEDIUM_NAME,
    };
  }

  return utm;
}

// Sets utm params for the app-view
export function getInAppUtmParams(onbUtm: Utm): Utm {
  let utm = getUtmParams(onbUtm);
  let source: string | null = utm.source; // This will stored as "source1,source2,..."
  let medium: string | null = utm.medium; // This will stored as "medium1,medium2,..."

  const ios = 'ios';
  const android = 'android';

  // append utm params with mobile device info
  if (getMobileOS() === MOBILE_PLATFORMS.IOS) {
    source = getSource(source);
    medium = getMedium(medium, ios);
    utm = { ...utm, source, medium };
  } else if (getMobileOS() === MOBILE_PLATFORMS.ANDROID) {
    source = getSource(source);
    medium = getMedium(medium, android);
    utm = { ...utm, source, medium };
  }
  return utm;
}

// get source value for app-view utm params
function getSource(source: string | null) {
  const inAppUtmSource = 'metro-fs_app';
  source = source ? `${source},${inAppUtmSource}` : inAppUtmSource;
  return source;
}

// get medium value for app-view utm params
function getMedium(medium: string | null, platform: string) {
  medium = medium ? `${medium},${platform}` : platform;
  return medium;
}
