export enum EventId {
  EMAIL = 'email',
  PASSWORD = 'password',
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  PHONE_NUMBER = 'phone-number',
  BIRTHDAY_YEAR = 'birthday-year',
  BIRTHPLACE = 'birthplace',
  STREET_NAME = 'street-name',
  HOUSE_NUMBER = 'house-number',
  CITY = 'city',
  POSTCODE = 'postcode',
  IS_METRO_CUSTOMER = 'is-metro-customer',
  METRO_CUSTOMER_NUMBER = 'metro-customer-number',
  IS_US_TAX_PAYER = 'is-us-tax-payer',
}
