import HomeEmail from '@/components/pages/HomeEmail.vue';
import OfflineOnboardingEmailSent from '@/components/pages/onboarding-v2/OfflineOnboardingEmailSent.vue';
import OnboardingV2RouteHome from '@/components/pages/onboarding-v2/OnboardingV2RouteHome.vue';
import TeamCardsHome from '@/components/pages/team-cards/HomeTeamCards.vue';
import TeamCardsEmailSent from '@/components/pages/team-cards/TeamCardsEmailSent.vue';
import TeamCardsTokenExpired from '@/components/pages/team-cards/TeamCardsTokenExpired.vue';
import { featureFlagsNames } from '@/constants';
import featureFlagsStatus from '@/utils/featureFlags';
import { setNewThemeColor } from '@/utils/themeColorTag';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
// other router modules
import posthog from '@/plugins/posthog';
import { useCommonStore } from '@/stores/common';
import { getActivePinia } from 'pinia';
import { nextTick } from 'vue';
import OnboardingV2Routes from './onboarding-v2';
import OnboardingSme from './onboardingSme';
import OnboardingSoleTrader from './onboardingSoleTrader';
import { indexRoutesNames, onboardingV2RoutesNames, smeRoutesNames, teamCardsRoutesNames } from './routeNames';
import TeamCardsRoutes from './teamCards';

const Login = () => import('@/components/pages/Login.vue');
const ActivationOtp = () => import('@/components/pages/ActivationOtp.vue');
const LeadsVerification = () => import('@/components/pages/LeadsVerification.vue');
const ResetPasswordTrigger = () => import('@/components/pages/ResetPasswordTrigger.vue');
const ResetPasswordConfirmation = () => import('@/components/pages/ResetPasswordConfirmation.vue');
const ResetPasswordInput = () => import('@/components/pages/ResetPasswordInput.vue');
const Invitation = () => import('@/components/pages/Invitation.vue');
const GoToApp = () => import('@/components/pages/GoToApp.vue');
const NotFound = () => import('@/components/pages/NotFound.vue');
const Maintenance = () => import('@/components/pages/Maintenance.vue');
const Error = () => import('@/components/organisms/Error.vue');
const OtpEntry = () => import('@/components/pages/onboarding-v2/OtpEntry.vue');

interface RouteMeta {
  allowFromName?: string[];
  requiresAuth?: boolean;
  requireFeatureFlagKey?: keyof typeof featureFlagsNames;
}

/**
 * The router configuration. It uses standard Vue syntax. If you want to guard a route, use `meta` object.
 * Possible options are:
 * - `meta: { requiresAuth: true }` for routes that require authentication
 * - `meta: { allowFromName: [] }` for routes that can be accessed from the given names
 * - `meta: { requireFeatureFlagKey: string as keyof typeof featureFlagsNames }` for routes that can be accessed only if certain feature flag is enabled
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: HomeEmail,
    name: indexRoutesNames.home,
  },
  {
    path: '/activate',
    component: ActivationOtp,
    name: indexRoutesNames.activate,
  },
  {
    path: '/reset-password-trigger',
    component: ResetPasswordTrigger,
    name: indexRoutesNames.resetPasswordTrigger,
    meta: { allowFromName: [indexRoutesNames.login] },
  },
  {
    path: '/reset-password-confirmation',
    component: ResetPasswordConfirmation,
    name: indexRoutesNames.resetPasswordConfirmation,
    meta: { allowFromName: [indexRoutesNames.resetPasswordTrigger] },
  },
  {
    path: '/reset-password-input',
    component: ResetPasswordInput,
    name: indexRoutesNames.resetPasswordInput,
  },
  {
    path: '/invite/:referralId',
    component: Invitation,
    name: indexRoutesNames.invitation,
  },
  {
    path: '/app',
    component: GoToApp,
    name: indexRoutesNames.goToApp,
  },
  {
    path: '/reference',
    component: LeadsVerification,
    name: indexRoutesNames.leadsVerification,
  },
  {
    path: '/login',
    component: Login,
    name: indexRoutesNames.login,
    meta: { allowFromName: [indexRoutesNames.home] },
  },
  {
    path: '/onboarding',
    component: OnboardingV2RouteHome,
    name: indexRoutesNames.onboardingV2,
    children: OnboardingV2Routes,
    meta: { requiresAuth: true },
  },
  // TODO: replace the path to /onboarding/sole-trader later
  // once the other departments are ready
  {
    path: '/onboarding',
    component: OnboardingV2RouteHome,
    name: onboardingV2RoutesNames.soleTraderContainer,
    children: OnboardingSoleTrader,
  },
  {
    path: '/onboarding/sme',
    component: OnboardingV2RouteHome,
    name: smeRoutesNames.smeContainer,
    children: OnboardingSme,
  },
  {
    path: '/otp-entry',
    component: OtpEntry,
    name: indexRoutesNames.otpEntry,
    meta: {
      allowFromName: [indexRoutesNames.login, indexRoutesNames.home, indexRoutesNames.activate],
    },
  },
  {
    path: '/team-cards',
    component: TeamCardsHome,
    name: teamCardsRoutesNames.teamCardsHome,
  },
  {
    path: '/team-cards/onboarding',
    component: OnboardingV2RouteHome,
    name: teamCardsRoutesNames.teamCardsOnboarding,
    children: TeamCardsRoutes,
  },
  {
    path: '/team-cards/token-expired',
    component: TeamCardsTokenExpired,
    name: teamCardsRoutesNames.teamCardsTokenExpired,
  },
  {
    path: '/team-cards/email-sent',
    component: TeamCardsEmailSent,
    name: teamCardsRoutesNames.teamCardsEmailSent,
    meta: {
      allowFromName: [indexRoutesNames.home],
    },
  },
  {
    path: '/offline-onboarding/email-sent',
    component: OfflineOnboardingEmailSent,
    name: indexRoutesNames.offlineOnboardingEmailSent,
    meta: {
      allowFromName: [indexRoutesNames.otpEntry],
    },
  },
  {
    path: '/maintenance',
    component: Maintenance,
    name: indexRoutesNames.maintenance,
  },
  {
    path: '/error',
    component: Error,
    name: indexRoutesNames.error,
  },
  // NotFound must be defined at very end
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: indexRoutesNames.notFound,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // resetting theme color for Apple devices on every route change.
  // if new color is needed, it's set by new component on mount.
  setNewThemeColor();

  const commonStore = useCommonStore();
  await commonStore.checkActiveSessionAndGetCurrentUser();
  const meta = to.meta as RouteMeta;

  // check for the first registration step - pages should be visited in a particular order
  if (meta?.allowFromName && meta.allowFromName.indexOf(String(from.name)) === -1) {
    if (commonStore.currentUser) {
      commonStore.logOut();
    }

    return next({
      name: indexRoutesNames.home,
    });
  }

  // check for the second registration step - pages available after logging in
  if (getActivePinia()) {
    if (to.meta?.requiresAuth && !commonStore.isAuthenticated) {
      return next({
        name: indexRoutesNames.home,
      });
    }
  }

  // check for query param of sme flow to manually "activate" flow
  if (to.query?.smeFlag) {
    localStorage.setItem('smeFlag', 'smeRoutesOpen');
  }

  if (featureFlagsStatus[to.meta?.requireFeatureFlagKey as keyof typeof featureFlagsNames] === false) {
    return next({
      name: indexRoutesNames.home,
    });
  }

  next();
});

router.afterEach((to) => {
  nextTick(() => {
    posthog.capture('$pageview', { title: to.name!.toString() });
  });
});

export { router };
