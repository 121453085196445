<template>
  <svg
    width="25px"
    height="24px"
    viewBox="0 0 25 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="alert-info-small"
        d="M12,0 C18.6175294,0 24,5.38279687 24,11.9992726 C24,18.6172031 18.6175294,24 12,24 C5.3824706,24 0,18.6172031 0,11.9992726 C0,5.38279687 5.3824706,0 12,0 Z M12,2.18221495 C6.58552552,2.18221495 2.18208268,6.58592471 2.18208268,11.9992726 C2.18208268,17.4140753 6.58552552,21.8177851 12,21.8177851 C17.4130198,21.8177851 21.8179173,17.4140753 21.8179173,11.9992726 C21.8179173,6.58592471 17.4130198,2.18221495 12,2.18221495 Z M12.7560916,9.75 C13.3583465,9.75 13.847133,10.2388161 13.847133,10.8411075 L13.847133,16.8247409 L14.4552067,16.8247409 C15.0574615,16.8247409 15.546248,17.313557 15.546248,17.9158483 C15.546248,18.5181397 15.0574615,19.0069558 14.4552067,19.0069558 L10.0910413,19.0069558 C9.48878652,19.0069558 9,18.5181397 9,17.9158483 C9,17.313557 9.48878652,16.8247409 10.0910413,16.8247409 L11.6650503,16.8247409 L11.6650503,11.9322149 L10.8780458,11.9322149 C10.275791,11.9322149 9.78700449,11.4433988 9.78700449,10.8411075 C9.78700449,10.2388161 10.275791,9.75 10.8780458,9.75 L12.7560916,9.75 Z M11.9971016,5.25 C12.8713893,5.25 13.584203,5.96140207 13.584203,6.83719767 C13.584203,7.71299327 12.8713893,8.42439534 11.9971016,8.42439534 C11.121359,8.42439534 10.4100001,7.71299327 10.4100001,6.83719767 C10.4100001,5.96140207 11.121359,5.25 11.9971016,5.25 Z"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-309.000000, -1342.000000)">
        <g transform="translate(309.500000, 1342.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <mask fill="white">
            <use xlink:href="#alert-info-small"></use>
          </mask>
          <use fill="currentColor" xlink:href="#alert-info-small"></use>
        </g>
      </g>
    </g>
  </svg>
</template>
