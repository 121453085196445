import { getStrippedUrl } from '@/constants';
import { MFS_POSTHOG_API_HOST, MFS_POSTHOG_TOKEN } from '@/env';
import posthog from 'posthog-js';

function getEnvInfo() {
  let env = 'local';
  const hostname = window?.location?.hostname;

  if (/^get.dev.metro-fs.com/.test(hostname)) {
    env = 'dev';
  }
  if (/^get.pp.metro-fs.com/.test(hostname)) {
    env = 'pp';
  }
  if (/^get.metro-fs.com/.test(hostname)) {
    env = 'prod';
  }
  if (import.meta.env.MODE === 'e2e-ci' || import.meta.env.MODE === 'e2e-local') {
    env = 'test';
  }

  return env;
}

posthog.init(MFS_POSTHOG_TOKEN, {
  api_host: MFS_POSTHOG_API_HOST,
  feature_flag_request_timeout_ms: 3000,
  debug: import.meta.env.DEV || /^get.dev.metro-fs.com/.test(window?.location?.hostname),
  persistence: 'memory',
  disable_cookie: true,
  autocapture: false,
  capture_pageview: false,
  disable_session_recording: true,
  session_recording: {
    maskAllInputs: true,
  },
  sanitize_properties: (properties: Record<string, string>): Record<string, string> => {
    const { $current_url, $initial_referrer, $referrer, url, search } = properties;
    return {
      ...properties,
      $current_url: getStrippedUrl($current_url),
      $initial_referrer: getStrippedUrl($initial_referrer),
      $referrer: getStrippedUrl($referrer),
      url: getStrippedUrl(url),
      search: getStrippedUrl(search),
    };
  },
});

posthog.register({
  env: getEnvInfo(),
});

posthog.setPersonPropertiesForFlags({'$initial_referring_domain': getEnvInfo()});

export default posthog;
