<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="6catavh1sa" d="M0 0h10.44v16.517H0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path
        d="M5.25 1.5C4.01 1.5 3 2.51 3 3.75S4.01 6 5.25 6 7.5 4.99 7.5 3.75 6.49 1.5 5.25 1.5m0 6A3.754 3.754 0 0 1 1.5 3.75 3.754 3.754 0 0 1 5.25 0 3.754 3.754 0 0 1 9 3.75 3.754 3.754 0 0 1 5.25 7.5"
        fill="currentColor"
      />
      <path d="M5.25 15a.75.75 0 0 1-.75-.75v-6a.75.75 0 1 1 1.5 0v6a.75.75 0 0 1-.75.75" fill="currentColor" />
      <g transform="translate(0 7.483)">
        <path
          d="M7.5 16.517H3a.75.75 0 0 1-.747-.676l-.682-6.834H.75a.75.75 0 0 1-.75-.75v-3C-.004 2.543 2.111.24 4.817.017 7.52-.201 9.988 1.716 10.43 4.395a.75.75 0 1 1-1.48.244 3.77 3.77 0 0 0-4.009-3.127A3.771 3.771 0 0 0 1.5 5.256v2.25h.75a.75.75 0 0 1 .747.676l.682 6.835h3.143l.681-6.825a.746.746 0 0 1 .822-.671.75.75 0 0 1 .671.82l-.75 7.5a.75.75 0 0 1-.746.676"
          fill="currentColor"
          mask="url(#iem09pgcob)"
        />
      </g>
      <path
        d="M12 22.5h10.5V15H12v7.5zM22.75 24h-11c-.689 0-1.25-.56-1.25-1.25v-8c0-.69.561-1.25 1.25-1.25h11c.69 0 1.25.56 1.25 1.25v8c0 .69-.56 1.25-1.25 1.25z"
        fill="currentColor"
      />
      <path d="M23.25 18.75h-12a.75.75 0 1 1 0-1.5h12a.75.75 0 0 1 0 1.5" fill="currentColor" />
      <path
        d="M17.25 20.75a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75M19.5 15a.75.75 0 0 1-.75-.75V13c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5v1.25a.75.75 0 0 1-1.5 0V13c0-1.655 1.345-3 3-3 1.654 0 3 1.345 3 3v1.25a.75.75 0 0 1-.75.75"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
