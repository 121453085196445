import { type RouteRecordRaw } from 'vue-router';
import { smeRoutesNames } from './routeNames';

const CompanyTypeSelection = () => import('@/components/pages/onboarding-v2/sme/CompanyTypeSelection.vue');
const NotSupportedPrivatePerson = () => import('@/components/pages/onboarding-v2/sme/NotSupportedPrivatePerson.vue');
const NotSupportedCompanyType = () => import('@/components/pages/onboarding-v2/sme/NotSupportedCompanyType.vue');
const RoleSelection = () => import('@/components/pages/onboarding-v2/sme/RoleSelection.vue');
const UsTaxResidency = () => import('@/components/pages/onboarding-v2/sme/UsTaxResidency.vue');
const RepresentativeQuestion = () => import('@/components/pages/onboarding-v2/sme/RepresentativeQuestion.vue');
const NotSupportedNonRepresentative = () =>
  import('@/components/pages/onboarding-v2/sme/NotSupportedNonRepresentative.vue');
const CompanyDetails = () => import('@/components/pages/onboarding-v2/sme/CompanyDetails.vue');
const CompanyAddress = () => import('@/components/pages/onboarding-v2/sme/CompanyAddress.vue');
const PersonalDetails = () => import('@/components/pages/onboarding-v2/sme/PersonalDetails.vue');
const AddressDetails = () => import('@/components/pages/onboarding-v2/sme/AddressDetails.vue');
const IdentityDetails = () => import('@/components/pages/onboarding-v2/sme/IdentityDetails.vue');
const TaxDetails = () => import('@/components/pages/onboarding-v2/sme/TaxDetails.vue');
const Representatives = () => import('@/components/pages/onboarding-v2/sme/Representatives.vue');
const AddRepresentative = () => import('@/components/pages/onboarding-v2/sme/AddRepresentative.vue');
const EditRepresentative = () => import('@/components/pages/onboarding-v2/sme/EditRepresentative.vue');
const UploadDocumentsCompany = () => import('@/components/pages/onboarding-v2/sme/UploadDocumentsCompany.vue');
const LegalDetails = () => import('@/components/pages/onboarding-v2/sme/LegalDetails.vue');
const InviteRepresentative = () => import('@/components/pages/onboarding-v2/sme/InviteRepresentative.vue');
const RepresentativeStatus = () => import('@/components/pages/onboarding-v2/sme/RepresentativeStatus.vue');
const AppDownload = () => import('@/components/pages/onboarding-v2/sme/AppDownload.vue');
const PersonSelection = () => import('@/components/pages/onboarding-v2/sme/PersonSelection.vue');

/**
 * The router configuration. It uses standard Vue syntax. If you want to guard a route, use `meta` object.
 * Possible options are:
 * - `meta: { requiresAuth: true }` for routes that require authentication
 * - `meta: { allowFromName: [] }` for routes that can be accessed from the given names
 * - `meta: { requireFeatureFlagKey: string as keyof typeof featureFlagsNames }` for routes that can be accessed only if certain feature flag is enabled
 */
const onboardingSmeRoutes: Array<RouteRecordRaw> = [
  // TODO: the /role should probably move to the generic routes and eventually replace /trader
  {
    path: 'role',
    component: RoleSelection,
    name: smeRoutesNames.roleSelection,
    meta: { requiresAuth: true },
  },
  {
    path: 'role-not-supported',
    component: NotSupportedPrivatePerson,
    name: smeRoutesNames.notSupportedPrivatePerson,
    meta: { requiresAuth: true },
  },
  {
    path: 'company-type',
    component: CompanyTypeSelection,
    name: smeRoutesNames.companyTypeSelection,
    meta: { requiresAuth: true },
  },
  {
    path: 'company-type-not-supported',
    component: NotSupportedCompanyType,
    name: smeRoutesNames.notSupportedCompanyType,
    meta: { requiresAuth: true },
  },
  {
    path: 'us-tax-residency',
    component: UsTaxResidency,
    name: smeRoutesNames.usTaxResidency,
    meta: { requiresAuth: true },
  },
  {
    path: 'confirm-representative',
    component: RepresentativeQuestion,
    name: smeRoutesNames.representativeQuestion,
    meta: { requiresAuth: true },
  },
  {
    path: 'not-representative',
    component: NotSupportedNonRepresentative,
    name: smeRoutesNames.notSupportedNonRepresentative,
    meta: { requiresAuth: true },
  },
  {
    path: 'company-details',
    component: CompanyDetails,
    name: smeRoutesNames.companyDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'company-address',
    component: CompanyAddress,
    name: smeRoutesNames.companyAddress,
    meta: { requiresAuth: true },
  },
  {
    path: 'personal-details',
    component: PersonalDetails,
    name: smeRoutesNames.personalDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'address-details',
    component: AddressDetails,
    name: smeRoutesNames.addressDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'identity-details',
    component: IdentityDetails,
    name: smeRoutesNames.identityDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'tax-details',
    component: TaxDetails,
    name: smeRoutesNames.taxDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'representatives',
    component: Representatives,
    name: smeRoutesNames.representatives,
    meta: { requiresAuth: true },
  },
  {
    path: 'add-representative',
    component: AddRepresentative,
    name: smeRoutesNames.addRepresentative,
    meta: { requiresAuth: true },
  },
  {
    path: 'edit-representative',
    component: EditRepresentative,
    name: smeRoutesNames.editRepresentative,
    meta: { requiresAuth: true },
  },
  {
    path: 'upload-documents',
    component: UploadDocumentsCompany,
    name: smeRoutesNames.uploadDocumentsCompany,
    meta: { requiresAuth: true },
  },
  {
    path: 'legal-details',
    component: LegalDetails,
    name: smeRoutesNames.legalDetails,
    meta: { requiresAuth: true },
  },
  {
    path: 'invite-representative',
    component: InviteRepresentative,
    name: smeRoutesNames.inviteRepresentative,
    meta: { requiresAuth: true },
  },
  {
    path: 'representative-status',
    component: RepresentativeStatus,
    name: smeRoutesNames.representativeStatus,
    meta: { requiresAuth: true },
  },
  {
    path: 'app-download',
    component: AppDownload,
    name: smeRoutesNames.appDownload,
    meta: { requiresAuth: true },
  },
  {
    path: 'person-selection',
    component: PersonSelection,
    name: smeRoutesNames.personSelection,
    meta: { requiresAuth: true },
  },
];

export default onboardingSmeRoutes;
