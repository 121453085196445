<template>
  <OnboardingV2Container>
    <MfsGridWithSidebar>
      <template v-slot:title>
        <MfsHeader v-if="commonStore.basePageDetails.headerTitle" predefined-style="onb-page-header">
          {{ commonStore.basePageDetails.headerTitle }}
        </MfsHeader>
      </template>

      <template v-slot:infoText>
        <MfsText v-if="commonStore.basePageDetails.infoText" predefined-style="onb-page-info-text">
          {{ commonStore.basePageDetails.infoText }}
        </MfsText>
      </template>

      <template v-slot:default>
        <router-view />
      </template>
    </MfsGridWithSidebar>
  </OnboardingV2Container>
</template>

<script setup lang="ts">
import MfsHeader from '@/components/atoms/MfsHeader.vue';
import MfsText from '@/components/atoms/MfsText.vue';
import OnboardingV2Container from '@/components/organisms/OnboardingV2Container.vue';
import MfsGridWithSidebar from '@/components/templates/MfsGridWithSidebar.vue';
import { useCommonStore } from '@/stores/common';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
const commonStore = useCommonStore();

onMounted(() => {
  if (route.fullPath.includes('team-cards')) {
    commonStore.basePageDetails = {
      ...commonStore.basePageDetails,
      sideBarTitle: t('onboardingTeamCards.sidebar.title'),
      stepsDescription18nKey: 'onboardingTeamCards.sidebar.steps',
      stepsCount: 2,
    };
    return;
  }

  if (route.fullPath.includes('sme')) {
    commonStore.basePageDetails = {
      ...commonStore.basePageDetails,
      sideBarTitle: t('onboardingV2.sidebar.titleSme'),
      stepsDescription18nKey: 'onboardingV2.sidebar.stepsSme',
      stepsCount: 5,
    };
    return;
  }

  commonStore.basePageDetails = {
    ...commonStore.basePageDetails,
    sideBarTitle: t('onboardingV2.sidebar.title'),
    stepsDescription18nKey: 'onboardingV2.sidebar.steps',
    stepsCount: 4,
  };
});
</script>
