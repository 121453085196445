<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#0052b4" d="M0 255.9L256 0h256v512H0z" />
      <path
        fill="#eee"
        d="M0 0v33.4l14.2 31L0 100.2v155.7h33.4l34.2-16.6 32.6 16.6 33.4.1v-75.3l75.2 75.2H256l-6.2-19.8 6.2-11.7v-15.7l-75.2-75.2H256v-33.3l-14.8-33.3L256 33.4V0H100.2L65.5 16.9 33.4 0H0z"
      />
      <path fill="#d80027" d="M256 224.4l-91-91h-31.4L256 256z" />
      <path fill="#d80027" d="M33.4 0v33.4H0v66.8h33.4v155.7h66.8V100.2H256V33.4H100.2V0z" />
      <path fill="#ffda44" d="M411.8 300.5v11.2h-89v-11.2h-33.4V345h22.3v11.2H423V345h22.2v-44.5z" />
      <path fill="#338af3" d="M289.4 133.6V256c0 59.6 77.9 78 77.9 78s78-18.4 78-78V133.6h-156z" />
      <path
        fill="#eee"
        d="M367.3 224.9c-19.5 0-19.5 17.8-39 17.8s-19.4-17.8-39-17.8V256c19.6 0 19.6 17.8 39 17.8s19.5-17.8 39-17.8 19.5 17.8 39 17.8 19.4-17.8 39-17.8v-31.2c-19.6 0-19.6 17.8-39 17.8-19.5 0-19.5-17.8-39-17.8zm0-62.4c-19.5 0-19.5 17.8-39 17.8s-19.4-17.8-39-17.8v31.2c19.6 0 19.6 17.8 39 17.8s19.5-17.8 39-17.8 19.5 17.8 39 17.8 19.4-17.8 39-17.8v-31.2c-19.6 0-19.6 17.8-39 17.8-19.5 0-19.5-17.8-39-17.8z"
      />
    </g>
  </svg>
</template>
