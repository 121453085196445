import MfsApp from '@/App.vue';
import '@/assets/styles/tailwind.css';
import { trackClickDirective } from '@/directives/trackClick';
import '@/plugins/dayjs';
import { getFirebaseClient } from '@/plugins/firebase';
import { i18n } from '@/plugins/i18n';
import { initializeSentry } from '@/plugins/sentry';
import { router } from '@/router';
import { generateCssRootCustomProperties } from '@/utils/cssUtil';
import { initFeatureFlags } from '@/utils/featureFlags';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

// Lazily initialize firebase for later use down the road
getFirebaseClient();
// set css custom properties
generateCssRootCustomProperties();

const app = createApp(MfsApp);

app.use(router);
app.use(createPinia());
app.use(i18n);
app.directive('track-click', trackClickDirective);

initializeSentry(app);

// load Vue app after feature flags are initialized
const init = async () => {
  try {
    await initFeatureFlags();
    app.mount('#app');
  } catch (error) {
    console.error('Posthog initialization failed with error:', error);
  }
};

init();
