<template>
  <div>
    <MfsFont
      font-name="overline"
      :class="[
        'text-center',
        {
          'md:mx-auto md:mb-9 md:max-w-[415px] lg:mx-0 lg:!text-left': onSliderHome,
        },
      ]"
    >
      <I18nT keypath="noCheckboxLegalText.agreement">
        <template v-for="(termsId, i) in registrationAgreementConfig[0].showTermsPdf" v-slot:[termsId] :key="i">
          <a
            class="cursor-pointer text-primary"
            @click.prevent.stop="onboardingStore.termsDetails[termsId] ? openMfsDialog(termsId) : null"
          >
            {{ t(`noCheckboxLegalText.${termsId}-linktext`) }}
          </a>
        </template>
      </I18nT>
    </MfsFont>

    <MfsDialog
      v-if="currentOpenTerm"
      :is-opened="isGuidelinesDialogVisible"
      title=""
      :markdown-text="openTermHtml"
      @close="isGuidelinesDialogVisible = false"
    >
      <MfsButton
        :id="`download-pdf-${currentOpenTerm.termsId}`"
        class="!mt-5"
        :label="t('common.buttonLabel.downloadPdf')"
        height="48"
        @click="downloadPdf(currentOpenTerm.termsId)"
      />
    </MfsDialog>
  </div>
</template>

<script setup lang="ts">
import MfsButton from '@/components/atoms/MfsButton.vue';
import MfsDialog from '@/components/atoms/MfsDialog.vue';
import MfsFont from '@/components/atoms/MfsFont.vue';
import env from '@/env';
import { getStorageDownloadUrl } from '@/service/firebase';
import { useOnboardingStore } from '@/stores/onboarding';
import { legal } from '@/utils/analytics/errorEventNames';
import { trackError } from '@/utils/analytics/sendAnalyticsUtil';
import { registrationAgreementConfig, TermsIdType } from '@/utils/legalAgreementConfigV2';
import axios from 'axios';
import MarkdownIt from 'markdown-it';
import { computed, onMounted, ref } from 'vue';
import { I18nT, useI18n } from 'vue-i18n';

withDefaults(
  defineProps<{
    onSliderHome?: boolean;
  }>(),
  {
    onSliderHome: false,
  }
);

const { t } = useI18n();
const onboardingStore = useOnboardingStore();
const openTermHtml = ref('');
const openedTerm = ref<TermsIdType | null>(null);
const isGuidelinesDialogVisible = ref(false);
const md: MarkdownIt = new MarkdownIt({
  linkify: true,
});

onMounted(() => {
  if (Object.keys(onboardingStore.termsDetails).length === 0) {
    onboardingStore.fetchTermsDetails(registrationAgreementConfig);
  }
});

const openMfsDialog = (termsId: TermsIdType) => {
  openedTerm.value = termsId;
  const contentToRender = currentOpenTerm.value?.content ?? '';

  openTermHtml.value = md.render(contentToRender).replace(/<a /g, '<a target="_blank" ');
  isGuidelinesDialogVisible.value = true;
};

const currentOpenTerm = computed(() => {
  if (openedTerm.value !== null) {
    return onboardingStore.termsDetails[openedTerm.value];
  }
  return null;
});

const downloadPdf = async (termsId: string) => {
  try {
    const downloadUrl = await getStorageDownloadUrl(env.MFS_TERMS_BUCKET, `${termsId}/de_DE/index.pdf`);
    const response = await axios(downloadUrl, {
      method: 'GET',
      responseType: 'blob',
    });

    //Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: 'application/pdf' });
    const link = document.createElement('a');
    //Build a URL from the file
    link.href = URL.createObjectURL(file);
    if (openedTerm.value !== null) {
      link.download = onboardingStore.termsDetails[openedTerm.value]?.title || '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error: any) {
    trackError(legal.downloadPdf, error, true);
  }
};
</script>
