import type { RouteRecordRaw } from 'vue-router';
import { onboardingV2RoutesNames } from './routeNames';

const AreYouASoleTrader = () => import('@/components/pages/onboarding-v2/AreYouASoleTrader.vue');
const PasswordEntry = () => import('@/components/pages/onboarding-v2/PasswordEntry.vue');
const PhoneNumber = () => import('@/components/pages/onboarding-v2/PhoneNumber.vue');
const AppDownload = () => import('@/components/pages/onboarding-v2/AppDownload.vue');

/**
 * The router configuration. It uses standard Vue syntax. If you want to guard a route, use `meta` object.
 * Possible options are:
 * - `meta: { requiresAuth: true }` for routes that require authentication
 * - `meta: { allowFromName: [] }` for routes that can be accessed from the given names
 * - `meta: { requireFeatureFlagKey: string as keyof typeof featureFlagsNames }` for routes that can be accessed only if certain feature flag is enabled
 */
const onboardingRoutes: Array<RouteRecordRaw> = [
  {
    path: 'password-entry',
    component: PasswordEntry,
    name: onboardingV2RoutesNames.passwordEntry,
    meta: { requiresAuth: true },
  },
  {
    path: 'phone-number',
    component: PhoneNumber,
    name: onboardingV2RoutesNames.phoneNumberEntry,
    meta: { requiresAuth: true },
  },
  {
    path: 'trader',
    component: AreYouASoleTrader,
    name: onboardingV2RoutesNames.areYouASoleTrader,
    meta: { requiresAuth: true },
  },
  {
    path: 'app-download',
    component: AppDownload,
    name: onboardingV2RoutesNames.appDownload,
    meta: { requiresAuth: true },
  },
];

export default onboardingRoutes;
