<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#eee" d="M0 167l253.8-19.3L512 167v178l-254.9 32.3L0 345z" />
      <path fill="#d80027" d="M0 0h512v167H0z" />
      <path fill="#333" d="M0 345h512v167H0z" />
      <path
        fill="#6da544"
        d="M153 194.8l13.8 42.5h44.7l-36.2 26.3 13.8 42.5-36.1-26.3-36.2 26.3 13.8-42.5-36.2-26.3h44.7zm206 0l13.9 42.5h44.7l-36.2 26.3 13.8 42.5-36.2-26.3-36.1 26.3 13.8-42.5-36.2-26.3h44.7z"
      />
    </g>
  </svg>
</template>
