export enum Onboarding {
  AION_FRESHWORKS_JWT_API = 'auth/aion-freshworks-jwt',
  LEADS_EMAIL_RETRIEVE = 'onboarding/leads/email-retrieve',
  LEADS_RETRIEVE_API = 'onboarding/leads',
  SOLETRADER_ONBOARDING = 'onboarding/soletrader',
  MARKETING_EMAIL_OPTIN = 'onboarding/marketing-email-optin',
  USER_RESET_PASSWORD_API = 'auth/reset-password',
  VALIDATE_PHONE_NUMBER = 'onboarding/validate-phone-number',
  AUTH_GENERATE_OTP = 'auth/generate-otp',
  OTP_CREATE_USER = 'onboarding/otp-create-user',
}

export enum TeamCards {
  INVITE_RESEND = 'teamcards/invite-resend',
  TOKEN_VERIFY = 'teamcards/validate-token',
  ONBOARDING = 'teamcards/onboarding',
}

export enum Sme {
  DELETE_DOC_URL = 'sme-onboarding/delete-document',
  GET_DOC_URL = 'sme-onboarding/get-document-upload-url',
  SET_ROLE = 'sme-onboarding/set-role',
  GET_UPLOAD_SLOTS = 'sme-onboarding/upload-slots',
}
