<template>
  <svg
    width="15px"
    height="8px"
    viewBox="0 0 15 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="chevron-down"
        d="M6.00022346,16.4998125 C5.80522346,16.4998125 5.61097346,16.4248125 5.46472346,16.2748125 C5.17447346,15.9793125 5.17897346,15.5038125 5.47522346,15.2143125 L11.5982235,9.2143125 C11.8899735,8.9285625 12.3572235,8.9285625 12.6482235,9.2143125 L18.5252235,14.9735625 C18.8214735,15.2638125 18.8259735,15.7385625 18.5357235,16.0348125 C18.2454735,16.3303125 17.7714735,16.3340625 17.4752235,16.0453125 L12.1232235,10.7998125 L6.52522346,16.2853125 C6.37897346,16.4285625 6.18997346,16.4998125 6.00022346,16.4998125"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1192.000000, -1171.000000)">
        <g transform="translate(1187.500000, 1162.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <mask fill="white">
            <use xlink:href="#chevron-down"></use>
          </mask>
          <use
            fill="currentColor"
            transform="translate(12.000223, 12.749906) scale(1, -1) translate(-12.000223, -12.749906) "
            xlink:href="#chevron-down"
          ></use>
        </g>
      </g>
    </g>
  </svg>
</template>
