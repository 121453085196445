<template>
  <main>
    <div v-if="shouldShowBlankLoader" class="h-screen bg-base-bg" />

    <template v-else>
      <router-view />

      <template v-if="isFreshChatVisible">
        <FreshChatWidget />
      </template>

      <Cookies @enable-fresh-chat="onEnableFreshChat" />

      <MfsAuthDialog
        :is-visible="showContinueDialog"
        :title="t('onboardingV2.continueWithSession.heading')"
        :text="t('onboardingV2.continueWithSession.text')"
        :cancel-label="t('onboardingV2.continueWithSession.backBtn')"
        :ok-label="t('onboardingV2.continueWithSession.continueBtn')"
        @ok-btn-click="onClickContinue"
        @cancel-btn-click="onClickNotContinue"
      />
    </template>

    <FirestoreErrorDialog />
  </main>
</template>

<script setup lang="ts">
import FreshChatWidget from '@/components/atoms/FreshChatWidget.vue';
import Cookies from '@/components/organisms/Cookies.vue';
import FirestoreErrorDialog from '@/components/organisms/FirestoreErrorDialog.vue';
import MfsAuthDialog from '@/components/organisms/MfsAuthDialog.vue';
import { useCommonPageFeats } from '@/composables/useCommonPageFeats';
import { useCommonStore } from '@/stores/common';
import { useOnboardingStore } from '@/stores/onboarding';
import { checkForUtmParamsAndRefAndSaveUrl } from '@/utils/cookiesAndUtm';
import { setAppSizeAsCssProperty } from '@/utils/cssUtil';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useFirestore } from './composables/useFirestore';
import { MFS_SSO_CONTEXT_URI } from './env';
import { indexRoutesNames } from './router/routeNames';
import featureFlagsStatus from './utils/featureFlags';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { redirectLoggedInUser, logOutAndResetState } = useCommonPageFeats();
const commonStore = useCommonStore();
const onboardingStore = useOnboardingStore();
const { firestoreRequest, loadUserData } = useFirestore();

let isFreshChatVisible = ref(false);
let showContinueDialog = ref(false);

const shouldShowBlankLoader = computed(() => commonStore.isAuthStatusLoading && !featureFlagsStatus.MAINTENANCE_MODE);

onMounted(async () => {
  nextTick(() => {
    setAppSizeAsCssProperty();
  });

  if (featureFlagsStatus.MAINTENANCE_MODE) {
    return router.push({ name: indexRoutesNames.maintenance });
  }

  if ('app-view' in route.query) {
    onboardingStore.isAppView = true;
  }

  checkForUtmParamsAndRefAndSaveUrl();

  await commonStore.checkActiveSessionAndGetCurrentUser();

  if (window.location.href === `${MFS_SSO_CONTEXT_URI}/app`) return;

  if (commonStore.currentUser) {
    const token = await commonStore.currentUser.getIdTokenResult();

    if (!token) return;

    const { roles } = token.claims;

    onboardingStore.accountRole = roles;
    await onNext();

    // If user reloads or tries to change his password, don't show the popup
    if (onboardingStore.nextRoute === route.name || route.name === indexRoutesNames.resetPasswordInput) return;

    // when clicked on back, current route is still the previous route. On page reload here, it shows popup and on click no, user is not signed out
    showContinueDialog.value = true;
  }
});

const onNext = async () => {
  await loadUserData();
};

firestoreRequest.onNext = onNext;

function onClickContinue() {
  redirectLoggedInUser(false);
  showContinueDialog.value = false;
}

async function onClickNotContinue() {
  await logOutAndResetState();
  showContinueDialog.value = false;
  window.location.href = MFS_SSO_CONTEXT_URI;
}

function onEnableFreshChat() {
  isFreshChatVisible.value = true;
}
</script>

<style>
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-height: 100vh;
}

/* Removes blue background for inputs autofilled by Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
}
</style>
