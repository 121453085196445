// config file to control legal agreements

export enum TermsIdType {
  METROFS_TERMS = 'METROFS_TERMS',
  METROFS_AION_TERMS = 'METROFS_AION_TERMS',
  METROFS_FEES = 'METROFS_FEES',
  METROFS_DEPOSIT_PROTECTION = 'METROFS_DEPOSIT_PROTECTION',
  METROFS_PRIVACY = 'METROFS_PRIVACY',
  METROFS_AION_PRIVACY = 'METROFS_AION_PRIVACY',
  METROFS_SCHUFA = 'METROFS_SCHUFA',
  METROFS_MARKETING = 'METROFS_MARKETING',
  METROFS_DATA_SHARING = 'METROFS_DATA_SHARING',
  METROFS_SOLETRADER = 'METROFS_SOLETRADER',
  METROFS_TEAM_CARDS_TERMS = 'METROFS_TEAM_CARDS_TERMS',
}

export interface EachAgreementConfig {
  showTermsPdf: TermsIdType[];
  hiddenTerms: TermsIdType[];
  optionalAcceptance?: boolean;
}

export const registrationAgreementConfig: EachAgreementConfig[] = [
  {
    showTermsPdf: [TermsIdType.METROFS_TERMS, TermsIdType.METROFS_PRIVACY, TermsIdType.METROFS_AION_TERMS],
    hiddenTerms: [TermsIdType.METROFS_FEES, TermsIdType.METROFS_DEPOSIT_PROTECTION, TermsIdType.METROFS_AION_PRIVACY],
  },
];

export const onboardingAgreementConfig: EachAgreementConfig[] = [
  {
    // 1st agreement - schufa
    showTermsPdf: [],
    hiddenTerms: [TermsIdType.METROFS_SCHUFA],
    optionalAcceptance: false,
  },
  {
    // 2nd agreement - marketing
    showTermsPdf: [TermsIdType.METROFS_MARKETING],
    hiddenTerms: [TermsIdType.METROFS_DATA_SHARING],
    optionalAcceptance: true,
  },
  {
    // 3rd checkbox - isPep
    showTermsPdf: [],
    hiddenTerms: [],
    optionalAcceptance: true,
  },
  {
    // 4th agreement - soletrader
    showTermsPdf: [],
    hiddenTerms: [TermsIdType.METROFS_SOLETRADER],
    optionalAcceptance: false,
  },
];

export const onboardingAgreementsObject: { [key: number]: boolean } = onboardingAgreementConfig.reduce(
  (acc: { [key: number]: boolean }, _, i) => {
    acc[i] = false;
    return acc;
  },
  {}
);

export const teamCardsAgreementConfig: EachAgreementConfig[] = [
  {
    showTermsPdf: [TermsIdType.METROFS_PRIVACY],
    hiddenTerms: [TermsIdType.METROFS_AION_PRIVACY],
    optionalAcceptance: false,
  },
  {
    showTermsPdf: [TermsIdType.METROFS_TEAM_CARDS_TERMS],
    hiddenTerms: [],
    optionalAcceptance: false,
  },
];
