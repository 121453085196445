<template>
  <svg
    width="15px"
    height="8px"
    viewBox="0 0 15 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="chevron-up"
        d="M6.00022346,14.9998125 C5.80522346,14.9998125 5.61097346,14.9248125 5.46472346,14.7748125 C5.17447346,14.4793125 5.17897346,14.0038125 5.47522346,13.7143125 L11.5982235,7.7143125 C11.8899735,7.4285625 12.3572235,7.4285625 12.6482235,7.7143125 L18.5252235,13.4735625 C18.8214735,13.7638125 18.8259735,14.2385625 18.5357235,14.5348125 C18.2454735,14.8303125 17.7714735,14.8340625 17.4752235,14.5453125 L12.1232235,9.2998125 L6.52522346,14.7853125 C6.37897346,14.9285625 6.18997346,14.9998125 6.00022346,14.9998125"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1240.000000, -1169.000000)">
        <g transform="translate(1235.500000, 1162.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <mask fill="white">
            <use xlink:href="#chevron-up"></use>
          </mask>
          <use fill="currentColor" xlink:href="#chevron-up"></use>
        </g>
      </g>
    </g>
  </svg>
</template>
