import type { Env } from '@/types/env';

export default {
  MFS_APP_DOMAIN: 'metro-fs.com',
  MFS_ENV: 'production',
  MFS_HOMEPAGE: 'https://metro-fs.com',
  MFS_COBROWSE_API: 'https://assist.metro-fs.com',
  MFS_COBROWSE_LICENSE: '3x8W1EBfggALGg',
  MFS_POSTHOG_TOKEN: 'phc_GXFbHYJpRf15IecJqDN3EVv3u5i5r0DHyPhADdhCvTr',
  MFS_POSTHOG_API_HOST: 'https://analytics.metro-fs.com',
  MFS_BV2_API_BASE_URI: 'https://api.metro-fs.com/v1',
  MFS_TERMS_BUCKET: 'metro-fs-eu-terms-versions-active',

  // sso
  MFS_SSO_CONTEXT_URI: 'https://get.metro-fs.com',

  // firebase
  MFS_FIREBASE_APIKEY: 'AIzaSyBDwPbzP7mbeYF4Q0YzovHEXEdRlB_AH5Y',
  MFS_FIREBASE_AUTHDOMAIN: 'metro-fs.firebaseapp.com',
  MFS_FIREBASE_PROJECT_ID: 'metro-fs',
  MFS_FIREBASE_MASSENGER_SENDER_ID: '498101649337',
  MFS_FIREBASE_APP_ID: '1:498101649337:web:087a7ce67d1b32cf950068',
  MFS_FIREBASE_APP_URL: 'https://link.metro-fs.com/app',
  MFS_FIREBASE_IOS_DOWNLOAD_URL: 'https://link.metro-fs.com/ios-download',
  MFS_FIREBASE_ANDROID_DOWNLOAD_URL: 'https://link.metro-fs.com/playstore',

  // freshchat
  MFS_FRESHCHAT_TOKEN: '9984f8d3-e339-4e06-a0e6-54610dbc3828',

  // google places
  MFS_PLACES_API_KEY: 'AIzaSyAjoZpy4B1DixwxBeXpfSmowcS0u5r2C_g',

  // sentry's Data Source Name (DSN)
  MFS_SENTRY_DSN: 'https://42b2d510bf34447a91e148cbbf050f5d@o4504928279199744.ingest.sentry.io/4504928283066368',
} as Env;
