<template>
  <svg
    width="19px"
    height="16px"
    viewBox="0 0 19 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="arrow-right"
        d="M3.00121875,12.0002813 C3.00121875,11.5855313 3.33646875,11.2502813 3.75121875,11.2502813 L18.4407188,11.2502812 L12.9694688,5.77903125 C12.6762188,5.48578125 12.6762188,5.01178125 12.9694688,4.71853125 C13.1157188,4.57228125 13.3077187,4.49878125 13.4997187,4.49878125 C13.6917188,4.49878125 13.8837187,4.57228125 14.0299688,4.71853125 L20.7814688,11.4692812 C20.8497188,11.5382812 20.9052187,11.6200313 20.9427188,11.7107813 C20.9809687,11.8015312 21.0012187,11.8997812 21.0012187,12.0002812 C21.0012187,12.1000313 20.9809687,12.1982813 20.9427188,12.2890313 C20.9052187,12.3797812 20.8497188,12.4615313 20.7814688,12.5305312 L14.0299688,19.2812812 C13.7367188,19.5745312 13.2627188,19.5745312 12.9694688,19.2812812 C12.6762188,18.9880312 12.6762188,18.5140312 12.9694688,18.2207812 L18.4407188,12.7502812 L3.75121875,12.7502812 C3.33646875,12.7502812 3.00121875,12.4142813 3.00121875,12.0002813 Z"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-902.000000, -1166.000000)">
        <g transform="translate(899.500000, 1162.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <mask fill="white">
            <use xlink:href="#arrow-right"></use>
          </mask>
          <use fill="currentColor" xlink:href="#arrow-right"></use>
        </g>
      </g>
    </g>
  </svg>
</template>
