<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#eee" d="M189.2 0H512v167l-347.5 24.6z" />
      <path fill="#6da544" d="M163 320l349 25v167H189.2z" />
      <path fill="#d80027" d="M0 0h189.2v167H512v178H189.2v167H0z" />
      <path
        fill="#eee"
        d="M156.6 112.7L133 89l-15.7 15.8L101.5 89 78 112.7l15.8 15.7L78 144l23.6 23.6 15.8-15.7 15.7 15.7 23.6-23.6-15.7-15.7z"
      />
    </g>
  </svg>
</template>
