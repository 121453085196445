<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#d80027" d="M0 167l253.8-19.3L512 167v178l-254.9 32.3L0 345z" />
      <path fill="#333" d="M0 0h512v167H0z" />
      <path fill="#496e2d" d="M0 345h512v167H0z" />
      <path
        fill="#d80027"
        d="M332.5 122.4l-31.2-14.7 16.6-30.3-34 6.5-4.3-34.3L256 75l-23.6-25.3L228 84l-34-6.5 16.7 30.3-31.3 14.7z"
      />
    </g>
  </svg>
</template>
