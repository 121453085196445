<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#eee" d="M0 144.7l255.3-36.5L512 144.7v222.6L250.5 407 0 367.3z" />
      <path fill="#ff9811" d="M0 0h512v144.7H0z" />
      <path fill="#6da544" d="M0 367.3h512V512H0z" />
      <circle cx="256" cy="256" r="89" fill="#0052b4" />
      <circle cx="256" cy="256" r="55.7" fill="#eee" />
      <path
        fill="#0052b4"
        d="M256 187.3l17.2 39 42.3-4.6-25.2 34.3 25.2 34.3-42.3-4.6-17.2 39-17.2-39-42.3 4.6 25.2-34.3-25.2-34.3 42.3 4.6z"
      />
    </g>
  </svg>
</template>
