<template>
  <div class="slider-container">
    <div v-for="i in 3" :key="i" :class="`slide slide-team-cards-img--${i}`" />
  </div>
</template>

<script setup lang="ts">
import { teamCardsStartPageEvents } from '@/utils/analytics/eventNames';
import { trackAnalytics } from '@/utils/analytics/sendAnalyticsUtil';
import Siema from 'siema';
import { onMounted, ref, watch } from 'vue';

const emit = defineEmits(['slide-change']);

const props = defineProps<{
  currentSlide: number;
}>();

const sliderInstance = ref<Siema | undefined>(undefined);

watch(
  () => props.currentSlide,
  (value) => {
    if (!sliderInstance.value) return;

    const currSlide = sliderInstance.value.currentSlide + 1;
    currSlide !== value && sliderInstance.value.goTo(value - 1);
  },
  { immediate: true }
);

onMounted(() => {
  sliderInstance.value = new Siema({
    selector: '.slider-container',
    duration: 250,
    onChange: () => {
      const nextSlideStep = sliderInstance.value!.currentSlide + 1;
      emit('slide-change', nextSlideStep);

      trackAnalytics(teamCardsStartPageEvents.swipeOnSlide, {
        'swipe-on-slide-on-team-cards': nextSlideStep,
      });
    },
  });
});
</script>

<style scoped>
.slide {
  height: calc(1.25 * 85vw);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  margin: 0 auto;
  max-width: 500px;
}
@media (min-aspect-ratio: 3/4) {
  .slide {
    height: 63vh;
  }
}

.slide-team-cards-img--1 {
  background-image: url('../../assets/images/home-team-cards-slider-mobile-1.webp');
}

.slide-team-cards-img--2 {
  background-image: url('../../assets/images/home-team-cards-slider-mobile-2.webp');
}

.slide-team-cards-img--3 {
  background-image: url('../../assets/images/home-team-cards-slider-mobile-3.webp');
}

@media screen and (min-width: 768px) {
  .slide {
    height: 415px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .slide-team-cards-img--1 {
    background-image: url('../../assets/images/home-team-cards-slider-desktop-1.webp');
  }

  .slide-team-cards-img--2 {
    background-image: url('../../assets/images/home-team-cards-slider-desktop-2.webp');
  }

  .slide-team-cards-img--3 {
    background-image: url('../../assets/images/home-team-cards-slider-desktop-3.webp');
  }
}
</style>
