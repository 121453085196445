<template>
  <div class="flex h-[4px]">
    <div
      v-for="step in stepsConfig.max"
      :key="step"
      :style="{ width: width + '%' }"
      :class="['m-0 mr-1', isCompleted(step) && 'bg-primary', !isCompleted(step) && 'bg-base-lighter']"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface StepsConfig {
  max: number;
  completed: number;
}

const props = defineProps({
  stepsConfig: {
    type: Object as () => StepsConfig,
    required: true,
    validator: (value: StepsConfig) => {
      return value.max > 0 && value.completed >= 0;
    },
  },
});

const width = computed(() => {
  return 100 / props.stepsConfig.max;
});

const isCompleted = (step: number): boolean => {
  return step <= props.stepsConfig.completed;
};
</script>
