import { indexRoutesNames, onboardingV2RoutesNames, smeRoutesNames, teamCardsRoutesNames } from '@/router/routeNames';
import type { RouteRecordName } from 'vue-router';

export enum PageContext {
  EMAIL_ENTRY = 'Email Entry',
  START = 'Start',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  OTP_ENTRY = 'OTP Entry',
  PASSWORD_ENTRY = 'Password Entry',
  PASSWORD_RESET = 'Password Reset',
  SOLETRADER = 'Soletrader',
  NOT_SOLETRADER = 'Not A Soletrader',
  NOT_SUPPORTED_COMPANY_TYPE = 'Not Supported Company',
  PHONE_NUMBER = 'Phone Number',
  US_TAX_RESIDENCY = 'US Tax Residency',
  PERSONAL_DETAILS = 'Personal Details',
  NOT_SUPPORTED_NATIONALITY = 'Not Supported Nationality',
  ADDRESS_DETAILS = 'Address Details',
  BUSINESS_DETAILS = 'Business Details',
  IDENTITY_DETAILS = 'Identity Details',
  TAX_DETAILS = 'Tax Details',
  LEGAL_DETAILS = 'Legal Details',
  APP_DOWNLOAD = 'App Download',
  OFFLINE_ONBOARDING_EMAIL_SENT = 'Offline Onboarding Email Sent',

  TEAMCARDS_START = 'Team Cards Start',
  TEAMCARDS_EMAIL = 'Team Cards Email Confirmation',
  TEAMCARD_PASSWORD_ENTRY = 'Team Cards Password Entry',
  TEAMCARDS_TOKEN_EXPIRED = 'Team Cards Token Expired',
  TEAMCARDS_APP_DOWNLOAD = 'Team Cards App Download',

  SME_SELECT_COMPANY_TYPE = 'Select Company Type Sme',
  SME_SELECT_ROLE = 'Select Role Sme',
  SME_US_TAX_RESIDENCY = 'US Tax Residency Sme',
  SME_REP_QUESTION = 'Representative Question Sme',
  SME_COMPANY_DETAILS = 'Company Details Sme',
  SME_COMPANY_ADDRESS = 'Company Address Sme',
  SME_PERSONAL_DETAILS = 'Personal Details Sme',
  SME_ADDRESS_DETAILS = 'Address Details Sme',
  SME_IDENTITY_DETAILS = 'Identity Details Sme',
  SME_TAX_DETAILS = 'Tax Details Sme',
  SME_REPRESENTATIVES = 'Representatives Sme',
  SME_ADD_REPRESENTATIVE = 'Add Representative Sme',
  SME_EDIT_REPRESENTATIVE = 'Edit Representative Sme',
  SME_INVITE_REPRESENTATIVE = 'Invite Representative Sme',
  SME_REPRESENTATIVE_STATUS = 'Representative Status Sme',
  SME_SELECT_PERSON = 'Select Person Sme',
  SME_UPLOAD_COMPANY_DOCUMENTS = 'Upload Company Documents Sme',
  SME_LEGAL_DETAILS = 'Legal Details Sme',
  SME_APP_DOWNLOAD = 'App Download Sme',
  SME_NOT_SUPPORTED_PP = 'Not Supported Private Person Sme',
  SME_NOT_SUPPORTED_NON_REP = 'Not Supported Non Representative Sme',
  SME_NOT_SUPPORTED_COMPANY_TYPE = 'Not Supported Company Type Sme',
}

const indexRouteMapping: Record<RouteRecordName, PageContext> = {
  [indexRoutesNames.home]: PageContext.EMAIL_ENTRY,
  [indexRoutesNames.otpEntry]: PageContext.OTP_ENTRY,
  [indexRoutesNames.login]: PageContext.LOGIN,
  [indexRoutesNames.resetPasswordTrigger]: PageContext.PASSWORD_RESET,
  [indexRoutesNames.offlineOnboardingEmailSent]: PageContext.OFFLINE_ONBOARDING_EMAIL_SENT,
};

const onboardingRouteMapping: Record<RouteRecordName, PageContext> = {
  [onboardingV2RoutesNames.areYouASoleTrader]: PageContext.SOLETRADER,
  [onboardingV2RoutesNames.notSupportedCustomer]: PageContext.NOT_SOLETRADER,
  [onboardingV2RoutesNames.passwordEntry]: PageContext.PASSWORD_ENTRY,
  [onboardingV2RoutesNames.phoneNumberEntry]: PageContext.PHONE_NUMBER,
  [onboardingV2RoutesNames.usTaxResidency]: PageContext.US_TAX_RESIDENCY,
  [onboardingV2RoutesNames.businessDetails]: PageContext.BUSINESS_DETAILS,
  [onboardingV2RoutesNames.personalDetails]: PageContext.PERSONAL_DETAILS,
  [onboardingV2RoutesNames.addressDetails]: PageContext.ADDRESS_DETAILS,
  [onboardingV2RoutesNames.identityDetails]: PageContext.IDENTITY_DETAILS,
  [onboardingV2RoutesNames.taxDetails]: PageContext.TAX_DETAILS,
  [onboardingV2RoutesNames.notSupportedNationality]: PageContext.NOT_SUPPORTED_NATIONALITY,
  [onboardingV2RoutesNames.legalDetails]: PageContext.LEGAL_DETAILS,
  [onboardingV2RoutesNames.appDownload]: PageContext.APP_DOWNLOAD,
};

const teamcardsRouteMapping: Record<RouteRecordName, PageContext> = {
  [teamCardsRoutesNames.teamCardsHome]: PageContext.TEAMCARDS_START,
  [teamCardsRoutesNames.teamCardsPasswordEntry]: PageContext.TEAMCARD_PASSWORD_ENTRY,
  [teamCardsRoutesNames.teamCardsAppDownload]: PageContext.TEAMCARDS_APP_DOWNLOAD,
  [teamCardsRoutesNames.teamCardsTokenExpired]: PageContext.TEAMCARDS_EMAIL,
};

const smeRouteMapping: Record<RouteRecordName, PageContext> = {
  [smeRoutesNames.companyTypeSelection]: PageContext.SME_SELECT_COMPANY_TYPE,
  [smeRoutesNames.notSupportedPrivatePerson]: PageContext.SME_NOT_SUPPORTED_PP,
  [smeRoutesNames.roleSelection]: PageContext.SME_SELECT_ROLE,
  [smeRoutesNames.usTaxResidency]: PageContext.US_TAX_RESIDENCY,
  [smeRoutesNames.representativeQuestion]: PageContext.SME_REP_QUESTION,
  [smeRoutesNames.notSupportedNonRepresentative]: PageContext.SME_NOT_SUPPORTED_NON_REP,
  [smeRoutesNames.companyDetails]: PageContext.SME_COMPANY_DETAILS,
  [smeRoutesNames.companyAddress]: PageContext.SME_COMPANY_DETAILS,
  [smeRoutesNames.personalDetails]: PageContext.SME_PERSONAL_DETAILS,
  [smeRoutesNames.addressDetails]: PageContext.SME_ADDRESS_DETAILS,
  [smeRoutesNames.identityDetails]: PageContext.SME_IDENTITY_DETAILS,
  [smeRoutesNames.taxDetails]: PageContext.SME_TAX_DETAILS,
  [smeRoutesNames.representatives]: PageContext.SME_REPRESENTATIVES,
  [smeRoutesNames.addRepresentative]: PageContext.SME_ADD_REPRESENTATIVE,
  [smeRoutesNames.editRepresentative]: PageContext.SME_EDIT_REPRESENTATIVE,
  [smeRoutesNames.uploadDocumentsCompany]: PageContext.SME_UPLOAD_COMPANY_DOCUMENTS,
  [smeRoutesNames.legalDetails]: PageContext.SME_LEGAL_DETAILS,
  [smeRoutesNames.inviteRepresentative]: PageContext.SME_INVITE_REPRESENTATIVE,
  [smeRoutesNames.representativeStatus]: PageContext.SME_REPRESENTATIVE_STATUS,
};

const routesMap: Record<RouteRecordName, PageContext> = {
  ...indexRouteMapping,
  ...onboardingRouteMapping,
  ...teamcardsRouteMapping,
  ...smeRouteMapping,
};

export const getPageKey = (pageName: RouteRecordName): PageContext => {
  return routesMap[pageName];
};
