<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#ffda44" d="M401.9 0l-234 172.8L0 401.8V512h110.5L339 336.9l173-226.4V0z" />
      <path fill="#d80027" d="M512 110.3L110.3 512H512V110.3z" />
      <path fill="#6da544" d="M0 0v401.9L401.9 0H0z" />
    </g>
  </svg>
</template>
