<template>
  <p
    :class="[
      'm-0 whitespace-pre-line p-0 sm:whitespace-normal',
      {
        'transition duration-300 ease-in-out': withTransition,
        '!whitespace-pre-line': isLineBreakEnabled,
      },
      {
        '!whitespace-pre-line font-lato text-sm leading-page-info-text text-base-info-text':
          predefinedStyle === 'onb-page-info-text',
        'text-grey font-lato text-sm leading-page-info-text': predefinedStyle === 'onb-page-info-text',
        'text-sm text-error': predefinedStyle === 'error',
        'font-lato text-[15px] font-black text-primary': predefinedStyle === 'plain-btn',
      },
      {
        'mb-8 xl:!mb-10': predefinedStyle === 'onb-page-info-text',
      },
      `md:text-${alignText}`,
    ]"
  >
    <slot></slot>
  </p>
</template>

<script lang="ts">
const predefinedStylesList: Array<string> = ['onb-page-info-text', 'error', 'plain-btn'];
</script>

<script setup lang="ts">
defineProps({
  predefinedStyle: {
    type: String,
    default: null,
    validator: (value: string) => {
      const isStyleAllowed = predefinedStylesList.indexOf(value) > -1;

      if (!isStyleAllowed) {
        console.info(
          `The font style ${value} you have chosen is not allowed, take one of these:`,
          predefinedStylesList
        );
      }

      return isStyleAllowed;
    },
  },
  isLineBreakEnabled: {
    type: Boolean,
    default: false,
  },
  withTransition: {
    type: Boolean,
    default: false,
  },
  alignText: {
    type: String,
    default: 'center',
  },
});
</script>
