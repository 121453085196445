<template>
  <div class="slider-info">
    <MfsFont fontName="display-3" color="primary-v2" class="mb-4 font-metro font-black">
      {{ getCurrentHeader }}
    </MfsFont>

    <MfsFont fontName="body-2-regular" color="primary-v2" class="slide-description">
      {{ getCurrentDescription }}
    </MfsFont>
  </div>
</template>

<script setup lang="ts">
import MfsFont from '@/components/atoms/MfsFont.vue';
import { computed } from 'vue';
import { useI18n, type TranslateResult } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  currentSlide: number;
}>();
const getCurrentHeader = computed((): TranslateResult => t(`teamCardsHomeSlider.slider.header.${props.currentSlide}`));
const getCurrentDescription = computed(
  (): TranslateResult => t(`teamCardsHomeSlider.slider.description.${props.currentSlide}`)
);
</script>
<style scoped>
.slide-description {
  min-height: 40px;
  max-width: 300px;
  margin: 0 auto;
}

.slider-info {
  margin-bottom: 33px;
}

@media screen and (min-width: 768px) {
  .slide-description {
    min-height: 0;
  }

  .slider-info {
    text-align: center;
    margin-bottom: 48px;
  }
}
</style>
