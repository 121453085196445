import env from '@/env';
import { useOnboardingStore } from '@/stores/onboarding';
import { Functional, Statistics, ThirdParty } from '@/utils/cookiesAndUtm';
import { computed, onMounted, ref } from 'vue';

// @ts-ignore
export const useCookies = (emit) => {
  const shouldShowCookieOverlay = ref(false);
  const onboardingStore = useOnboardingStore();

  const cookieConfig = {
    expiry: 12 * 30, // 12 months
    path: '/',
    domain: window.location.hostname === 'localhost' ? 'localhost' : env.MFS_APP_DOMAIN,
  };

  const cookieExpiryDateAsUtcString = computed(() => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + cookieConfig.expiry);

    return expiryDate.toUTCString();
  });

  const checkCookieState = (functionalState: string, statisticsState: string, thirdPartyState: string): boolean => {
    const cookies = document.cookie.split(`;`);
    const allowFunctionalCookie = cookies.some((cookie) => cookie.trim() === `allowFunctional=${functionalState}`);
    const allowStatisticsCookie = cookies.some((cookie) => cookie.trim() === `allowStatistics=${statisticsState}`);
    const allowThirdPartyCookie = cookies.some((cookie) => cookie.trim() === `allowThirdParty=${thirdPartyState}`);

    return allowFunctionalCookie && allowStatisticsCookie && allowThirdPartyCookie;
  };

  const onAcceptAllCookies = async () => {
    shouldShowCookieOverlay.value = false;
    setCookie('allowFunctional');
    setCookie('allowStatistics');
    setCookie('allowThirdParty');

    emit('enable-fresh-chat');
  };

  const setCookie = (cookieName: string): void => {
    const cookieParts = [
      `${cookieName}=true`,
      `expires=${cookieExpiryDateAsUtcString.value}`,
      `path=${cookieConfig.path}`,
      `Domain=${cookieConfig.domain}`,
    ];

    document.cookie = cookieParts.join(';');
  };

  // check for all possible cookie combinations
  const cookieConsentStatus: Record<string, boolean> = {
    hasAllCookieConsent: checkCookieState(Functional.TRUE, Statistics.TRUE, ThirdParty.TRUE),
    hasFunctionalCookieConsent: checkCookieState(Functional.TRUE, Statistics.FALSE, ThirdParty.FALSE),
    hasStatisticsCookieConsent: checkCookieState(Functional.FALSE, Statistics.TRUE, ThirdParty.FALSE),
    hasThirdPartyCookieConsent: checkCookieState(Functional.FALSE, Statistics.FALSE, ThirdParty.TRUE),
    hasFunctionalAndStatisticsCookieConsent: checkCookieState(Functional.TRUE, Statistics.TRUE, ThirdParty.FALSE),
    hasFunctionalAndThirdPartyCookieConsent: checkCookieState(Functional.TRUE, Statistics.FALSE, ThirdParty.TRUE),
    hasStatisticsAndThirdPartyCookieConsent: checkCookieState(Functional.FALSE, Statistics.TRUE, ThirdParty.TRUE),
    hasNoCookieConsent: checkCookieState(Functional.FALSE, Statistics.FALSE, ThirdParty.FALSE),
  };

  // get the accepted cookie consent
  const acceptedCookies = computed(() =>
    Object.keys(cookieConsentStatus).filter((cookie) => cookieConsentStatus[cookie])
  );

  // enable freshchat for certain cookie consent actions
  const triggerFreshChat = (acceptedCookies: string) => {
    const cookieConsentActions = [
      'hasAllCookieConsent',
      'hasFunctionalCookieConsent',
      'hasFunctionalAndStatisticsCookieConsent',
      'hasFunctionalAndThirdPartyCookieConsent',
    ];

    if (cookieConsentActions.includes(acceptedCookies)) {
      emit('enable-fresh-chat');
    } else {
      onboardingStore.isFunctionalCookieEnabled = false;
    }
  };

  onMounted(() => {
    if (onboardingStore.isAppView) {
      emit('enable-fresh-chat');
      shouldShowCookieOverlay.value = false;
    } else if (acceptedCookies.value[0]) {
      triggerFreshChat(acceptedCookies.value[0]);
    } else {
      shouldShowCookieOverlay.value = true;
    }
  });

  return {
    shouldShowCookieOverlay,
    onAcceptAllCookies,
  };
};
