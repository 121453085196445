<template>
  <section v-if="shouldShowCookieOverlay" class="fixed bottom-0 left-0 z-[1000] w-full" data-cy="cookie-box">
    <div class="bg-white px-6 py-8 shadow-[0_-6px_24px_0_rgba(10,35,55,0.16)]">
      <div class="m-auto max-w-5xl md:flex">
        <div>
          <MfsFont font-name="body-1-bold" class="mb-4">{{ t('cookies.headline') }}</MfsFont>
          <MfsFont font-name="body-2-regular">
            <I18nT keypath="cookies.description" tag="p">
              <template #dataCookiesSlot>
                <a href="https://metro-fs.com/cookies" target="_blank">{{ t('cookies.dataCookiesLinkLabel') }}</a>
              </template>

              <template #dataImpressumSlot>
                <a href="https://metro-fs.com/impressum" target="_blank">
                  {{ t('cookies.dataImpressumLinkLabel') }}
                </a>
              </template>

              <template #dataPrivacySlot>
                <a href="https://metro-fs.com/privacy-policy" target="_blank">
                  {{ t('cookies.dataPrivacyLinkLabel') }}
                </a>
              </template>
            </I18nT>
          </MfsFont>
        </div>

        <div class="mt-8 md:ml-20 md:min-w-[300px]">
          <MfsButton
            class="mb-4"
            data-cy="cookie-btn"
            :label="t('cookies.acceptButtonLabel')"
            @click="onAcceptAllCookies"
          />
          <a href="https://metro-fs.com/cookies">
            <MfsButton target="_blank" :is-outlined="true" :label="t('cookies.rejectButtonLabel')" />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import MfsButton from '@/components/atoms/MfsButton.vue';
import MfsFont from '@/components/atoms/MfsFont.vue';
import { useCookies } from '@/composables/useCookies';
import { I18nT, useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['enable-fresh-chat']);

const { shouldShowCookieOverlay, onAcceptAllCookies } = useCookies(emit);
</script>

<style scoped>
a {
  @apply text-primary;
}
</style>
