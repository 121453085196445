<template>
  <div v-bind="$attrs" v-on="$attrs" :id="id" :class="[{ 'self-center': isSelfCentered }, classes]">
    <component :is="iconComponent" ref="icon" class="transition-colors duration-200" :style="iconStyle" />
  </div>
</template>

<script setup lang="ts">
import { iconsMap } from '@/components/atoms/icons';
import { validateColorPropBasedOnCssProperty } from '@/utils/cssUtil';
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: Number,
    default: 24,
  },
  isSelfCentered: {
    type: Boolean,
    default: true,
  },
  color: {
    type: String,
    default: 'primary',
    validator: (value: string) => validateColorPropBasedOnCssProperty(value),
  },
  iconName: {
    type: String,
    required: true,
    validator: (value: string) => {
      const allowedIconNames = Object.keys(iconsMap);
      const isIconAllowed = allowedIconNames.includes(value);

      if (!isIconAllowed) {
        console.info(
          `MfsIcon: The icon you have chosen - ${value} - doesn't exist. Add it to the src/components/atoms/icons folder, or take one of these:`,
          allowedIconNames
        );
      }

      return isIconAllowed;
    },
  },
  classes: {
    type: [String, Array],
    default: undefined,
  },
  id: {
    type: String,
    default: undefined,
  },
});

const iconComponent = computed(() => iconsMap[props.iconName]);

const iconStyle = computed(() => ({
  color: `var(--mfs-color-${props.color})`,
  width: `${props.size}px`,
  height: `${props.size}px`,
  fontSize: `${props.size}px`,
}));
</script>
