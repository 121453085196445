import { i18n } from '@/plugins/i18n';
import { countries } from '@/utils/countries';
import { type TranslateResult } from 'vue-i18n';

/*
   functions
 */

// Solaris understands only MR and MS values
export const getSalutation = () => ({
  MR: i18n.global.t('common.Mr'),
  MS: i18n.global.t('common.Ms'),
});

export const getTitle = () => ({
  None: '',
  Dr: i18n.global.t('common.Dr'),
  Prof: i18n.global.t('common.Prof'),
  ProfDr: i18n.global.t('common.ProfDr'),
});

// get supported idTypes object for the selected idOrigin
export const getIdTypes = (idOrigin: string) => {
  const supportedIdTypes = countries[idOrigin].idType;

  return Object.keys(idTypes)
    .filter((idType) => supportedIdTypes.includes(idType))
    .reduce((idTypesObj, key) => {
      return { ...idTypesObj, [key]: idTypes[key] };
    }, {});
};

export const getBusinessFormOptions = () => ({
  AG: i18n.global.t('businessForms.ag'),
  EG: i18n.global.t('businessForms.eg'),
  GBR: i18n.global.t('businessForms.gbr'),
  GMBH: i18n.global.t('businessForms.gmbh'),
  GMBH_CO_KG: i18n.global.t('businessForms.gmbhCoKg'),
  KG: i18n.global.t('businessForms.kg'),
  KG_AA: i18n.global.t('businessForms.kgAa'),
  OHG: i18n.global.t('businessForms.ohg'),
  UG: i18n.global.t('businessForms.ug'),
  ASSOCIATION: i18n.global.t('businessForms.association'),
  PRIVATE: i18n.global.t('businessForms.private'),
  OTHER: i18n.global.t('businessForms.other'),
});

export const getNaceOptions = (): { [key: string]: TranslateResult } => {
  const prioNaceOptions = sortNaceOptionsAlphabeticallyByLabel(['I1', 'I2', 'I3', 'I4', 'I5', 'G6', 'G7', 'G8']);
  const otherNaceOptions = sortNaceOptionsAlphabeticallyByLabel('ABCDEFGHJLMNOPQRST'.split(''));
  const allNaceCode = [...prioNaceOptions, ...otherNaceOptions];
  return allNaceCode.reduce<{ [key: string]: TranslateResult }>((accumulated, naceOption) => {
    accumulated[naceOption] = i18n.global.t(`businessInfo.naceItems.${naceOption}`);
    return accumulated;
  }, {});
};

function sortNaceOptionsAlphabeticallyByLabel(naceOptions: string[]): string[] {
  return naceOptions.sort((a, b) => naceLabel(a).localeCompare(naceLabel(b)));
}

function naceLabel(naceOption: string): string {
  return i18n.global.t(`businessInfo.naceItems.${naceOption}`) as string;
}

export const getIncomeLevelList = () => ({
  REVENUE_RANGE_LESS_THAN_2500_EUR: i18n.global.t('personal.income.level1'),
  REVENUE_RANGE_BETWEEN_2500_AND_4000_EUR: i18n.global.t('personal.income.level2'),
  REVENUE_RANGE_BETWEEN_4000_AND_6000_EUR: i18n.global.t('personal.income.level3'),
  REVENUE_RANGE_OVER_6000_EUR: i18n.global.t('personal.income.level4'),
});

// returns url without token
export const getStrippedUrl = (url: string): string => {
  return url && url.split('?')[0];
};

/*
   constants
 */

/*
 * source: https://metro-fs.atlassian.net/wiki/spaces/platform/pages/513507340/Freshsales
 * consistent with de.json -> businessInfo.naceItems
 */
export const getNaceItems: Record<string, string> = {
  A: 'Land- und Forstwirtschaft',
  B: 'Bergbau',
  C: 'Produzierendes und verarbeitendes Gewerbe',
  D: 'Energieversorgung',
  E: 'Wasserversorgung',
  F: 'Baugewerbe',
  G: 'Handel',
  H: 'Verkehr und Lagerei',
  J: 'Information und Kommunikation',
  L: 'Grundstücks- und Wohnungswesen',
  M: 'Freiberufliche Dienstleistungen',
  N: 'Sonstigen wirtschaftlichen Dienstleistungen',
  O: 'Öffentliche Sektor',
  P: 'Bildungswesen',
  Q: 'Gesundheits- und Sozialwesen',
  R: 'Kunst, Unterhaltung und Erholung',
  S: 'Sonstigen Dienstleistungen',
  T: 'Private Haushalte',
  I1: 'Hotel- und Gastgewerbe',
  I2: 'Bar, Café & Club',
  I3: 'Restaurant & Fast Food',
  I4: 'Caterer & Kantine',
  I5: 'Sonstige Gastronomie',
  G6: 'Großhandel',
  G7: 'Einzelhandel',
  G8: 'Kiosk & Tankstelle',
};

export const BKZ = [
  '021',
  '033',
  '034',
  '038',
  '521',
  '601',
  '602',
  '603',
  '611',
  '630',
  '643',
  '644',
  '645',
  '647',
  '675',
  '031',
  '035',
  '036',
  '074',
  '077',
  '080',
  '612',
  '639',
  '642',
  '652',
  '653',
  '654',
  '655',
  '656',
  '658',
  '666',
  '010',
  '011',
  '012',
  '017',
  '018',
  '023',
  '024',
  '025',
  '512',
  '646',
  '029',
  '053',
  '054',
  '057',
  '058',
  '075',
  '529',
  '676',
  '685',
  '056',
  '062',
  '066',
  '067',
  '071',
  '613',
  '618',
  '619',
  '622',
  '623',
  '624',
  '632',
  '633',
  '687',
  '689',
  '030',
  '041',
  '045',
  '046',
  '048',
  '049',
  '402',
  '403',
  '528',
  '547',
  '621',
  '626',
  '629',
  '677',
  '026',
  '037',
  '105',
  '106',
  '108',
  '109',
  '606',
  '607',
  '608',
  '609',
  '610',
  '640',
  '669',
  '022',
  '032',
  '079',
  '099',
  '579',
  '664',
  '665',
  '671',
  '015',
  '016',
  '039',
  '539',
  '013',
  '014',
  '019',
  '020',
  '072',
  '401',
];

export const emailLocalStorageKey = 'emailForSignIn';
export const teamCardsJwtLocalStorageKey = 'teamCardsJwt';
export const freshchatUuid = 'freshchatUuid';

// all available idTypes and their corresponding translations
const idTypes: { [key: string]: TranslateResult } = {
  PASSPORT: i18n.global.t('personal.idType.passport'),
  IDCARD: i18n.global.t('personal.idType.idcard'),
  RESIDENCY_PERMIT: i18n.global.t('personal.idType.residencyPermit'),
  PASSPORT_AND_RESIDENCY_PERMIT: i18n.global.t('personal.idType.passportAndResidencyPermit'),
  PASSPORT_AND_CITY_REGISTRATION: i18n.global.t('personal.idType.passportAndCityRegistration'),
  IDCARD_AND_CITY_REGISTRATION: i18n.global.t('personal.idType.idcardAndCityRegistration'),
};

/*
   regex
 */

// value must have at least 4 digits separated by hyphens
export const metroIdRegex = /^\d{1,3}-\d{1,6}-\d{1,2}(-\d{1,2})?$/;

// validation regular expressions for password
export const atLeast8CharsRegex = /(?=.{8,})/;
export const atLeastOneNumberRegex = /(?=.*\d)/;
export const atLeastOneSpecialCharacterRegex = /[^a-zA-Z0-9]/;

/*
   urls
*/

export const APP_LINKS = {
  GOOGLE: 'https://play.google.com/store/apps/details?id=com.metrofs.banking&gl=DE',
  APPLE: 'https://apps.apple.com/de/app/metro-fs/id1567197056',
};

export const FRESHCHAT_HOST = 'https://wchat.eu.freshchat.com';

/*
   enums
*/

export enum Salutation {
  MR = 'MR',
  MS = 'MS',
}

export enum Title {
  Dr = 'Dr',
  Prof = 'Prof',
  ProfDr = 'ProfDr',
  None = 'None',
}

export enum MethodType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export enum IncomeLevel {
  REVENUE_RANGE_LESS_THAN_2500_EUR = 'REVENUE_RANGE_LESS_THAN_2500_EUR',
  REVENUE_RANGE_BETWEEN_2500_AND_4000_EUR = 'REVENUE_RANGE_BETWEEN_2500_AND_4000_EUR',
  REVENUE_RANGE_BETWEEN_4000_AND_6000_EUR = 'REVENUE_RANGE_BETWEEN_4000_AND_6000_EUR',
  REVENUE_RANGE_OVER_6000_EUR = 'REVENUE_RANGE_OVER_6000_EUR',
}

export const featureFlagsNames = {
  DEV_TEST: 'test-fe-ff',
  RECORD_SESSION: 'record-session',
  MAINTENANCE_MODE: 'maintenance-mode',
  NEW_COMPANY_SELECTION: 'new-company-selection',
};

export const featureFlagsForAbNames = {};
