<template>
  <div class="relative">
    <label
      :for="id"
      :class="[
        'mfs-base-input-label',
        {
          'mfs-base-input-label--switched': switchLabelPosition,
          'mfs-base-input-label--disabled': isDisabled,
        },
      ]"
    >
      {{ label }}
    </label>

    <input
      v-model="inputModel"
      :class="[
        'mfs-base-input mfs-text-field shadow-mfs-sm',
        {
          'mfs-base-input--error': !success && !isDisabled && hasError,
          'mfs-base-input--disabled': isDisabled,
        },
      ]"
      :id="id"
      :type="manualTypeOverride || type"
      :maxlength="maxLength"
      :disabled="isDisabled"
      @focus="onFocus"
      @blur="onBlur"
      @keypress="keyPress"
      @keyup.enter="keyupEnter"
      @click:append="onInput"
      autocomplete="off"
    />

    <MfsIcon
      :size="24"
      :icon-name="shouldShowPassword ? 'eye-off' : 'eye'"
      color="base-tint"
      :classes="[
        'cursor-pointer absolute right-4 top-4 z-10',
        {
          hidden: type !== 'password' && !shouldShowPassword,
        },
      ]"
      @click="togglePasswordVisibility"
    />

    <div v-if="!shouldHideDetails && !success && !isDisabled && hasError" class="mfs-base-input__error-msg">
      {{ getError }}
    </div>
  </div>
</template>

<script setup lang="ts">
import MfsIcon from '@/components/atoms/MfsIcon.vue';
import { trackInputBlurEvent } from '@/utils/analytics/sendAnalyticsUtil';
import { computed, defineModel, onMounted, ref, watch, type Ref } from 'vue';
import { type TranslateResult } from 'vue-i18n';

const emit = defineEmits(['input', 'focus', 'blur', 'keyup-enter', 'key-press']);
const props = withDefaults(
  defineProps<{
    id: string;
    label: string | TranslateResult;
    type?: string;
    isDisabled?: boolean;
    errorMessages?: string | Array<string> | Ref<string>;
    success?: boolean;
    maxLength?: number;
    shouldHideDetails?: boolean;
  }>(),
  {
    type: 'text',
    isDisabled: false,
    success: false,
    shouldHideDetails: false,
    maxLength: 250,
  }
);

const inputModel = defineModel<string>();

const switchLabelPosition = ref(false);
const isInputFocused = ref(false);
const shouldShowPassword = ref(false);
const manualTypeOverride = ref<null | string>(null);

const hasError = computed(() => {
  if (!props.errorMessages) return false;

  if (typeof props.errorMessages === 'string' || Array.isArray(props.errorMessages)) {
    return props.errorMessages.length;
  }

  return props.errorMessages.value.length > 0;
});

const getError = computed(() => {
  if (!props.errorMessages) return false;

  if (typeof props.errorMessages === 'string') {
    return props.errorMessages;
  }

  if (Array.isArray(props.errorMessages)) {
    return props.errorMessages[0];
  }

  return props.errorMessages.value;
});

watch(
  () => inputModel.value,
  () => {
    detectLabelPosition();
    emit('input', inputModel.value);
  }
);

onMounted(() => {
  detectLabelPosition();
});

const detectLabelPosition = () => {
  switchLabelPosition.value = (inputModel.value && inputModel.value.length > 0) || isInputFocused.value ? true : false;
};

const keyPress = (event: KeyboardEvent) => {
  // block non-numeric input when type is tel
  if (props.type === 'tel' && isNaN(Number(event.key)) && event.key !== 'Enter') {
    event.preventDefault();
  }
  return event;
};

const onFocus = (event: FocusEvent): void => {
  emit('focus', event);
  switchLabelPosition.value = true;
  isInputFocused.value = true;
};

const onBlur = (event: FocusEvent): void => {
  emit('blur', event);
  trackInputBlurEvent(event, inputModel.value);
  isInputFocused.value = false;

  if (!inputModel.value) {
    switchLabelPosition.value = false;
  }
};

const onInput = (e: Event) => {
  emit('input', (e.target as HTMLInputElement).value);
};

const keyupEnter = (e: KeyboardEvent) => {
  emit('keyup-enter', e);
};
const togglePasswordVisibility = () => {
  shouldShowPassword.value = !shouldShowPassword.value;
  manualTypeOverride.value = shouldShowPassword.value ? 'text' : 'password';
};
</script>
