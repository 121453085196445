<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#496e2d" d="M0 0h512v512H0z" />
      <path fill="#ffda44" d="M205.9.5h33.4l16 255-16 255.9h-33.4z" />
      <path fill="#333" d="M239.3.5h33.4l16.6 256-16.6 254.9h-33.4z" />
      <path fill="#ffda44" d="M511.4 205.9v33.4l-256 16.1L.6 239.3v-33.4z" />
      <path fill="#333" d="M511.4 239.3v33.4L255.8 291 .6 272.7v-33.4z" />
      <path fill="#eee" d="M272.7.5h33.4v510.9h-33.4z" />
      <path fill="#eee" d="M511.5 272.7v33.4H.6v-33.4z" />
      <circle cx="256" cy="256" r="122.4" fill="#d80027" />
      <path
        fill="#496e2d"
        d="M283.9 269.7c-9.1-18.3-21-36.7-21-36.7s.3-8.4.3-13.4a13.7 13.7 0 0 0-27.2-1.2 10.8 10.8 0 0 0-4.3 20.3 15.5 15.5 0 0 1 9.2-8.6 13.8 13.8 0 0 0 2.2 1.5l1.8.8s-6.5 18.3-6.5 28.2c0 27.6 18.2 36.1 18.2 36.1v.3l-9.1 9h18.2v-18l9 9c.2 0 17.3-11 9.2-27.2zM256 155.8l4.1 12.8h13.5l-10.9 7.9 4.2 12.7-10.9-7.9-10.8 8 4.1-12.8-10.9-8H252zM197.1 175l10.9 7.8 10.8-7.8-4.1 12.7 10.8 7.9h-13.4l-4.1 12.7-4.2-12.7h-13.4l10.9-7.9zm-36.4 50h13.4l4.2-12.7 4.1 12.7h13.4l-10.8 8 4.1 12.7-10.8-7.9-10.9 7.9 4.2-12.8zm0 62l10.9-8-4.2-12.7 10.9 7.9 10.8-7.9-4.1 12.8 10.8 7.9h-13.4l-4.1 12.7L174 287zm36.4 50l4.2-12.7-10.9-7.9h13.4l4.2-12.7 4.1 12.7h13.4l-10.8 7.9 4.1 12.7-10.8-7.8zm58.9 19.2l-4.1-12.8h-13.5l10.9-7.9-4.2-12.7 10.9 7.9 10.8-8-4.1 12.8 10.9 8H260zm58.9-19.2l-10.9-7.8-10.8 7.8 4.1-12.7-10.8-7.9h13.4l4.1-12.7 4.2 12.7h13.4l-10.9 7.9zm36.4-50h-13.4l-4.2 12.7-4.1-12.7h-13.4l10.8-8-4.1-12.7 10.8 7.9 10.9-7.9-4.2 12.8zm0-62l-10.9 8 4.2 12.7-10.9-7.9-10.8 7.9 4.1-12.8-10.8-7.9h13.4l4.1-12.7L338 225zm-36.4-50l-4.2 12.7 10.9 7.9h-13.4l-4.2 12.7-4.1-12.7h-13.4l10.8-7.9-4.1-12.7 10.8 7.8z"
      />
    </g>
  </svg>
</template>
