<template>
  <MfsDialog :isOpened :title="t('cookies.dialogHeading')" @close="$emit('close')">
    <I18nT keypath="cookies.dialogInfo">
      <template v-slot:url>
        <a
          href="https://metro-fs.com/cookies/"
          target="_blank"
          class="cursor-pointer text-primary"
          @click="$emit('close')"
        >
          {{ t('cookies.dialogUrlText') }}
        </a>
      </template>
    </I18nT>
  </MfsDialog>
</template>

<script setup lang="ts">
import MfsDialog from '@/components/atoms/MfsDialog.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineEmits(['close']);

defineProps<{
  isOpened: boolean;
}>();
</script>
