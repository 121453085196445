import { MFS_SSO_CONTEXT_URI } from '@/env';
import { getFirebaseClient } from '@/plugins/firebase';
import { AccountRole } from '@/types/AccountRole';
import { Collection } from '@/types/FirebaseCollection';
import type { User, UserCredential } from 'firebase/auth';
import {
  browserLocalPersistence,
  confirmPasswordReset,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore/lite';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

async function initializeAuthInst() {
  const firebaseApp = await getFirebaseClient();
  return getAuth(firebaseApp);
}

async function getDbInst() {
  const firebaseApp = await getFirebaseClient();
  return getFirestore(firebaseApp);
}

/**
 * Get storage url from bucket and path
 * @param {string} bucket bucket name
 * @param {string} path path to file
 */
export async function getStorageDownloadUrl(bucket: string, path: string): Promise<string> {
  const firebase = await getFirebaseClient();
  const storage = getStorage(firebase);
  return getDownloadURL(ref(storage, `gs://${bucket}/${path}`));
}

/**
 * Change user password
 * @param {string} oobCode oobCode from email
 * @param {string} newPassword new password
 */
export async function changePassword(oobCode: string, newPassword: string): Promise<void> {
  return confirmPasswordReset(await initializeAuthInst(), oobCode, newPassword);
}

/**
 * Update user password
 * @param {string} newPassword new password
 */
export async function updateUserPassword(newPassword: string): Promise<void> {
  const authInstance = await initializeAuthInst();

  if (!authInstance.currentUser) throw new Error('No user is logged in');

  return updatePassword(authInstance.currentUser, newPassword);
}

/**
 * Sign in user with email and password
 * @param {string} email email
 * @param {string} password password
 */
export async function signIn(email: string, password: string): Promise<UserCredential> {
  const authInstance = await initializeAuthInst();

  await setPersistence(authInstance, browserLocalPersistence);
  return signInWithEmailAndPassword(authInstance, email, password);
}

/**
 * Sign in with custom token
 * @param {string} token - Firebase custom token
 */
export async function signInWithToken(token: string): Promise<UserCredential> {
  const authInstance = await initializeAuthInst();

  return signInWithCustomToken(authInstance, token);
}

export async function signOutTrigger(): Promise<void> {
  const authInstance = await initializeAuthInst();

  return signOut(authInstance);
}

/**
 * Verify password reset code
 * @param {string} oobCode oobCode from email
 */
export async function validateResetPasswordLink(oobCode: string): Promise<string> {
  const authInstance = await initializeAuthInst();

  return verifyPasswordResetCode(authInstance, oobCode);
}

/**
 * Get the current user's details
 * @param {string} userUuid - The user's UUID
 * @param {AccountRole} [roles] - The role to use correct collection
 * @param {Collection} [overwriteRoleCollection] - Optional arg to overwrite the role's collection
 * @returns {Promise<T>} - The current user's details
 */

export async function readDataFromFirestore<T>(
  userUuid: string,
  roles?: AccountRole,
  overwriteRoleCollection?: Collection
): Promise<T | null> {
  let collection = Collection.SOLETRADER_REG;
  if (roles === AccountRole.TEAMCARD_MEMBER) collection = Collection.TEAMCARD_REG;
  if (overwriteRoleCollection) collection = overwriteRoleCollection;

  const db = await getDbInst();
  const docRef = doc(db, collection, userUuid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    try {
      return docSnap.data() as T;
    } catch (error) {
      throw new Error('Firebase read data failed with error: ' + error);
    }
  }

  return null;
}

/**
 * Save the current user's details to Firestore
 * @param {object} data - The data to be saved
 * @param {Collection} [collectionName = Collection.SOLETRADER_REG] - the collection to save to
 */
export async function updateDataInFirestore(
  data: object,
  collectionName: Collection = Collection.SOLETRADER_REG
): Promise<void> {
  const db = await getDbInst();
  const authInstance = await initializeAuthInst();

  if (!authInstance.currentUser) {
    window.location.href = MFS_SSO_CONTEXT_URI;
    return;
  }

  const currentUserUuid = authInstance.currentUser.uid;
  const docRef = doc(db, collectionName, currentUserUuid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    try {
      await updateDoc(docRef, data);
    } catch (error) {
      throw new Error('Firebase doc update failed with error: ' + error);
    }
  } else {
    try {
      await setDoc(docRef, data);
    } catch (error) {
      throw new Error('Firebase doc set failed with error: ' + error);
    }
  }
}

/**
 * Set up a listener for the current user's details
 * @param {Function} callback - The callback to be called when the user's auth state changes
 */
export async function onAuthStateChangedTrigger(callback: (user: User | null) => void): Promise<void> {
  const authInstance = await initializeAuthInst();

  onAuthStateChanged(authInstance, callback);
}
