<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g>
      <path
        d="M11,22A11,11,0,0,1,3.222,3.222,11,11,0,1,1,18.778,18.778,10.928,10.928,0,0,1,11,22ZM11,2a9,9,0,1,0,9,9A9.01,9.01,0,0,0,11,2Z"
        fill="currentColor"
        fill-rule="evenodd"
      />
      <path
        d="M8.362.315a.937.937,0,1,1,1.4,1.246l-5,5.625a.938.938,0,0,1-1.364.04L.275,4.1A.938.938,0,1,1,1.6,2.775L4.022,5.2Z"
        transform="translate(6 7.25)"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>
