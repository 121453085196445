<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <defs>
      <path id="6k42m44xha" d="M0 0L27.974 0 27.974 28 0 28z" />
      <path
        id="smj4omsc4b"
        d="M21.307 11.065c2.653 0 4.812 2.155 4.812 4.802 0 1.909-1.149 3.627-2.863 4.387v4.615c0 .233-.092.455-.257.62l-1.074 1.07c-.171.171-.394.256-.618.256-.224 0-.447-.085-.618-.255l-1.074-1.071c-.165-.165-.258-.387-.258-.62v-.428l-.458-.458c-.165-.164-.257-.387-.257-.62 0-.232.092-.455.257-.62l.453-.45-.453-.452c-.165-.164-.257-.387-.257-.62 0-.232.092-.455.257-.62l.384-.381c-1.674-.777-2.789-2.473-2.789-4.353 0-2.648 2.16-4.802 4.813-4.802zm0 1.75c-1.689 0-3.063 1.369-3.063 3.052 0 1.357.916 2.562 2.226 2.932.378.107.637.451.637.843v.866c0 .232-.092.455-.256.62l-.095.093.095.095c.164.164.256.387.256.62v.713c0 .233-.092.456-.256.62l-.095.094.095.095c.164.164.256.387.256.62v.428l.2.199.2-.199v-4.864c0-.392.26-.736.636-.843 1.311-.369 2.226-1.575 2.226-2.932 0-1.683-1.373-3.052-3.062-3.052zm0 .875c.604 0 1.094.49 1.094 1.094 0 .604-.49 1.094-1.094 1.094-.604 0-1.094-.49-1.094-1.094 0-.604.49-1.094 1.094-1.094zm-4.856 8.185H3.5c-.483 0-.875-.392-.875-.875v-1.691c0-1.505.974-2.854 2.42-3.359l4.028-1.399V13.1C7.642 11.936 6.77 10.028 6.77 8.003c0-3.448 2.458-6.253 5.48-6.253 3.023 0 5.48 2.805 5.48 6.253 0 2.024-.87 3.932-2.301 5.097l-.001 2.074c0 .483-.392.874-.875.874s-.875-.39-.875-.875V12.66c0-.291.144-.562.386-.725 1.182-.798 1.917-2.306 1.917-3.932 0-2.483-1.674-4.503-3.731-4.503S8.52 5.52 8.52 8.003c0 1.627.735 3.134 1.917 3.933.241.163.386.434.386.724v2.513c0 .373-.236.705-.588.827l-4.614 1.604c-.745.26-1.246.944-1.246 1.705v.816H16.45c.483 0 .875.392.875.875s-.392.875-.875.875z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-90 -234) translate(76 220) translate(14 14)">
            <use fill="currentColor" xlink:href="#smj4omsc4b" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
