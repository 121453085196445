import { EventId } from './eventIds';
import {
  addressDetailsEvents,
  businessDetailsEvents,
  passwordEntryEvents,
  personalDataEvents,
  phoneNumberEvents,
  startPageEvents,
} from './eventNames';

const mapEventNamesToEventIds = {
  [EventId.EMAIL]: startPageEvents.inputUserEmail,
  [EventId.PASSWORD]: passwordEntryEvents.inputPassword,
  [EventId.FIRSTNAME]: personalDataEvents.inputFirstname,
  [EventId.LASTNAME]: personalDataEvents.inputLastname,
  [EventId.PHONE_NUMBER]: phoneNumberEvents.inputPhoneNumber,
  [EventId.BIRTHPLACE]: personalDataEvents.inputBirthPlace,
  [EventId.BIRTHDAY_YEAR]: personalDataEvents.inputBirthYear,
  [EventId.STREET_NAME]: addressDetailsEvents.inputStreetName,
  [EventId.HOUSE_NUMBER]: addressDetailsEvents.inputHouseNumber,
  [EventId.CITY]: addressDetailsEvents.inputCityName,
  [EventId.POSTCODE]: addressDetailsEvents.inputPostCode,
  [EventId.IS_METRO_CUSTOMER]: businessDetailsEvents.clickMetroNumberActivation,
  [EventId.METRO_CUSTOMER_NUMBER]: businessDetailsEvents.inputMetroNumber,
  [EventId.IS_US_TAX_PAYER]: businessDetailsEvents.selectUsTaxCheckbox,
};

export function getEventName(id: EventId) {
  return mapEventNamesToEventIds[id];
}
