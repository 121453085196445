export enum indexRoutesNames {
  home = 'Home',
  otpEntry = 'OtpEntry',
  activate = 'Activate',
  changeEmail = 'ChangeEmail',
  resetPasswordTrigger = 'ResetPasswordTrigger',
  resetPasswordConfirmation = 'ResetPasswordConfirmation',
  resetPasswordInput = 'ResetPasswordInput',
  onboarding = 'Onboarding',
  update = 'Update',
  invitation = 'Invitation',
  notFound = 'NotFound',
  goToApp = 'GoToApp',
  leadsVerification = 'LeadsVerification',
  onboardingV2 = 'Onboarding-v2',
  login = 'Login-v2',
  offlineOnboardingEmailSent = 'OfflineOnboardingEmailSent',
  maintenance = 'Maintenance',
  error = 'Error',
}

export enum onboardingV2RoutesNames {
  soleTraderContainer = 'SoleTraderContainer',
  notSupportedCustomer = 'NotSupportedCustomer-v2',
  notSupportedNationality = 'NotSupportedNationality-v2',
  areYouASoleTrader = 'AreYouASoleTrader-v2',
  passwordEntry = 'PasswordEntry-v2',
  phoneNumberEntry = 'PhoneNumberEntry-v2',
  usTaxResidency = 'USTaxResidency-v2',
  personalDetails = 'PersonalDetails-v2',
  addressDetails = 'AddressDetails-v2',
  identityDetails = 'IdentityDetails-v2',
  taxDetails = 'TaxDetails-v2',
  businessDetails = 'BusinessDetails-v2',
  legalDetails = 'LegalDetails-v2',
  appDownload = 'AppDownload-v2',
}

export enum teamCardsRoutesNames {
  teamCardsHome = 'TeamCardsHome',
  teamCardsOnboarding = 'TeamCardsOnboarding',
  teamCardsPasswordEntry = 'TeamCardsPasswordEntry',
  teamCardsAppDownload = 'TeamCardsAppDownload',
  teamCardsTokenExpired = 'TeamCardsTokenExpired',
  teamCardsEmailSent = 'TeamCardsEmailSent',
}

export enum smeRoutesNames {
  smeContainer = 'SmeContainer',
  roleSelection = 'RoleSelection-sme',
  notSupportedPrivatePerson = 'NotSupportedPrivatePerson-sme',
  companyTypeSelection = 'CompanyTypeSelection-sme',
  notSupportedCompanyType = 'NotSupportedCompanyType-sme',
  usTaxResidency = 'USTaxResidency-sme',
  representativeQuestion = 'RepresentativeQuestion-sme',
  notSupportedNonRepresentative = 'NotSupportedNonRepresentative-sme',
  companyDetails = 'CompanyDetails-sme',
  companyAddress = 'CompanyAddress-sme',
  personalDetails = 'PersonalDetails-sme',
  addressDetails = 'AddressDetails-sme',
  identityDetails = 'IdentityDetails-sme',
  taxDetails = 'TaxDetails-sme',
  representatives = 'Representatives-sme',
  addRepresentative = 'AddRepresentative-sme',
  editRepresentative = 'EditRepresentative-sme',
  uploadDocumentsCompany = 'UploadDocumentsCompany-sme',
  legalDetails = 'LegalDetails-sme',
  inviteRepresentative = 'InviteRepresentative-sme',
  representativeStatus = 'RepresentativeStatus-sme',
  appDownload = 'AppDownload-sme',
  personSelection = 'PersonSelection-sme',
}
