export enum PersonalInfoList {
  EMAIL = 'email',
  PASSWORD = 'password',
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  BIRTHDAY_DAY = 'birthday-day',
  BIRTHDAY_MONTH = 'birthday-month',
  PHONE_NUMBER = 'phone-number',
  STREET_NAME = 'street-name',
  HOUSE_NUMBER = 'house-number',
  METRO_CUSTOMER_NUMBER = 'metro-customer-number',
}
