<template>
  <svg
    width="15px"
    height="18px"
    viewBox="0 0 15 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="arrow-up"
        d="M12.0015,21 C11.58675,21 11.2515,20.66475 11.2515,20.25 L11.2515,5.5605 L5.78025,11.03175 C5.487,11.325 5.013,11.325 4.71975,11.03175 C4.5735,10.8855 4.5,10.6935 4.5,10.5015 C4.5,10.3095 4.5735,10.1175 4.71975,9.97125 L11.4705,3.21975 C11.5395,3.1515 11.62125,3.096 11.712,3.0585 C11.80275,3.02025 11.901,3 12.0015,3 C12.10125,3 12.1995,3.02025 12.29025,3.0585 C12.381,3.096 12.46275,3.1515 12.53175,3.21975 L19.2825,9.97125 C19.57575,10.2645 19.57575,10.7385 19.2825,11.03175 C18.98925,11.325 18.51525,11.325 18.222,11.03175 L12.7515,5.5605 L12.7515,20.25 C12.7515,20.66475 12.4155,21 12.0015,21"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1048.000000, -1165.000000)">
        <g transform="translate(1043.500000, 1162.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <mask fill="white">
            <use xlink:href="#arrow-up"></use>
          </mask>
          <use fill="currentColor" xlink:href="#arrow-up"></use>
        </g>
      </g>
    </g>
  </svg>
</template>
