<template>
  <component
    :is="tagComponent"
    :class="[{ 'transition-all duration-200': withTransition, 'whitespace-pre-line': isLineBreakEnabled }, classStyle]"
    :style="{
      color: `var(--mfs-color-${color})`,
    }"
  >
    <slot></slot>
  </component>
</template>

<script lang="ts">
const fontNameToTag: { [key: string]: string } = {
  'display-4': 'h1', //mfs-font-display-4
  'display-3': 'h2', //mfs-font-display-3
  'display-2': 'h3', //mfs-font-display-2
  'display-1': 'h4', //mfs-font-display-1
  title: 'p', //mfs-font-title
  'headline-2': 'p', //mfs-font-headline-2
  headline: 'h2', //mfs-font-headline
  'subtitle-1': 'p', //mfs-font-subtitle-1
  'subtitle-2': 'p', //mfs-font-subtitle-2
  'body-1-regular': 'p', //mfs-font-body-1-regular
  'body-1-bold': 'p', //mfs-font-body-1-bold
  'body-2-regular': 'p', //mfs-font-body-2-regular
  'body-2-bold': 'p', //mfs-font-body-2-bold
  'caption-regular': 'p', //mfs-font-caption-regular
  'caption-bold': 'p', //mfs-font-caption-bold
  overline: 'p', //mfs-font-overline
  'overline-grey-base-2': 'p', //mfs-font-overline-grey-base-2
  'extra-small': 'p', //mfs-font-extra-small
  'button-1': 'span', //mfs-font-button-1
  'button-2': 'span', //mfs-font-button-2
};
</script>

<script setup lang="ts">
import { validateColorPropBasedOnCssProperty } from '@/utils/cssUtil';
import { computed } from 'vue';

const props = defineProps({
  fontName: {
    type: String,
    required: true,
    validator: (value: string) => {
      const allowedFontNames = Object.keys(fontNameToTag);
      const isFontAllowed = allowedFontNames.includes(value);

      if (!isFontAllowed) {
        console.info(
          `MfsFont: The font name ${value} you have chosen is not allowed, take one of these:`,
          allowedFontNames
        );
      }

      return isFontAllowed;
    },
  },
  tag: {
    type: String,
    required: false,
  },
  isLineBreakEnabled: {
    type: Boolean,
    required: false,
  },
  color: {
    type: String,
    required: false,
    validator: (value: string) => validateColorPropBasedOnCssProperty(value),
  },
  withTransition: {
    type: Boolean,
    required: false,
  },
});

const classStyle = computed(() => {
  return `mfs-font-${props.fontName}`;
});

const tagComponent = computed(() => {
  if (props.tag) return props.tag;
  return fontNameToTag[props.fontName];
});
</script>

<style scoped>
/* font definitions */
.mfs-font-display-4 {
  font-family: CAMetro;
  font-weight: 800;
  font-size: 40px;
  line-height: 1.25;
  letter-spacing: -0.1px;
}

.mfs-font-display-3 {
  font-family: CAMetro;
  font-weight: 800;
  font-size: 32px;
  line-height: 1.19;
  letter-spacing: -0.1px;
}

.mfs-font-title {
  font-family: CAMetro;
  font-weight: 800;
  font-size: 28px;
  line-height: normal;
  letter-spacing: -0.1px;
}

.mfs-font-headline-2 {
  font-family: CAMetro;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.1px;
}

.mfs-font-headline {
  font-family: CAMetro;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.1px;
}

.mfs-font-display-2 {
  font-family: Lato;
  font-weight: 900;
  font-size: 18px;
  line-height: normal;
  letter-spacing: -0.1px;
}

.mfs-font-display-1 {
  font-family: Lato;
  font-weight: 900;
  font-size: 17px;
  line-height: normal;
  letter-spacing: -0.1px;
}

.mfs-font-subtitle-1 {
  font-family: CAMetro;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
}

.mfs-font-body-1-bold {
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.1px;
}

.mfs-font-button-2 {
  font-family: Lato;
  font-weight: 900;
  font-size: 15px;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: center;
}

.mfs-font-body-2-bold {
  font-family: Lato;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
}

.mfs-font-body-2-regular {
  font-family: Lato;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
}

.mfs-font-button-1 {
  font-family: Lato;
  font-weight: 900;
  font-size: 13px;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: center;
}

.mfs-font-caption-bold {
  font-family: Lato;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.1px;
}

.mfs-font-subtitle-2 {
  font-family: CAMetro;
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: normal;
}

.mfs-font-caption-regular {
  font-family: Lato;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: normal;
}

.mfs-font-overline-grey-base-2 {
  font-family: Lato;
  font-weight: 900;
  font-size: 10px;
  line-height: 1.4;
  letter-spacing: -0.1px;
}

.mfs-font-overline {
  font-family: Lato;
  font-size: 10px;
  line-height: 1.4;
  letter-spacing: 0.1px;
}

.mfs-font-extra-small {
  font-family: Lato;
  font-weight: 700;
  font-size: 8px;
  line-height: 1.5;
  letter-spacing: -0.05px;
}
</style>
