<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#0052b4" d="M256 0h256v511.9H0v-256z" />
      <path
        fill="#eee"
        d="M256 0H100.2L66.7 14.9 33.4 0H0v33.4l15.7 31.3L0 100.2V256h33.4l34-13.6 32.8 13.6h33.4v-75.2l75.2 75.2H256l-7.7-21.3 7.7-10.2v-15.7l-75.2-75.2H256v-33.4l-11.8-33.4L256 33.4z"
      />
      <path fill="#d80027" d="M256 224.5l-91-91h-31.4L256 256z" />
      <path fill="#d80027" d="M33.4 0v33.4H0v66.8h33.4V256h66.8V100.2H256V33.4H100.2V0z" />
      <path fill="#acabb1" d="M400.7 155.8L367.3 167l-33.4-11.2-11.1-27.8 11.1-27.8h66.8L389.6 128z" />
      <path fill="#338af3" d="M289.4 155.8v122.5c0 59.6 77.9 77.9 77.9 77.9s78-18.3 78-78V155.9h-156z" />
      <path fill="#6da544" d="M296.4 307.3c19.2 36.7 71 48.9 71 48.9s51.6-12.2 70.8-49l-70.9-118-70.9 118z" />
      <path
        fill="#ffda44"
        d="M445.2 277l-77.9-121.2-78 121.2v1.3a62 62 0 0 0 7.1 29L367.3 197l70.9 110.3a62 62 0 0 0 7-29z"
      />
    </g>
  </svg>
</template>
