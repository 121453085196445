<template>
  <div>
    <SidebarSingleStep
      v-for="n in stepsCount"
      :key="n"
      :single-step="t(`${stepsDescriptionKey}.${n}`)"
      :is-active="step === n"
      :is-completed="step > n"
      :is-first="n === 1"
      :is-last="n === stepsCount"
      :is-logged-out="isLoggedOut"
    />
  </div>
</template>

<script setup lang="ts">
import SidebarSingleStep from '@/components/atoms/SvgImgs/SidebarSingleStep.vue';
import { useI18n } from 'vue-i18n';

defineProps<{
  step: number;
  isLoggedOut: boolean;
  stepsCount: number;
  stepsDescriptionKey: string;
}>();

const { t } = useI18n();
</script>
