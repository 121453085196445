<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#338af3" d="M0 0h512v256l-153.2 35.7L210 256z" />
      <path fill="#6da544" d="M210 256h302v256H0z" />
      <path fill="#eee" d="M0 0v512l256-256z" />
      <path fill="#d80027" d="M103.6 189.2l16.6 51h53.6l-43.4 31.6 16.6 51-43.4-31.5-43.4 31.5 16.6-51-43.4-31.6H87z" />
    </g>
  </svg>
</template>
