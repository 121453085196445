/*
 * Default validation values that should be passed to Analytics in case of no errors
 */

type ValidationFieldset = Record<`validate-${string}`, null>;

const validateEmailEntry: ValidationFieldset = {
  'validate-email': null,
};

const validatePhoneNumber: ValidationFieldset = {
  'validate-phone': null,
};

const validatePersonalDetails: ValidationFieldset = {
  'validate-salutation': null,
  'validate-firstName': null,
  'validate-lastName': null,
  'validate-day': null,
  'validate-month': null,
  'validate-year': null,
  'validate-placeOfBirth': null,
  'validate-incomeLevel': null,
};

const validateAddressDetails: ValidationFieldset = {
  'validate-streetName': null,
  'validate-houseNumber': null,
  'validate-city': null,
  'validate-postcode': null,
};

const validateBusinessDetails: ValidationFieldset = {
  'validate-metroAccountId': null,
};

const validateTaxDetails: ValidationFieldset = {
  'validate-taxResCountry': null,
  'validate-taxResidencies': null,
};

const validateLegalDetails: ValidationFieldset = {
  'validate-onbAgreements': null,
  'validate-description': null,
  'validate-month': null,
  'validate-year': null,
};

const validateLogin: ValidationFieldset = {
  'validate-password': null,
};

const validatePasswordReset: ValidationFieldset = {
  'validate-emailForResetPassword': null,
};

export const getDefaultValidation = {
  validateEmailEntry,
  validatePhoneNumber,
  validatePersonalDetails,
  validateAddressDetails,
  validateBusinessDetails,
  validateTaxDetails,
  validateLegalDetails,
  validateLogin,
  validatePasswordReset,
  default: {},
};
