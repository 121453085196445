<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    class="hover:fill-error-hover"
  >
    <path
      d="M14.2 1c1.213 0 2.2.987 2.2 2.2l-.001.733h5.868a.733.733 0 0 1 0 1.467H20.74l-1.298 15.582A2.213 2.213 0 0 1 17.251 23H6.75a2.213 2.213 0 0 1-2.193-2.018L3.258 5.4H1.733a.733.733 0 0 1 0-1.467H7.6L7.6 3.2c0-1.213.987-2.2 2.2-2.2h4.4zm5.07 4.4H4.73l1.29 15.46a.738.738 0 0 0 .73.673h10.5c.345 0 .641-.244.716-.572l.016-.1L19.27 5.4zM9.8 9.067c.405 0 .733.328.733.733v7.333a.733.733 0 0 1-1.466 0V9.8c0-.405.328-.733.733-.733zm4.4 0c.405 0 .733.328.733.733v7.333a.733.733 0 0 1-1.466 0V9.8c0-.405.328-.733.733-.733zm0-6.6H9.8a.734.734 0 0 0-.733.733v.733h5.866V3.2a.734.734 0 0 0-.733-.733z"
      fill-rule="evenodd"
    />
  </svg>
</template>
