<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
    <g mask="url(#a)">
      <path fill="#ffda44" d="M0 256L256 0h256v512H0z" />
      <path
        fill="#eee"
        d="M0 0h33.4l32 16.9L100.3 0H256v33.4l-14.8 33.5 14.8 33.3v33.4l-9.3 33.7 9.3 41.5v15.7l-6.2 11.6L256 256h-47.2l-39.3-7-35.9 7.1-33.4-.1-32.6-16.6L33.4 256H0V100.2l14.2-35.8L0 33.4z"
      />
      <path
        fill="#d80027"
        d="M33.4 0v33.4H0v66.8h33.4V256h66.8V100.2H256V33.4H100.2V0H33.4zm100.2 133.5L256 256v-31.5l-91-91h-31.4z"
      />
      <circle cx="66.5" cy="66.7" r="47.5" fill="#0052b4" />
      <path
        fill="#ffda44"
        d="M66.5 19.5L56 52.2H21.5l27.9 20.2L38.8 105l27.9-20.2L94.5 105 84 72.4l27.5-20.2H77.2zm110.3 25.1l-5.4 17h-18L168 72.2l-5.5 17L177 78.7l14.5 10.5-5.5-17 14.3-10.5h-17.8zm-110 111.3l-5.6 17H43.3l14.4 10.7-5.5 17 14.5-10.5 14.4 10.5-5.5-17 14.5-10.5H72.2z"
      />
      <path fill="#0052b4" d="M180.8 133.6H256v75.2zm-47.2 47.2v75.3l75.2-.1z" />
    </g>
  </svg>
</template>
