type EventFieldset = Record<string, string>;

export const startPageEvents: EventFieldset = {
  clickContinueOnEmail: 'Click Continue On OTP Email Entry',
  clickOnMetroLogo: 'Click On Metro Logo',
  clickFreshchat: 'Click Freshchat On Start',
  inputUserEmail: 'Input User Email',
};

export const teamCardsStartPageEvents: EventFieldset = {
  clickContinueOnStart: 'Click Continue On Team Cards Start',
  swipeOnSlide: 'Swipe On Slide On Team Cards Start',
  clickOnSlide: 'Click On Slide On Team Cards Start',
  clickOnMetroLogo: 'Click On Metro Logo On Team Cards Start',
  clickFreshchat: 'Click Freshchat On Team Cards Start',
};

export const passwordEntryEvents: EventFieldset = {
  inputPassword: 'Input Password',
  clickContinue: 'Click Continue On Password Entry',
  clickFreshchat: 'Click Freshchat On Password Entry',
};

export const teamCardsPasswordEntryEvents: EventFieldset = {
  inputPassword: 'Input Team Cards Password',
  clickContinue: 'Click Continue On Team Cards Password Entry',
  clickFreshchat: 'Click Freshchat On Team Cards Password Entry',
  clickReturnToStart: 'Click Return To Start From Team Cards Password Entry',
  termsCheckboxSelected: 'Select Terms on Team Cards Password Entry',
};

export const resetPasswordTriggerEvents: EventFieldset = {
  clickFreshchat: 'Click Freshchat On Password Reset page',
  clickResetOnForgotPassword: 'Click Reset on Forgot Password page',
};

export const resetPasswordConfirmationEvents: EventFieldset = {
  clickResendEmail: 'Click Resend Email On Reset Password Confirmation',
  successResendEmail: 'Success Email Resend on Reset Password Confirmation',
};

export const resetPasswordInputEvents: EventFieldset = {
  clickFreshchat: 'Click Freshchat On Password Reset page',
  clickConfirmPassword: 'Click create new password on password confirmation page',
  successPasswordChange: 'Success Password Change',
};

export const soletraderEvents: EventFieldset = {
  selectSoletraderStatus: 'Select A Soletrader Status',
  clickContinue: 'Click Continue On Soletrader',
  clickFreshchat: 'Click Freshchat On Soletrader',
  clickBackToSoletraderFromNotSupportedCompany: 'Click Back To Soletrader From Not Supported Company',
  clickBackToPhoneNumberFromSoleTrader: 'Click Back To Phone Number From Soletrader',
  selectLegalFormOnNotSupportedCompany: 'Select Legal Form On Not Supported Company',
  clickActivateEmailReminderOnNotSupportedCompany: 'Click Activate Email Reminder On Not Supported Company',
  clickFreshchatOnNotSoletrader: 'Click Freshchat On Not A Soletrader Page',
  successNotSupportedCompanyEmailActivation: 'Success Not Supported Company Email Activation',
};

export const phoneNumberEvents: EventFieldset = {
  selectPhoneCountryCode: 'Select Phone Country Code',
  inputPhoneNumber: 'Input Phone Number',
  clickFreshchat: 'Click Freshchat on Phone Number Screen',
  clickContinue: 'Click Continue on Phone Number Screen',
};

export const usTaxResidencyEvents: EventFieldset = {
  clickFreshchat: 'Click Freshchat On US tax residency page',
  selectUsTaxResidencyStatus: 'Select US Tax residency Status',
  clickContinue: 'Click Continue On US Tax Residency',
  clickBackToSoletraderFromUsResidency: 'Click Back To Soletrader From US Tax Residency',
};

export const personalDataEvents: EventFieldset = {
  clickBackToBusinessDetails: 'Click Back To US Tax Residency From Personal Details',
  showPersonalDataTooltip: 'Show Personal Data Tooltip',
  selectSalutation: 'Select Salutation',
  selectTitle: 'Select Title',
  inputFirstname: 'Input Firstname',
  inputLastname: 'Input Lastname',
  showBirthDetailsTooltip: 'Show Birth Details Tooltip',
  inputBirthDay: 'Input Birthday',
  inputBirthMonth: 'Input Birthmonth',
  inputBirthYear: 'Input Birthyear',
  inputBirthPlace: 'Input Birthplace',
  clickFreshchat: 'Click Freshchat on Personal Details',
  clickContinue: 'Click Continue on Personal Details',
};

export const addressDetailsEvents: EventFieldset = {
  clickBackToPersonalDetails: 'Click Back To Personal Details From Address Details',
  clickSearchAddress: 'Click Search Address',
  clickSelectAddressFromDropdown: 'Click Select Address From Dropdown',
  inputStreetName: 'Input Street Name',
  inputStreetNumber: 'Input Street Number',
  inputCityName: 'Input City Name',
  inputPostCode: 'Input Post Code',
  clickContinue: 'Click Continue On Address Details',
  clickFreshchat: 'Click Freshchat On Address Details',
};

export const identityDetailsEvents: EventFieldset = {
  clickBackToAddressDetails: 'Click Back To Address Details From Identity Details',
  clickBackToIdentityDetailsFromUnsupportedNationality: 'Click Back To Identity Details From not supported Nationality',
  clickFreshchat: 'Click Freshchat On Identity Details',
  clickContinue: 'Click Continue on Identity Details',
  selectNationality: 'Select Nationality',
  selectDocumentType: 'Select Document Type',
  showNationalityDetailsTooltip: 'Show Nationality Details Tooltip',
};

export const notSupportedNationalityEvents: EventFieldset = {
  clickActivateEmail: 'Click Activate Email On Not Supported Nationality',
  successEmailActivation: 'Success Not Supported Nationality Email Activation',
  clickFreshchat: 'Click Freshchat on Not Supported Nationality Page',
  clickBackToIdentityDetailsFromUnsupportedNationality: 'Click Back To Identity Details From Not Supported Nationality',
};

export const taxDetailsEvents: EventFieldset = {
  selectTaxResidency: 'Select Tax Residency',
  clickContinue: 'Click Continue on Tax Details',
  clickBack: 'Click Back To Identity Details From Tax Details',
  clickFreshchat: 'Click Freshchat On Tax Details',
  inputTaxId: 'Input Tax ID',
  clickAddTaxResidency: 'Click Add More Tax Residency',
  clickAddAfterMaxTaxResidency: 'Click Add More Tax Residency After Allowed Limit on Tax Details',
};

export const businessDetailsEvents: EventFieldset = {
  clickBackToUsTaxResidency: 'Click Back To Tax Details From Business Details',
  showBusinessDetailsTooltip: 'Show Business Details Tooltip',
  selectNace: 'Select Nace',
  clickMetroNumberActivation: 'Click Metro Number Activation',
  inputMetroNumber: 'Input Metro Number',
  clickContinue: 'Click Continue On Business Details',
  clickFreshchat: 'Click Freshchat On Business Details',
  clickMetroNumberTooltip: 'Click on Metro Number Tooltip',
};

export const legalEvents: EventFieldset = {
  clickBackToIdentityDetails: 'Click Back To Business Details From Legal Details',
  checkboxSchufaConsent: 'Checkbox: Schufa Consent',
  checkboxSoletraderAgreement: 'Checkbox: Soletrader Agreement',
  checkboxPepPerson: 'Checkbox: is Pep Person',
  checkboxMarketingConsent: 'Checkbox: Marketing Consent',
  clickContinue: 'Click Continue On Legal Details',
  clickFreshchat: 'Click Freshchat On Legal Details',
  clickContinueOnPepDialog: 'Click Continue On Legal Pep Dialog',
};

export const teamCardsTokenExpiredEvents: EventFieldset = {
  clickSendNewEmail: 'Click Send New Email Link On Team Cards',
  successNewEmailSent: 'Success New Email Sent On Team Cards',
  clickFreshchat: 'Click Freshchat On Team Cards Email Confirmation Page',
};

export const appDownloadEvents: EventFieldset = {
  clickFreshchat: 'Click Freshchat On App Download Page',
  clickAndroidDownload: 'Click Android App Download',
  clickAppleDownload: 'Click iOS App Download',
};

export const teamCardsAppDownloadEvents: EventFieldset = {
  clickFreshchat: 'Click Freshchat On Team Cards App Download Page',
  clickAndroidDownload: 'Click Android App Download On Team Cards',
  clickAppleDownload: 'Click iOS App Download On Team Cards',
};

export const loginEvents: EventFieldset = {
  clickLogin: 'Click Login',
  clickFreshchat: 'Click Freshchat On Login Page',
  clickForgotPassword: 'Click Forgot Password on Login Page',
};

export const otpEmailEntryEvents: EventFieldset = {
  clickContinue: 'Click Continue On OTP Email Entry',
  clickFreshchat: 'Click Freshchat On Email Entry',
  triggerOtp: 'Trigger OTP On Email Entry',
  otpApiResponse: 'OTP API Response On Email Entry',
};

export const otpEntryEvents: EventFieldset = {
  enterOtp: 'Enter OTP Code On OTP Entry',
  clickContinue: 'Click Continue on OTP Entry',
  clickBackToEmailEntry: 'Click Back To Email Entry From OTP Entry',
  clickFreshchat: 'Click Freshchat On OTP Entry',
  clickResendOtp: 'Click Resend OTP On OTP Entry',
  triggerResendOtp: 'Trigger Resend OTP On OTP Entry',
  resendOtpApiResponse: 'Resend OTP API Response On OTP Entry',
  clickChangeEmail: 'Click Change Email On OTP Entry',
  triggerOtpSubmit: 'Trigger OTP Submit On OTP Entry',
  showOfflineOnboardedScreen: 'Show Offline Onboarded Screen',
};

export const validationEvents: EventFieldset = {
  validateEmailEntry: 'Validate Email Entry',
  validatePersonalDetails: 'Validate Personal Details',
  validateAddressDetails: 'Validate Address Details',
  validateBusinessDetails: 'Validate Business Details',
  validateTaxDetails: 'Validate Tax Details',
  validateLegalDetails: 'Validate Legal Details',
  validateLogin: 'Validate Login',
  validatePasswordReset: 'Validate Reset Password Trigger',
  validatePhoneNumber: 'Validate Phone Number',
};
